import React, { useState, useEffect } from "react";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import SideBar from "../components/Sidebar/SideBar";
// Icons
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../css/References.css";
import { getToken } from "../services/localStorageServices";
import LocationAdd from "../components/LocationBox/LocationAdd";
import NoDataFound from "./NoDataFound";
import { Spin, message } from "antd";
import { useDeleteTypeListMutation } from "../services/userAuthAPI";

const Referrals = () => {
  const { access_token } = getToken();
  const [referalDataList, setReferalDataList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [deleteTypeList] = useDeleteTypeListMutation();

  const referalRefrence = (type) => {
    setShowModal(true);
  };

  const referenceView = () => {
    setOpenBackdrop(true);
    fetch("https://skdm.in/skddev/DrCrm/account_data_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        account_data_types: "Test",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())

      .then((apiData) => {
        if (!apiData || apiData === "No Data Found") {
          console.log(apiData);
        } else {
          setOpenBackdrop(false);
          setReferalDataList(apiData);
        }
      })
      .catch((error) => {
        console.error("Error fetching reference data:", error);
      });
  };

  useEffect(() => {
    referenceView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const DeletePateint = async (pid, id) => {
    if (window.confirm("Are you sure want to remove list ?")) {
      setOpenBackdrop(true);
      const req = await deleteTypeList({
        token: access_token,
        account_data_id: pid,
      });

      if (req.data) {
        setOpenBackdrop(false);
        referalDataList.splice(id, 1);
        setReferalDataList([...referalDataList]);
        message.success("Removed Successfully");
      }
    }
  };

  const referalRefrenceUpdate = (type, status, value, id) => {};
  return (
    <div>
      <LocationAdd
        setReferalDataList={setReferalDataList}
        referalDataList={referalDataList}
        show={showModal}
        close={() => setShowModal(false)}
        locationType="Test"
        setOpenBackdrop={setOpenBackdrop}
      />

      <SideBar>
        <NavbarComp />
        <Spin spinning={openBackdrop}>
          <div className="referencesPage navbarBodyMargin">
            {/* Head */}
            <div className="referencesHead">
              <div className="referenceHeadText">
                <h6 className="pageTitle">Test Referrals Listing</h6>
              </div>
              <div className="addReferenceBtn">
                <button
                  onClick={() => referalRefrence("Referrals")}
                  className="Clickbtn"
                >
                  + Add Test Referrals
                </button>
              </div>
            </div>
            {/* ======= References List ========= */}
            <div className="referencesListSection d-lg-block d-none">
              <table className="table" style={{ textAlign: "left" }}>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile </th>
                    <th scope="col">Email</th>
                    <th scope="col">Address</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {referalDataList && referalDataList.length > 0 ? (
                    referalDataList.map((workObj, index) => (
                      <tr key={index}>
                        <td>{workObj.account_text_name}</td>
                        <td>{workObj.account_mobile}</td>
                        <td>{workObj.account_type_name}</td>
                        <td>{workObj.account_address}</td>

                        <td>
                          <button
                            className="iconsStyle edit"
                            style={{ color: "#fff !important" }}
                            onClick={() =>
                              referalRefrenceUpdate(
                                "Test",
                                "update",
                                workObj,
                                workObj.account_data_id
                              )
                            }
                          >
                            <AiFillEdit />
                          </button>
                        </td>
                        <td>
                          <button
                            className="iconsStyle delete"
                            style={{ color: "#fff !important" }}
                            onClick={() =>
                              DeletePateint(workObj.account_data_id, index)
                            }
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <section className="d-lg-none d-md-block">
            <div className="container">
              <div className="row">
                {referalDataList && referalDataList.length > 0 ? (
                  referalDataList.map((workObj, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-12">
                      <div className="customerCard">
                        <ul>
                          <li>
                            Name:
                            <span> {workObj.account_text_name}</span>
                          </li>

                          <li>
                            Phone:
                            <span> {workObj.account_mobile}</span>
                          </li>
                          <li>
                            Email:
                            <span> {workObj.account_type_name}</span>
                          </li>
                          <li>
                            Address:
                            <span> {workObj.account_address}</span>
                          </li>
                        </ul>

                        <div className="smallButton">
                          <button
                            className="iconsStyle sucess "
                            style={{ color: "#fff !important" }}
                            onClick={() =>
                              referalRefrenceUpdate(
                                "References",
                                "update",
                                workObj,
                                workObj.doctor_references_id
                              )
                            }
                          >
                            <AiFillEdit />
                          </button>
                          <button
                            className="iconsStyle delete"
                            style={{ color: "#fff !important" }}
                            onClick={() =>
                              DeletePateint(workObj.doctor_references_id, index)
                            }
                          >
                            <MdDelete />
                          </button>
                        </div>
                        {/* <span>+ 100</span>
                <h5>stisfied Customers</h5>
                <h5></h5>
                <h5></h5>
                <h5></h5> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </section>
        </Spin>
      </SideBar>

      {/* -----------------------responsive display------------------------ */}
    </div>
  );
};

export default Referrals;
