import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import { Link } from "react-router-dom";
import {
  useAddNewHospitalMutation,
  useEditHospitalMutation,
} from "../services/userAuthAPI";
import "../css/AddHospital.css";
import { useParams } from "react-router-dom";
import HospitalVector from "../Images/hospital-vector.webp";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { getToken } from "../services/localStorageServices";
import { useImageResizer } from "../context/imageResizer.js";
import { Form, Spin, Input, Button } from "antd";
const { TextArea } = Input;

const AddHospital = () => {
  const [file, setFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();
  const { access_token } = getToken();
  const { id } = useParams();
  const [addNewHospital] = useAddNewHospitalMutation();
  const [editHospital] = useEditHospitalMutation();
  const { resizeFile } = useImageResizer();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id !== "add") {
      const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

      if (hospital_list === null || hospital_list.length === 0) {
      } else {
        const filterHospital = hospital_list.find(
          (value) => value.list_of_account === id
        );
        form.setFieldsValue({
          name:filterHospital.first_name,
          address: filterHospital.address,
          city: filterHospital.city,
          phone:filterHospital.mobile,
          state:filterHospital.state,
          pincode:filterHospital.pincode,
          gmblink:filterHospital.gmb_link,
          shortDesc:filterHospital.description
        })
        setImageFile(filterHospital.logo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (e) => {
    const maxWidth = 300;
    const maxHeight = 300;
    const targetFileSize = 150 * 1024;

    const compresedFile = await resizeFile(
      e.target.files[0],
      maxWidth,
      maxHeight,
      targetFileSize
    );
    setFile(compresedFile);
    setImageFile(URL.createObjectURL(compresedFile));
  };
  // ========================= New Hospital Add ===================

  const newHospital = (id, values) => {
    var newHospital = [];
    const getHospital = JSON.parse(sessionStorage.getItem("hospaitl_list"));
    if (getHospital === null || getHospital.length === 0) {
      newHospital = [];
    } else {
      newHospital = getHospital;
    }

    const newData = {
      first_name: values.name,
      address: values.address,
      city: values.city,
      description: values.shortDesc,
      gmb_link: values.gmblink,
      logo: imageFile,
      mobile: values.phone,
      pincode: values.pincode,
      state: values.state,
      list_of_account: id,
      hospital_shedule: [],
    };
    newHospital.push(newData);
    sessionStorage.setItem("hospaitl_list", JSON.stringify(newHospital));
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("logo", file);
    formData.append("hospital_Name", values.name);
    formData.append("address", values.address);
    formData.append("phone_no", values.phone);
    formData.append("Description", values.shortDesc);
    formData.append("city", values.city);
    formData.append("pincode", values.pincode);
    formData.append("link_address", values.gmblink);
    formData.append("state", values.state);
    formData.append("token", access_token);

    const req = await addNewHospital(formData);
    if (req.data) {
      setLoading(false);

      newHospital(req.data.id, values);
      navigate("/hospitals");
      form.resetFields();
    }
  };

  // ================================= Hospital Update =============================

  const updateHospitalLocal = (id, values) => {
    const sessionData = JSON.parse(sessionStorage.getItem("hospaitl_list"));
    const index = sessionData.findIndex(
      (value) => value.list_of_account === id
    );
    // Update the value of the specific item
    sessionData[index] = {
      ...sessionData[index],
      first_name: values.name,
      address: values.address,
      city: values.city,
      description: values.shortDesc,
      gmb_link: values.gmblink,
      logo: imageFile,
      mobile: values.phone,
      pincode: values.pincode,
      state: values.state,
    };

    sessionStorage.setItem("hospaitl_list", JSON.stringify(sessionData));
  };

  const EditHospital = async (values) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("profile_Image", file);
    formData.append("hospitalName", values.name);
    formData.append("address", values.address);
    formData.append("phone_no", values.phone);
    formData.append("Description", values.shortDesc);
    formData.append("city", values.city);
    formData.append("pincode", values.pincode);
    formData.append("link_address", values.gmblink);
    formData.append("state", values.state);
    formData.append("token", access_token);
    formData.append("list_of_Account", id);

    const req = await editHospital(formData);

    if (req.data) {
      setLoading(false);
      updateHospitalLocal(id, values);
      navigate("/hospitals");
    }
  };

  return (
    <div>
      <SideBar>
        <NavbarComp />
        {/* =========== Add Hospital Form ================ */}
        <Spin spinning={loading}>
          <section className="doctorDetails navbarBodyMargin">
            <div className="container-fluid">
              <div className="AddHospital-main-Head">
                <div className="AddHospital-Heading">
                  <h1 className="pageTitle">
                    {id === "add" ? "Add Hospital" : "Edit Hospital"}
                  </h1>
                </div>
                <div className="breadcrumb-con">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        class="breadcrumb-item active"
                        style={{ color: "#808080" }}
                        aria-current="page"
                      >
                        {id === "add" ? "Add Hospital" : "Edit Hospital"}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="AddHospital-head-con">
                    <div className="EditHOspital-ImageSelectcom">
                      <div>
                        <div className="col-12 mb-5">
                          <input
                            type="file"
                            id="ProfileImamges"
                            className="productImages"
                            onChange={handleChange}
                            name="image"
                            placeholder=""
                            accept=".jpg, .png, jpeg"
                          />
                          <link
                            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
                            rel="stylesheet"
                          />

                          <div className="profileImage-con">
                            <div className="profileImage">
                              <img
                                src={imageFile ? imageFile : HospitalVector}
                                className="main-profile-img fatchImage"
                                alt="hospital"
                              />
                              <label className="iputIcon" for="ProfileImamges">
                                <i className="fa fa-edit"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="card-style settings-card-2 mb-30">
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={id === "add" ? handleSubmit : EditHospital}
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="Hospital Name"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter hospital name",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                className="name1"
                                placeholder="Full Name"
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="Phone No"
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter phone number",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Please enter valid phone number",
                                },
                              ]}
                            >
                              <Input type="number" placeholder="Phone No" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-lg-12">
                          <div className="input-style-1">
                            <Form.Item
                              label="Address"
                              name="address"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Address" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="City"
                              name="city"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter City Name" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="State"
                              name="state"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter State" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="Pincode"
                              name="pincode"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter pincode",
                                },
                                {
                                  max: 7,
                                  message: "Please enter valid pincode",
                                },
                                {
                                  min: 6,
                                  message: "Please enter valid pincode",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Enter Pincode"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="input-style-1">
                            <Form.Item
                              label="Google Map Link"
                              name="gmblink"
                              rules={[
                                {
                                  required: true,
                                  message: "Please add google map link",
                                },
                                {
                                  type: "url",
                                  message: "Please enter valid link",
                                },
                              ]}
                            >
                              <Input placeholder="Google MyBusiness Link" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="input-style-1">
                            <Form.Item
                              name="shortDesc"
                              label="Hospital Description"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter hospital description",
                                },
                              ]}
                            >
                              <TextArea
                                placeholder="Type here"
                                rows={6}
                                showCount
                                maxLength={500}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12">
                          <Button
                            htmlType="submit"
                            type="submit"
                            className="Clickbtn"
                          >
                            {id === "add" ? "Add Hospital" : "Update Hospital"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>
      </SideBar>
    </div>
  );
};

export default AddHospital;
