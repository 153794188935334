import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import "../css/notificationSetting.css";
import email from "../Images/email.webp";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { getToken } from "../services/localStorageServices";

export default function EmailSetting() {
  const [data, setData] = useState(null);
  const { access_token } = getToken();

  const fetchDataHandle = async () => {
    const response = await fetch(
      "https://skdm.in/skddev/DrCrm/message_api/message_api_checked_list.php",
      {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          type: "email",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const req = await response.json();
    console.log(req);
    setData(req);
  };
  useEffect(() => {
    fetchDataHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (index) => {
    const updatedData = [...data.template]; // Create a copy of the template array
    updatedData[index].enabled = updatedData[index].enabled === "1" ? "0" : "1"; // Toggle the enabled value
    setData({ ...data, template: updatedData }); // Update the state with the modified array
  };

  return (
    <div>
      <NavbarComp />
      <SideBar />
      <div className="notificationSection navbarBodyMargin">
        <h3 className="pageTitle">Email Setting</h3>

        <div className="notificationDetails">
          <div className="notificationDetailLeft">
            <div className="notificationUsed">
              <h5>
                <span>
                  <img src={email} alt="total Used" />
                  {data && data.totalUsedCredit}
                </span>
                <span>Total Email Send</span>
              </h5>
            </div>
            <div className="smsBookButtons">
              <NavLink to="/logs/email">
                <button className="submitBtn">
                  <AiOutlineUnorderedList />
                  &nbsp;View Email Logs
                </button>
              </NavLink>
            </div>
          </div>
          <div className="notificationRight d-none d-xl-block">
            <img
              src="https://img.freepik.com/free-vector/email-campaign-concept-illustration_114360-1681.jpg?w=2000"
              alt=""
            />
          </div>
        </div>
        <hr />
        <div className="smsSetting">
          <h3>Select Email setting</h3>
          <div className="checkList">
            <div className="row">
              {data &&
                data.template.map((value, i) => (
                  <div key={i} className="col-lg-4 col-md-6 col-12 mb-2">
                    <div class="form-check" key={i}>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={value.UserTemplateID}
                        id={value.TemplateType}
                        checked={value.enabled === "1"}
                        onChange={() => handleChange(i)}
                      />
                      <label class="form-check-label" for={value.TemplateType}>
                        {value.template_text}
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
