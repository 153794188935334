import React from "react";

const Certificate = ({
    preview,
    headerHeight,
    footerHeight,
    patientDetail,
    dateTime,
    withHeader,
}) => {
  let doctorData = JSON.parse(sessionStorage.getItem("doctorData"));

  const TemplateFontSize = {
    fontSize: `16px`,
    fontFamily: `Roboto', sans-serif`,
    margin: "0 0 0 0",
  };
  function dateToAge(birthdate) {
    const now = new Date();
    const birth = new Date(birthdate);
    let age = now.getFullYear() - birth.getFullYear();
    const monthDiff = now.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }

  const ImageStyle = {
    width: "100%",
    height: "1100px",
  };

  const positionStyle = {
    zindex: "10",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  }

  return (
    <>
      <div
        className="template"
        style={{
          "max-width": "100%",
          width: "100%",
          padding: "5px",
          position: "relative",
          zIndex: 10000,
        }}
      >
        {withHeader ? (
          <div className="headerFooterImg" style={ImageStyle}>
            <img src={preview} alt="latter head" style={{ width: "100%", height: "100%" }} />
          </div>
        ) : null}

        <div
          className="templateViewSection"
          style={withHeader ? positionStyle : null}
        >
          <div
            className="header"
            style={{ width: "100%", height: `${headerHeight}px` }}
          ></div>
          <div
            style={{
              width: "100%",
            }}
          >
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  <td>MEDICAL FITNESS CERTIFICATE</td>
                </tr>
              </tbody>
            </table>
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ paddingTop: 60, textDecoration: "underline" }}>
                    TO WHOMSOEVER IT MAY CONCERN
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ textAlign: "right", width: "100%" }}>
              <tbody>
                <tr>
                  <td style={TemplateFontSize}>
                    <strong>{dateTime.date}</strong>
                    {/* <td>20 Mar 2023</td> */}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{ textAlign: "initial", width: "100%", paddingTop: 100 }}
            >
              <tbody>
                <tr>
                  <td style={TemplateFontSize}>
                    This to certify that I have clinically evaluated
                    {patientDetail.gender === "Male" ? "Mr." : "Mrs."}{" "}
                    {patientDetail.name}
                  </td>
                </tr>
                <tr>
                  <td style={TemplateFontSize}>
                    Age {dateToAge(patientDetail.date)} years. He has been found
                    to be physically/developmentally fit for Sick leave.

                    {/* Age 20 years. He has been found
                    to be physically/developmentally fit for Sick leave. */}
                  </td>
                </tr>
                <tr>
                  <td style={TemplateFontSize}>
                    {" "}
                    {patientDetail.gender === "Male" ? "He" : "She"} has
                    completed age appropriate immunizations.
                  </td>
                </tr>
                <tr>
                  <td style={TemplateFontSize}>
                    {patientDetail.gender === "Male" ? "He" : "She"} does not
                    suffer from any known allergies to food/drugs.
                  </td>
                </tr>
                <tr>
                  <td style={TemplateFontSize}>
                    {patientDetail.gender === "Male" ? "He" : "She"} is not on
                    any chronic medications.
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%" }}>
              <tbody style={{ padding: "0 10px" }}>
                <tr style={{ textAlign: "left" }}>
                  <td style={{ textAlign: "right" }}>
                    <div
                      className="doctorSignatueDetails"
                      style={{
                        margin: "0px 15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      <div className="signImg">
                        <img
                          style={{ maxWidth: "100px" }}
                          src={doctorData.singnature}
                          alt=""
                        />
                      </div>
                      <div
                        className="doctorName"
                        style={{ width: "100%", textAlign: "end" }}
                      >
                        <strong style={TemplateFontSize}>
                          Dr.{" "}
                          {doctorData.first_name + " " + doctorData.last_name}
                        </strong>
                        <p style={TemplateFontSize}>
                          {doctorData.degree +
                            ", (" +
                            doctorData.specialtion +
                            ") " +
                            doctorData.additional_qaulification}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="footer"
              style={{ width: "100%", height: `${footerHeight}px` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificate;
