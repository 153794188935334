import React, { useRef } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useReferenceRegisterMutation } from "../../services/userAuthAPI"; 
import { getToken } from "../../services/localStorageServices";


const ReferalRefrenceModal = ({ show, hideModal, type, referalDataList, setReferalDataList,setErrorMessage,setOpen,referenceDataList, setReferenceDataList }) => {
  const formRef = useRef(null);

  const [referenceRegister] = useReferenceRegisterMutation();
  const { access_token } = getToken();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(formRef.current);
    const listData = referalDataList;
    const listDataRef = referenceDataList;
    const min = 1;
      const max = 100;

    // do something with the form data, e.g. send it to the server
    const referalData = {
      account_id_reff:"id_" + Math.floor(min + Math.random() * (max - min)),
        doctor_references_name: data.get("name"),
        doctor_refrences_specialization: data.get("speciallization"),
        doctor_refernces_mobile: data.get("mobile"),
        doctor_refernces_email: data.get("email"),
        doctor_referncesl_address: data.get("address"),
        doctor_references_type:type,
      token: access_token,
    };

    listData.push(referalData);
    listDataRef.push(referalData)

    setReferalDataList([...listData]);
    setReferenceDataList([...listDataRef])

    const req = await referenceRegister(referalData);
    if(req.data){
      hideModal();
      setErrorMessage("Added Successfull");
      setOpen(true);
    }
  

  };

  return (
    <Modal show={show} onHide={hideModal} centered style={{zIndex:"10000000000"}}>
      <Modal.Header closeButton>
        <Modal.Title>Add {type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef}>
          <Form.Group controlId="formBasicFirstName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter full name"
              name="name"
            />
          </Form.Group>

          <Form.Group controlId="formBasicFirstName">
            <Form.Label>Speciallization</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Speciallization"
              name="speciallization"
            />
          </Form.Group>

          <Form.Group controlId="formBasicLastName">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter mobile no."
              name="mobile"
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" name="email" />
          </Form.Group>

          <Form.Group controlId="formBasicPhone">
            <Form.Label>Address</Form.Label>
            <textarea className="form-control" name="address" placeholder="Enter Address" row="6" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ReferalRefrenceModal;
