import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import "../css/PrescriptionAttributes.css";
import { getToken } from "../services/localStorageServices";
import { Skeleton } from "@mui/material";
import { useTemplateStyleInputMutation } from "../services/userAuthAPI";
import { Spin, message } from "antd";

const PrescriptionAttributes = () => {
  const { access_token } = getToken();
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [checkedID, setCheckedID] = useState([]);
  const [hopitalView, setHopitalView] = useState([]);
  const [hospitalId, setHospitalId] = useState();

  const hospitalView = () => {
    fetch("https://skdm.in/skddev/DrCrm/Account_List_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setHopitalView(apiData);
        setHospitalId(apiData[0].list_of_account);
        AttributeList(apiData[0].list_of_account);
      });
  };

  useEffect(() => {
    const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

    if (hospital_list === null || hospital_list.length === 0) {
      hospitalView();
    } else {
      setHopitalView(hospital_list);
      setHospitalId(hospital_list[0].list_of_account);
      AttributeList(hospital_list[0].list_of_account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AttributeList = async (hospitalIdData) => {
    setLoader(true);

    try {
      const attributeLocal = JSON.parse(
        sessionStorage.getItem("attributeList")
      );

      if (!attributeLocal || attributeLocal.length === 0) {
        const response = await fetch(
          "https://skdm.in/skddev/DrCrm/template_attribute_view.php",
          {
            method: "POST",
            body: JSON.stringify({
              token: access_token,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );

        const data = await response.json();

        sessionStorage.setItem("attributeList", JSON.stringify(data));
        setAttributeList(data);
      } else {
        setAttributeList(attributeLocal);
      }

      templateListFetch(hospitalIdData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  // ============== View Template Inputs from database ==============
  const templateListFetch = (hospitalIdData) => {
    const localData = sessionStorage.getItem("template");
    if (!localData || localData.length === 0) {
      fetch("https://skdm.in/skddev/DrCrm/fetch_template_property.php", {
        method: "post",
        header: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          token: access_token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          sessionStorage.setItem("template", JSON.stringify(data));
          templateDataSetting(data, hospitalIdData);
        });
    } else {
      templateDataSetting(JSON.parse(localData), hospitalIdData);
    }
  };

  const templateDataSetting = (fetchData, hospitalIdData) => {
    if (fetchData && fetchData.length > 0) {
      const activeTemplateSetting = fetchData.filter(
        (value) => value.hospitalId === hospitalIdData
      );
      if (activeTemplateSetting && activeTemplateSetting.length > 0) {
        const inputString = activeTemplateSetting[0].template_attribute;
        const arrayFromString = inputString.split(",").map(Number);
        setCheckedID(arrayFromString);
      } else {
        const inputString =
          "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27";
        const arrayFromString = inputString.split(",").map(Number);
        setCheckedID(arrayFromString);
      }
    }
  };

  const skeletonArray = [];

  for (let i = 0; i < 16; i++) {
    skeletonArray.push(
      <div className="col-3 mb-5">
        <div className="row">
          <div className="col-4 d-flex justify-content-end align-items-center">
            <Skeleton
              animation="wave"
              variant="rounded"
              width={50}
              height={50}
            />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-12 d-flex justify-content-start align-items-start">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={200}
                  height={20}
                />
              </div>
              <div className="col-12 d-flex justify-content-start align-items-end mt-2">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={140}
                  height={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const checkAttribute = (id) => {
    if (!checkedID.includes(id)) {
      const updatedCheckedID = [...checkedID, id];
      setCheckedID(updatedCheckedID);
    } else {
      const updatedCheckedID = checkedID.filter(
        (checkedID) => checkedID !== id
      );
      setCheckedID(updatedCheckedID);
    }
  };

  // =============================== Save Attribute Setting ========================
  const [templateStyleInput] = useTemplateStyleInputMutation();

  const saveAttributes = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    const data = new FormData(e.currentTarget);
    if (data.get("settignApply") === "this") {
      formData.append("template_attribute_id", checkedID.join(","));
      formData.append("token", access_token);
      formData.append("hospital_id[]", hospitalId);
      const req = await templateStyleInput(formData);

      if (req.data) {
        setLoading(false);
        localDataUpdate("one");
        message.success("Setting Updated Succesfully");
      }
    } else {
      formData.append("template_attribute_id", checkedID.join(","));
      formData.append("token", access_token);
      hopitalView.forEach((value) => {
        formData.append("hospital_id[]", value.list_of_account);
      });
      const req = await templateStyleInput(formData);
      console.log(req);
      setLoading(false);
      localDataUpdate("all");
      message.success("Setting Updated Succesfully");
    }
  };

  const localDataUpdate = (type) => {
    const foundData = JSON.parse(sessionStorage.getItem("template"));
    if (type === "one") {
      const index = foundData.findIndex(
        (value) => value.hospitalId === hospitalId
      );
      if (index !== -1) {
        foundData[index] = {
          ...foundData[index],
          template_attribute: checkedID.join(","),
        };
        sessionStorage.setItem("template", JSON.stringify(foundData));
      } else {
        const foundData = JSON.parse(sessionStorage.getItem("template"));

        const newData = {
          hospitalId: hospitalId,
          template_name: "Template 1",
          template_attribute: checkedID.join(","),
          template_style: {
            template_doctor_detail: "",
            template_font_style: "'Roboto', sans-serif",
            template_footer_height: 0,
            template_header_height: 0,
            template_left_margin: 0,
            template_letter_head: null,
            template_right_margin: 0,
            template_signature: null,
            template_size: "14",
          },
        };

        foundData.push(newData);
        sessionStorage.setItem("template", JSON.stringify(foundData));
      }
    } else {
      hopitalView.forEach((valueData) => {
        const index = foundData.findIndex(
          (value) => value.hospitalId === valueData.list_of_account
        );
        if (index !== -1) {
          foundData[index] = {
            ...foundData[index],
            template_attribute: checkedID.join(","),
          };
          sessionStorage.setItem("template", JSON.stringify(foundData));
        } else {
          const foundData = JSON.parse(sessionStorage.getItem("template"));

          const newData = {
            hospitalId: valueData.list_of_account,
            template_name: "Template 1",
            template_attribute: checkedID.join(","),
            template_style: {
              template_doctor_detail: "",
              template_font_style: "'Roboto', sans-serif",
              template_footer_height: 0,
              template_header_height: 0,
              template_left_margin: 0,
              template_letter_head: null,
              template_right_margin: 0,
              template_signature: null,
              template_size: "14",
            },
          };

          foundData.push(newData);
          sessionStorage.setItem("template", JSON.stringify(foundData));
        }
      });
    }
  };

  return (
    <div>
      <SideBar>
        <NavbarComp />
        <Spin spinning={loading}>
          <div className="prescriptionAttributes navbarBodyMargin">
            {/* Heading */}
            <div className="attributesHead">
              <div className="attributesHeadText d-flex align-items-center">
                <h4 className="pageTitle">Prescription Templates Setting</h4>
              </div>
            </div>
            <hr />
            {/* Attributes */}
            <div className="attributesSection">
              <div className="filterDetails d-flex justify-content-between align-items-center">
                <div className="hospitalDetails">
                  <h5 className="mb-2">Hospitals List</h5>
                  <select
                    className="form-select"
                    name="clinic"
                    id="clinic"
                    onChange={(e) => {
                      templateListFetch(e.target.value);
                      setHospitalId(e.target.value);
                    }}
                  >
                    {hopitalView.map((value, i) => (
                      <option key={i} value={value.list_of_account}>
                        {value.first_name}
                      </option>
                    ))}
                  </select>
                </div>
                <form onSubmit={saveAttributes}>
                  <div className="attributesHeadButton d-flex align-items-center">
                    <h5>Apply&nbsp;setting&nbsp;to</h5>&nbsp;
                    <select className="form-select" name="settignApply">
                      <option value="this" selected>
                        Only this
                      </option>
                      <option value="all">All</option>
                    </select>
                    &nbsp;
                    <button type="submit" className="Clickbtn">
                      Save
                    </button>
                  </div>
                </form>
              </div>
              <hr />
              {loader ? (
                <div className="row">{skeletonArray}</div>
              ) : (
                <div className="attributesList">
                  {attributeList.length > 0 ? (
                    <div className="row">
                      {attributeList.map((value, i) => (
                        <div key={i} className="col-4">
                          <div className="input-attribute">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={"id_" + value.template_attribute_id}
                              value={value.template_attribute_id}
                              onChange={() =>
                                checkAttribute(
                                  Number(value.template_attribute_id)
                                )
                              }
                              checked={checkedID.includes(
                                Number(value.template_attribute_id)
                              )}
                            />
                            <label
                              htmlFor={"id_" + value.template_attribute_id}
                            >
                              {value.template_label_name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h1>No Data Found</h1>
                  )}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </SideBar>
    </div>
  );
};

export default PrescriptionAttributes;
