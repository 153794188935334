import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsFillCloudUploadFill } from "react-icons/bs";
import moment from "moment";
import { getToken } from "../../services/localStorageServices";
import { BiErrorCircle } from "react-icons/bi";
import patient_data from "../../Images/patient_data.csv";
import { Spin, message } from "antd";

function FileUpload() {
  const [show, setShow] = useState(false);
  const [dataArray, setDataArray] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  const [response, setResponse] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  //   const [file, setFile] = useState(null);

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    setSelectFile(acceptedFiles[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Extract headers (first row) and data rows (rest of the rows)
      const headers = dataArray[0];

      const requiredHeaders = ["Name", "Sex", "DOB", "Email", "Mobile"];

      const missingHeaders = requiredHeaders.filter(
        (header) => !dataArray[0].includes(header)
      );

      if (missingHeaders.length > 0) {
        setResponse({
          messageType: "error",
          message: `Sheet format is not appropriate. Missing headers: ${missingHeaders.join(
            ", "
          )}`,
        });
      } else {
        const dataObjects = dataArray.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            if (header === "Name") {
              const nameParts = row[index].split(" "); // Split the "Name" field into parts
              obj["patient_fname"] = nameParts[0] || ""; // Assign the first part as "FirstName"
              obj["patient_lname"] = nameParts.slice(1).join(" ") || ""; // Assign the remaining parts as "LastName"
            } else if (header === "Mobile") {
              obj["patient_phone_no"] = row[index];
            } else if (header === "DOB") {
              if (
                row[index] === "" ||
                row[index] === null ||
                typeof row[index] === "undefined"
              ) {
                obj["pateint_date_of_birth"] = null;
              } else {
                obj["pateint_date_of_birth"] = moment(row[index]).format(
                  "YYYY-MM-DD"
                );
              }
            } else if (header === "Sex") {
              obj["patient_gender"] = row[index];
            } else if (header === "Email") {
              obj["patient_email"] = row[index];
            } else {
              obj[header] = row[index];
            }
          });
          return obj;
        });
        if (dataObjects.length > 500) {
          setDataArray(null);
          setResponse({
            messageType: "error",
            message: "Sorry you can upload max 500 data at time",
          });
          message.error("Sorry you can upload max 500 data at time")
        } else if (dataObjects.length <= 0 || dataObjects === null) {
          setDataArray(null);
          setResponse({
            messageType: "error",
            message: "No data found in your file",
          });
          message.error("No data found in your file")
        } else {
          setResponse(null);
          setDataArray(dataObjects);
        }
      }
    };

    reader.readAsBinaryString(file);
  };

  //   ======================== Submit Data =================

  const { access_token } = getToken();

  const handleSubmit = async () => {
    setLoading(true);
    const response = await fetch(
      "https://skdm.in/skddev/DrCrm/patient_add_file.php",
      {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          data: dataArray,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const req = await response.json();
    if (req.status) {
      setShow(false);
      setDataArray(null);
      setSelectFile(null);
      message.success("Uploaded Successfully")
      setLoading(false);
    }
    //  else {
    //   setDataArray(null);
    //   setSelectFile(null);
    //   setOpenBakcDrop(false);
    //   setMessageType("error");
    //   setAlertText("Unable to upload something wrong !");
    //   setOpen(true);
    // }
  };

  return (
    <div>
      <Tooltip title="Upload Excel Sheet" arrow>
        <button
          className="Clickbtn d-flex align-items-center"
          onClick={handleShow}
        >
          <BsFillCloudUploadFill />
          &nbsp; Upload File
        </button>
      </Tooltip>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Spin spinning={loading}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Patient Excel File</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center">
            <Dropzone
              onDrop={handleFileUpload}
              accept=".xlsx, .xls, .xlsx, .csv, .gsheet"
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <img
                    style={{
                      margin: "auto",
                      width: "100%",
                      height: "100%",
                      maxWidth: "350px",
                    }}
                    src="https://blog.testproject.io/wp-content/uploads/2020/01/uploading2.jpg"
                    alt=""
                  />
                  <p
                    style={{
                      padding: "15px 25px",
                      borderRadius: "7px",
                      border: "2px dashed #111",
                    }}
                  >
                    <BsFillCloudUploadFill /> Drag &amp; drop a spreadsheet file
                    here, or{" "}
                    <button className="btn btn-dark">Select File</button>
                    {selectFile ? (
                      <p>
                        <AiFillCheckCircle style={{ color: "green" }} />
                        &nbsp;{selectFile.name}
                      </p>
                    ) : null}
                    {response ? (
                      <p style={{ color: "red" }}>
                        <BiErrorCircle />
                        &nbsp;{response.message}
                      </p>
                    ) : null}
                  </p>
                </div>
              )}
            </Dropzone>
            <strong>
              Note: The Column names must be Name, Sex, DOB, Mobile, Email Or
              Example Sheet{" "}
              <a href={patient_data} download="patient_data.csv">
                <button className="btn btn-success">Download</button>
              </a>
            </strong>
          </Modal.Body>
          <Modal.Footer>
            <button className="Cancelbtn" onClick={handleClose}>
              Close
            </button>
            <Button
              disabled={dataArray === null}
              className="Clickbtn"
              onClick={handleSubmit}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Spin>
      </Modal>
    </div>
  );
}

export default FileUpload;
