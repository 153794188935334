import React, { useState, useEffect } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import { useTemplateStyleInputMutation } from "../services/userAuthAPI";
import { getToken } from "../services/localStorageServices";
import "../css/PrescriptionTemplates.css";
import { Box, Drawer } from "@mui/material";
import { AiFillSetting } from "react-icons/ai";
import TextEditor from "../components/TextEditor/TextEditor";
import TemplateOneView from "../components/Templates/Template1/TemplateOneView";
import { Spin, message } from "antd";
import { useImageResizer } from "../context/imageResizer";
import TemplateTwoView from "../components/Templates/Template2/TemplateTwoView";

const PrescriptionTemplates = () => {
  // ============= Letter Head Img Preview ==============

  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [signatureFile, setSignatureFile] = useState(null);
  // create a preview as a side effect, whenever selected file is changed

  // ================================ Hospital View ====================

  const [hopitalView, setHopitalView] = useState([]);
  const [hospitalId, setHospitalId] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [templateSetting, setTemplateSetting] = useState({
    fontStyle: "'Roboto', sans-serif",
    fontSize: "14",
    headerHeight: 0,
    footerHeight: 0,
    leftSpace: 0,
    rightSpace: 0,
    doctorDetails: "",
    letterHead: null,
    signature: null,
  });
  const [activeTemplate, setActiveTemplate] = useState();
  const [state, setState] = React.useState({
    right: false,
  });
  // ===================== Module droawer =================
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // ============================== Hospital View =====================
  const hospitalView = async () => {
    try {
      setLoading(true);
      fetch("https://skdm.in/skddev/DrCrm/Account_List_View.php", {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())
        .then((apiData) => {
          setLoading(false);
          sessionStorage.setItem("hospaitl_list", JSON.stringify(apiData));
          setHopitalView(apiData);
          const hospitalIdData = apiData[0].list_of_account;
          setHospitalId(hospitalIdData);
          templateListFetch(hospitalIdData);
          // getTemplateData(hospitalIdData);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

    if (hospital_list === null || hospital_list.length === 0) {
      hospitalView();
    } else {
      setHopitalView(hospital_list);
      const hospitalIdData = hospital_list[0].list_of_account;
      setHospitalId(hospitalIdData);
      templateListFetch(hospitalIdData);
      // getTemplateData(hospitalIdData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { resizeFile } = useImageResizer();

  const onSelectFile = async (e) => {
    const maxWidth = 1200;
    const maxHeight = 1700;
    const targetFileSize = 150 * 1024;
    const imageExtension = "WEBP";

    const compresedFile = await resizeFile(
      e.target.files[0],
      maxWidth,
      maxHeight,
      imageExtension,
      targetFileSize
    );
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(compresedFile);

    const objectUrl = URL.createObjectURL(compresedFile);
    setTemplateSetting({ ...templateSetting, letterHead: objectUrl });
  };

  // =================================== Signature ===========================

  const onSignatureSelect = async (e) => {
    const maxWidth = 150;
    const maxHeight = 150;
    const targetFileSize = 30 * 1024;
    const imageExtension = "PNG";

    const compresedFile = await resizeFile(
      e.target.files[0],
      maxWidth,
      maxHeight,
      imageExtension,
      targetFileSize
    );
    // I've kept this example simple by using the first image instead of multiple
    setSignatureFile(compresedFile);

    const objectUrl = URL.createObjectURL(compresedFile);
    setTemplateSetting({ ...templateSetting, signature: objectUrl });
  };

  // ============== Send CSS from Input to database ==================
  const [templateStyleInput] = useTemplateStyleInputMutation();

  const { access_token } = getToken();

  const TemplateStyleInput = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formData = new FormData();

    if (data.get("settignApply") === "this") {
      setLoading(true);

      if (selectedFile) {
        formData.append("template_letter_head", selectedFile);
      }
      if (signatureFile) {
        formData.append("template_signature", signatureFile);
      }
      formData.append("template_size", templateSetting.fontSize);
      formData.append("template_left_margin", templateSetting.leftSpace);
      formData.append("template_right_margin", templateSetting.rightSpace);
      formData.append("template_header_height", templateSetting.headerHeight);
      formData.append("template_footer_height", templateSetting.footerHeight);
      formData.append("template_font_style", templateSetting.fontStyle);
      formData.append("template_doctor_detail", templateSetting.doctorDetails);
      formData.append("template_active_name", activeTemplate);
      formData.append("token", access_token);
      formData.append("hospital_id[]", hospitalId);

      const req = await templateStyleInput(formData);

      if (req.data) {
        localDataUpdate("one");
        setLoading(false);
        message.success("Setting Updated Succesfully");
      }
    } else {
      setLoading(true);

      if (selectedFile) {
        formData.append("template_letter_head", selectedFile);
      }
      if (signatureFile) {
        formData.append("template_signature", signatureFile);
      }
      formData.append("template_size", templateSetting.fontSize);
      formData.append("template_left_margin", templateSetting.leftSpace);
      formData.append("template_right_margin", templateSetting.rightSpace);
      formData.append("template_header_height", templateSetting.headerHeight);
      formData.append("template_footer_height", templateSetting.footerHeight);
      formData.append("template_font_style", templateSetting.fontStyle);
      formData.append("template_doctor_detail", templateSetting.doctorDetails);
      formData.append("template_active_name", activeTemplate);
      formData.append("token", access_token);
      hopitalView.forEach((value) => {
        formData.append("hospital_id[]", value.list_of_account);
      });

      const req = await templateStyleInput(formData);
      console.log(req)
        localDataUpdate("all");
        setLoading(false);
        message.success("Setting Updated Succesfully");
      
    }

  };


  // ===================== Store Data into local variable ===============

  const localDataUpdate = (type) => {
    const foundData = JSON.parse(sessionStorage.getItem("template"));

    if(type === "one"){
    const index = foundData.findIndex(
      (value) => value.hospitalId === hospitalId
    );

    if (index !== -1) {
      foundData[index] = {
        ...foundData[index],
        template_name: activeTemplate,
        template_style: {
          template_doctor_detail: templateSetting.doctorDetails,
          template_font_style: templateSetting.fontStyle,
          template_footer_height: templateSetting.footerHeight,
          template_header_height: templateSetting.headerHeight,
          template_left_margin: templateSetting.leftSpace,
          template_letter_head: templateSetting.letterHead,
          template_right_margin: templateSetting.rightSpace,
          template_signature: templateSetting.signature,
          template_size: templateSetting.fontSize,
        },
      };
      sessionStorage.setItem("template", JSON.stringify(foundData));
    } else {
      const foundData = JSON.parse(sessionStorage.getItem("template"));

      const newData = {
        hospitalId: hospitalId,
        template_name: activeTemplate,
        template_attribute:
          "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26",
        template_style: {
          template_doctor_detail: templateSetting.doctorDetails,
          template_font_style: templateSetting.fontStyle,
          template_footer_height: templateSetting.footerHeight,
          template_header_height: templateSetting.headerHeight,
          template_left_margin: templateSetting.leftSpace,
          template_letter_head: templateSetting.letterHead,
          template_right_margin: templateSetting.rightSpace,
          template_signature: templateSetting.signature,
          template_size: templateSetting.fontSize,
        },
      };

      foundData.push(newData);
      sessionStorage.setItem("template", JSON.stringify(foundData));
    }
  }else{
    hopitalView.forEach((valueData) => {
      const index = foundData.findIndex(
        (value) => value.hospitalId === valueData.list_of_account
      );
  
      if (index !== -1) {
        foundData[index] = {
          ...foundData[index],
          template_name: activeTemplate,
          template_style: {
            template_doctor_detail: templateSetting.doctorDetails,
            template_font_style: templateSetting.fontStyle,
            template_footer_height: templateSetting.footerHeight,
            template_header_height: templateSetting.headerHeight,
            template_left_margin: templateSetting.leftSpace,
            template_letter_head: templateSetting.letterHead,
            template_right_margin: templateSetting.rightSpace,
            template_signature: templateSetting.signature,
            template_size: templateSetting.fontSize,
          },
        };
        sessionStorage.setItem("template", JSON.stringify(foundData));
      } else {
        const foundData = JSON.parse(sessionStorage.getItem("template"));
  
        const newData = {
          hospitalId: valueData.list_of_account,
          template_name: activeTemplate,
          template_attribute:
            "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26",
          template_style: {
            template_doctor_detail: templateSetting.doctorDetails,
            template_font_style: templateSetting.fontStyle,
            template_footer_height: templateSetting.footerHeight,
            template_header_height: templateSetting.headerHeight,
            template_left_margin: templateSetting.leftSpace,
            template_letter_head: templateSetting.letterHead,
            template_right_margin: templateSetting.rightSpace,
            template_signature: templateSetting.signature,
            template_size: templateSetting.fontSize,
          },
        };
  
        foundData.push(newData);
        sessionStorage.setItem("template", JSON.stringify(foundData));
      }
    });
  }
  };

  // ============== View Template Inputs from database ==============
  const templateListFetch = (hospitalIdData) => {
    const localData = sessionStorage.getItem("template");
    if (localData === null || localData.length === 0) {
      setLoading(true);
      fetch("https://skdm.in/skddev/DrCrm/fetch_template_property.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          token: access_token, // Replace with your actual access token
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          SettingArrayData(data, hospitalIdData);
          sessionStorage.setItem("template", JSON.stringify(data));
        });
    } else {
      SettingArrayData(JSON.parse(localData), hospitalIdData);
    }
  };

  const SettingArrayData = (fetchData, hospitalIdData) => {
    if (fetchData && fetchData.length > 0) {
      const activeTemplateSetting = fetchData.filter(
        (value) => value.hospitalId === hospitalIdData
      );
      if (activeTemplateSetting && activeTemplateSetting.length > 0) {
        setActiveTemplate(activeTemplateSetting[0].template_name);
        setDefaultValue(
          activeTemplateSetting[0].template_style.template_doctor_detail
        );
        setTemplateSetting({
          ...templateSetting,
          fontStyle:
            activeTemplateSetting[0].template_style.template_font_style,
          fontSize: activeTemplateSetting[0].template_style.template_size,
          footerHeight:
            activeTemplateSetting[0].template_style.template_footer_height,
          headerHeight:
            activeTemplateSetting[0].template_style.template_header_height,
          leftSpace:
            activeTemplateSetting[0].template_style.template_left_margin,
          rightSpace:
            activeTemplateSetting[0].template_style.template_right_margin,
          letterHead:
            activeTemplateSetting[0].template_style.template_letter_head,
          signature: activeTemplateSetting[0].template_style.template_signature,
          doctorDetails:
            activeTemplateSetting[0].template_style.template_doctor_detail,
        });
      } else {
        setActiveTemplate("Template 1");
        setTemplateSetting({
          fontStyle: "'Roboto', sans-serif",
          fontSize: "14",
          headerHeight: 0,
          footerHeight: 0,
          leftSpace: 0,
          rightSpace: 0,
          doctorDetails: "",
          letterHead: null,
          signature: null,
        });
      }
    }
  };
  return (
    <div>
      <SideBar>
        <NavbarComp />
        <Spin spinning={loading}>
          <form onSubmit={TemplateStyleInput}>
            <div className="prescriptionTemplates navbarBodyMargin">
              {/* Heading */}
              <div className="prescriptionHead">
                <div className="prescriptionHeadText d-flex align-items-center">
                  <h4 className="pageTitle">Prescription Templates Setting</h4>
                </div>
                <div className="prescriptionHeadBtn d-flex">
                  {/* <div className="letterHeadAdjustBtn">
                  <button>Letterhead Adjustment</button>
                </div> */}
                  <div className="templateSaveBtn d-flex align-items-center">
                    <h5>Apply&nbsp;setting&nbsp;to</h5>&nbsp;
                    <select className="form-select" name="settignApply">
                      <option value="this" selected>
                        Only this
                      </option>
                      <option value="all">All</option>
                    </select>
                    &nbsp;
                    <button type="submit" className="Clickbtn">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {/* Main content */}
              <div className="row">
                {/* Template settings */}
                <div className="col-5 PriscriptionTemplates-Info d-none d-lg-block">
                  <div className="templateSettingSection">
                    {/*  Clinic Select */}
                    <div className="settingSelectClinic">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6>Clinic</h6>
                        </div>
                        <div className="col-6">
                          <div className="inputSelectClinic">
                            <select
                              name="clinic"
                              id="clinic"
                              onChange={(e) => {
                                templateListFetch(e.target.value);
                                setHospitalId(e.target.value);
                              }}
                            >
                              {hopitalView.map((value, i) => (
                                <option key={i} value={value.list_of_account}>
                                  {value.first_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Font Style*/}
                    <div className="settingSelectFont">
                      <div className="row">
                        <div className="col-3 d-flex align-items-center">
                          <h6>Font Style</h6>
                        </div>
                        <div className="col-3">
                          {/* <div className="resetFontBtn">
                          <button>Reset</button>
                        </div> */}
                        </div>
                        <div className="col-6">
                          <select
                            name="templatFont"
                            id="templatFont"
                            onChange={(e) =>
                              setTemplateSetting({
                                ...templateSetting,
                                fontStyle: e.target.value,
                              })
                            }
                          >
                            {templateSetting.fontStyle ===
                            "'Roboto', sans-serif" ? (
                              <option value="'Roboto', sans-serif" selected>
                                Roboto
                              </option>
                            ) : (
                              <option value="'Roboto', sans-serif">
                                Roboto
                              </option>
                            )}
                            {templateSetting.fontStyle ===
                            "'Poppins', sans-serif" ? (
                              <option value="'Poppins', sans-serif" selected>
                                Poppins
                              </option>
                            ) : (
                              <option value="'Poppins', sans-serif">
                                Poppins
                              </option>
                            )}
                            {templateSetting.fontStyle ===
                            "'Raleway', sans-serif" ? (
                              <option value="'Raleway', sans-serif" selected>
                                Raleway
                              </option>
                            ) : (
                              <option value="'Raleway', sans-serif">
                                Raleway
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* Font Size  */}
                    <div className="settingFontSize">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6>Font Size (px)</h6>
                        </div>
                        <div className="col-6">
                          <input
                            type="number"
                            name="tempFontSize"
                            value={templateSetting.fontSize}
                            onChange={(e) =>
                              setTemplateSetting({
                                ...templateSetting,
                                fontSize: e.target.value,
                              })
                            }
                            className="PrescriptionInputFSize"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Prescription Generation Setting */}
                    <div className="prescriptionGenerationSetting">
                      <div className="generationSettingsHead">
                        <h5>Prescription Generation Settings</h5>
                      </div>
                      <div className="generationSettingOptions">
                        <div className="row">
                          <div className="col-6">
                            <div className="input-style-1">
                              <label>Header Height(cm)</label>
                              <input
                                type="number"
                                className="form-control"
                                name="headerHeight"
                                value={templateSetting.headerHeight}
                                onChange={(e) =>
                                  setTemplateSetting({
                                    ...templateSetting,
                                    headerHeight: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-style-1">
                              <label>Footer Height(cm)</label>
                              <input
                                type="number"
                                className="form-control"
                                name="footerHeight"
                                value={templateSetting.footerHeight}
                                onChange={(e) =>
                                  setTemplateSetting({
                                    ...templateSetting,
                                    footerHeight: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-style-1">
                              <label>Left Space(cm)</label>
                              <input
                                type="number"
                                className="form-control"
                                name="tempMarginLeft"
                                value={templateSetting.leftSpace}
                                onChange={(e) =>
                                  setTemplateSetting({
                                    ...templateSetting,
                                    leftSpace: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-style-1">
                              <label>Right Space(cm)</label>
                              <input
                                type="number"
                                className="form-control"
                                name="tempMarginRight"
                                value={templateSetting.rightSpace}
                                onChange={(e) =>
                                  setTemplateSetting({
                                    ...templateSetting,
                                    rightSpace: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="createFotter">
                      <div className="letterHeadTitle">
                        <h5>Doctor Details in footer</h5>
                        <TextEditor
                          setTemplateSetting={setTemplateSetting}
                          templateSetting={templateSetting}
                          defaultValue={defaultValue}
                        />
                      </div>
                    </div>
                    <hr />
                    {/* Letter Head */}
                    <div className="letterHeadSection">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="letterHeadTitle">
                            <h5>Letter Head</h5>
                          </div>
                          <div className="letterHeadInput">
                            <div className="input-style-1">
                              <input
                                type="file"
                                name="letterHeadImg"
                                onChange={onSelectFile}
                                accept=".png,.jpg,.jpeg"
                              />
                            </div>
                            <div className="letterHeadImg">
                              {templateSetting.letterHead ? (
                                <img
                                  src={templateSetting.letterHead}
                                  alt="letter head"
                                />
                              ) : (
                                <h6>No Latter Head set yet</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="letterHeadTitle">
                            <h5>Signature</h5>
                          </div>
                          <div className="letterHeadInput">
                            <div className="input-style-1">
                              <input
                                type="file"
                                name="letterHeadImg"
                                onChange={onSignatureSelect}
                                accept=".png,.jpg,.jpeg"
                              />
                            </div>
                            <div className="letterHeadImg">
                              {templateSetting.signature ? (
                                <img
                                  src={templateSetting.signature}
                                  alt="Signature"
                                />
                              ) : (
                                <h6>No Signature Upload yet</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Template View  */}
                <div className="col-lg-7 col-md-12 PriscriptionTemplates-Priscription">
                  <div className="templateRadioBtns ">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="temp1"
                      name="chooseTemplate"
                      value="Template 1"
                      checked={activeTemplate === "Template 1"}
                      onChange={(e) => setActiveTemplate(e.target.value)}
                    />
                    <label htmlFor="temp1">
                      <strong>&nbsp;Template 1</strong>
                    </label>

                    <input
                      type="radio"
                      className="form-check-input"
                      id="temp2"
                      name="chooseTemplate"
                      value="Template 2"
                      checked={activeTemplate === "Template 2"}
                      onChange={(e) => setActiveTemplate(e.target.value)}
                    />
                    <label htmlFor="temp2">
                      <strong>&nbsp;Template 2</strong>
                    </label>
                    {/* <input
                    type="radio"
                    id="temp2"
                    className="form-check-input"
                    name="chooseTemplate"
                    value="Template 2"
                    checked={activeTemplate === "Template 2"}
                    onChange={(e) => setActiveTemplate(e.target.value)}
                  />
                  <label htmlFor="temp2"><strong>&nbsp;Template 2</strong></label> */}
                  </div>

                  {activeTemplate === "Template 1" && (
                    <TemplateOneView
                      fontSize={templateSetting.fontSize}
                      fontFamily={templateSetting.fontStyle}
                    />
                  )}
                  {activeTemplate === "Template 2" && (
                    <TemplateTwoView
                      fontSize={templateSetting.fontSize}
                      fontFamily={templateSetting.fontStyle}
                    />
                  )}
                  {/* ====================== Show Setting Template ================= */}
                  <div className="Priscriptionicon-con">
                    <button
                      className="Priscriptioniconedit"
                      type="button"
                      onClick={toggleDrawer("right", true)}
                    >
                      <AiFillSetting />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Spin>
      </SideBar>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box sx={{ width: 350, overflowX: "hidden" }} role="presentation">
          <div className="templateSettingSection">
            {/*  Clinic Select */}
            <div className="settingSelectClinic">
              <div className="row">
                <div className="col-6 d-flex align-items-center">
                  <h6>Clinic</h6>
                </div>
                <div className="col-6">
                  <div className="inputSelectClinic">
                    <select name="clinic" id="clinic">
                      {hopitalView.map((value, i) => (
                        <option key={i} value={value.list_of_account}>
                          {value.first_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/*  Font Style*/}
            <div className="settingSelectFont">
              <div className="row">
                <div className="col-6 d-flex align-items-center">
                  <h6>Font Style (px)</h6>
                </div>
                <div className="col-6">
                  <select
                    name="templatFont"
                    id="templatFont"
                    onChange={(e) =>
                      setTemplateSetting({
                        ...templateSetting,
                        fontStyle: e.target.value,
                      })
                    }
                  >
                    {templateSetting.fontStyle === "'Roboto', sans-serif" ? (
                      <option value="'Roboto', sans-serif" selected>
                        Roboto
                      </option>
                    ) : (
                      <option value="'Roboto', sans-serif">Roboto</option>
                    )}
                    {templateSetting.fontStyle === "'Poppins', sans-serif" ? (
                      <option value="'Poppins', sans-serif" selected>
                        Poppins
                      </option>
                    ) : (
                      <option value="'Poppins', sans-serif">Poppins</option>
                    )}
                    {templateSetting.fontStyle === "'Raleway', sans-serif" ? (
                      <option value="'Raleway', sans-serif" selected>
                        Raleway
                      </option>
                    ) : (
                      <option value="'Raleway', sans-serif">Raleway</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* Font Size  */}
            <div className="settingFontSize">
              <div className="row">
                <div className="col-6 d-flex align-items-center">
                  <h6>Font Size</h6>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    name="tempFontSize"
                    value={templateSetting.fontSize}
                    onChange={(e) =>
                      setTemplateSetting({
                        ...templateSetting,
                        fontSize: e.target.value,
                      })
                    }
                    className="PrescriptionInputFSize"
                  />
                </div>
              </div>
            </div>
            <div className="prescriptionGenerationSetting">
              <div className="generationSettingsHead">
                <h5>Prescription Generation Settings</h5>
              </div>
              <div className="generationSettingOptions">
                <div className="row">
                  <div className="col-6">
                    <div className="input-style-1">
                      <label>Header Height(cm)</label>
                      <input
                        type="number"
                        className="form-control"
                        name="headerHeight"
                        value={templateSetting.headerHeight}
                        onChange={(e) =>
                          setTemplateSetting({
                            ...templateSetting,
                            headerHeight: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-style-1">
                      <label>Footer Height(cm)</label>
                      <input
                        type="number"
                        className="form-control"
                        name="footerHeight"
                        value={templateSetting.footerHeight}
                        onChange={(e) =>
                          setTemplateSetting({
                            ...templateSetting,
                            footerHeight: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-style-1">
                      <label>Left Space(cm)</label>
                      <input
                        type="number"
                        className="form-control"
                        name="tempMarginLeft"
                        value={templateSetting.leftSpace}
                        onChange={(e) =>
                          setTemplateSetting({
                            ...templateSetting,
                            leftSpace: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-style-1">
                      <label>Right Space(cm)</label>
                      <input
                        type="number"
                        className="form-control"
                        name="tempMarginRight"
                        value={templateSetting.rightSpace}
                        onChange={(e) =>
                          setTemplateSetting({
                            ...templateSetting,
                            rightSpace: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="letterHeadSection">
              <div className="row">
                <div className="col-sm-6">
                  <div className="letterHeadTitle">
                    <h5>Letter Head</h5>
                  </div>
                  <div className="letterHeadInput">
                    <div className="input-style-1">
                      <input
                        type="file"
                        name="letterHeadImg"
                        onChange={onSelectFile}
                        accept=".png,.jpg,.jpeg"
                      />
                    </div>
                    <div className="letterHeadImg">
                      {templateSetting.letterHead ? (
                        <img
                          src={templateSetting.letterHead}
                          alt="letter head"
                        />
                      ) : (
                        <h6>No Latter Head set yet</h6>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="letterHeadTitle">
                    <h5>Signature</h5>
                  </div>
                  <div className="letterHeadInput">
                    <div className="input-style-1">
                      <input
                        type="file"
                        name="letterHeadImg"
                        onChange={onSignatureSelect}
                        accept=".png,.jpg,.jpeg"
                      />
                    </div>
                    <div className="letterHeadImg">
                      {templateSetting.signature ? (
                        <img src={templateSetting.signature} alt="Signature" />
                      ) : (
                        <h6>No Signature Upload yet</h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default PrescriptionTemplates;
