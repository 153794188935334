import { useEffect, useState } from "react";

function useTemplateAttribute() {
  const [attributeList, setAttributeList] = useState([]);

  const handleAttributeList = async () => {
    try {
      const attributeLocal = JSON.parse(
        sessionStorage.getItem("attributeList")
      );

      if (!attributeLocal || attributeLocal.length === 0) {
        const res = await fetch(
          "https://skdm.in/skddev/DrCrm/template_attribute_view.php",
          {
            method: "GET",
          }
        );

        if (res.ok) {
          const data = await res.json();
          setAttributeList(data);
          sessionStorage.setItem("attributeList", JSON.stringify(data));
        }
      } else {
        setAttributeList(attributeLocal);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleAttributeList();
  }, []);

  return attributeList;
}

export default useTemplateAttribute;
