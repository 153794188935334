import React from "react";

const NoDataFound = () =>{
    return(
        <>
        <div className="no_data_found">
            <div className="not_found_image" style={{maxWidth:"300px", margin: "auto"}}>
                <img style={{width:"100%", height: "100%"}} src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=2000" alt="" />
            </div>
        </div>
        </>
    )
}

export default NoDataFound;