import React, { useEffect, useState } from "react";
import $ from "jquery";
import "../css/Patients.css";
import { Link } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";

import {
  useDeletePatientMutation,
  useEditPatientMutation,
} from "../services/userAuthAPI";

import { getToken } from "../services/localStorageServices";
import { FaClipboardList, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "@mui/material/Tooltip";
import devil from "../Images/devil.webp";
import smile from "../Images/smile.webp";
import fa from "../Images/fa.webp";
import SearchIcon from "@mui/icons-material/Search";
import FileUpload from "../components/FileUploder/FileUploader";
import { Pagination, Spin, message } from "antd";
import NoDataFound from "./NoDataFound.jsx";

const Patinents = () => {
  const { access_token } = getToken();
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState(0);

  // =============== Patient Search ======================

  const searchPatient = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    setSearchValue(data.get("searchText"));
  };

  const PatientList = (pageData, limitData, search) => {
    setLoading(true);
    fetch("https://skdm.in/skddev/DrCrm/Patient_Profile_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        patient_search: search,
        page: pageData,
        limit: limitData,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())

      .then((apiData) => {
        setLoading(false);
        setListData(apiData.list);
        setTotal(apiData.total);
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    PatientList(page, limit, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, searchValue]);

  const [deletePatient] = useDeletePatientMutation();

  const DeletePateint = async (pid, id) => {
    if (window.confirm("Are you sure to remove patient ?")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("patient_Id", pid);
      const req = await deletePatient(formData);

      if (req.data) {
        listData.splice(id, 1);
        setListData([...listData]);
        message.success("Patient Remove Succesfully");
        setLoading(false);
      }
    }
  };

  $(".dropdown ul li").on("click", function () {
    var text = $(".default_option").text();
    $(".default_option").val(text);
    $(".dropdown ul").removeClass("active");
  });

  const [EditPatient] = useEditPatientMutation();

  const updateBookmark = async (name, index, id) => {
    setLoading(true);
    let newData = Array.from(listData);
    newData[index].bookmark = name;
    setListData(newData);

    const formData = new FormData();
    formData.append("patientID", id);
    formData.append("bookmark", name);
    formData.append("token", access_token);

    const req = await EditPatient(formData);

    if (req.data) {
      setLoading(false);
      message.success("Mark Updated To " + name);
    }

    if (req.error) {
      message.error("Unable to update bookmark");
    }
  };

  return (
    <div>
      <SideBar>
        <NavbarComp />
        {/* Patients List */}
        <div className="PatientsListSection navbarBodyMargin">
          <div className="patientListHead flex-wrap">
            <h1 className="pageTitle">Patients List</h1>
            <div className="addPatientBtn d-flex align-items-center mt-4 mt-sm-0">
              <FileUpload />
              <Link to="/PatientProfile/add">
                <Tooltip title="Add New Patient" arrow>
                  <button
                    className="Clickbtn"
                    data-bs-toggle="modal"
                    data-bs-target="#addTaskModal"
                  >
                    + Add Patient
                  </button>
                </Tooltip>
              </Link>
            </div>
          </div>

          {/* =========== Drugs Search Box ========================= */}
          <div className="DrugsPageSearchBox">
            <div className="searchBoxes">
              <form onSubmit={searchPatient}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <div className="searchDiv">
                      <input
                        className="form-control"
                        placeholder="Search"
                        type="search"
                        variant="outlined"
                        name="searchText"
                        fullWidth
                        auto
                        size="small"
                        // onChange={(e) => setSearchValue(e.target.value)}
                        autoComplete="off"
                      />
                      <div className="iconsStyle search">
                        <SearchIcon />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-2 mt-2">
                    <div className="searchDiv d-flex justify-content-between">
                    <button type="submit" className="Clickbtn">
                        Search
                      </button>
                      <button type="reset" className="btn btn-danger" onClick={()=>setSearchValue("")}>Reset</button>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <Spin spinning={loading}>
            {listData && listData.length > 0 ? (
              <>
                <div className="patientsListTable d-lg-block d-md-none d-none">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Patient ID</th>
                        <th scope="col">Profile</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Number</th>
                        <th scope="col">Email</th>
                        <th scope="col">Bookmark</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                        <th scope="col">History</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData &&
                        listData.map((workObj, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{ fontWeight: "500", color: "#2b5a89" }}
                            >
                              {workObj.pid}
                            </th>
                            <td className="d-flex justify-content-center">
                              <div className="patientImg">
                                <img
                                  src={
                                    workObj.patient_profile_Image
                                      ? workObj.patient_profile_Image
                                      : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                                  }
                                  alt={
                                    workObj.patient_first_name +
                                    " " +
                                    workObj.patient_last_name
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {workObj.patient_first_name +
                                " " +
                                workObj.patient_last_name}
                            </td>
                            <td>{workObj.patient_phone_no}</td>
                            <td>
                              <div className="careTakerNo">
                                {workObj.patient_email}
                              </div>
                            </td>
                            <td>
                              <div className="patientTypeBox favouriteClass">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <Tooltip
                                      title={
                                        workObj.bookmark !== null
                                          ? workObj.bookmark
                                          : "Add Mark"
                                      }
                                    >
                                      <img
                                        src={
                                          workObj.bookmark === "Favourite"
                                            ? smile
                                            : workObj.bookmark === "Frusted"
                                            ? devil
                                            : fa
                                        }
                                        style={{ width: "30px" }}
                                        alt="bookmark"
                                      />
                                    </Tooltip>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        updateBookmark(
                                          "Favourite",
                                          index,
                                          workObj.pid
                                        )
                                      }
                                    >
                                      <img
                                        src={smile}
                                        style={{
                                          width: "25px",
                                          margin: "0 10px 0 0",
                                        }}
                                        alt="Smile"
                                      />{" "}
                                      Favourite
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        updateBookmark(
                                          "Frusted",
                                          index,
                                          workObj.pid
                                        )
                                      }
                                    >
                                      <img
                                        src={devil}
                                        style={{
                                          width: "25px",
                                          margin: "0 10px 0 0",
                                        }}
                                        alt="frusted"
                                      />
                                      Frusted
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                            <td>
                              <Tooltip title="Edit Patient">
                                <Link to={`/PatientProfile/${workObj.pid}`}>
                                  <div className="iconsStyle edit">
                                    <FaEdit />
                                  </div>
                                </Link>
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="Remove Patient">
                                <div
                                  className="iconsStyle delete"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    DeletePateint(workObj.pid, index)
                                  }
                                >
                                  <MdDelete />
                                </div>
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="Patient History">
                                <Link
                                  to={`/viewHistory/${encodeURIComponent(
                                    workObj.pid
                                  )}/${encodeURIComponent(
                                    workObj.patient_first_name
                                  )}%20${encodeURIComponent(
                                    workObj.patient_last_name
                                  )}/${encodeURIComponent(
                                    workObj.pateint_date_of_birth
                                  )}/${encodeURIComponent(
                                    workObj.patient_gender
                                  )}`}
                                >
                                  <div className="iconsStyle sucess">
                                    <FaClipboardList
                                      style={{ fontSize: "1.2rem" }}
                                    />
                                  </div>
                                </Link>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <section className="profileSection d-lg-none d-md-block d-block">
                  <div className="container">
                    <div className="row">
                      {listData &&
                        listData.map((workObj, index) => (
                          <div key={index} className="col-md-6 col-12">
                            <div className="card">
                              <div className="d-flex">
                                <div class="cardBox ">
                                  <img
                                    src={
                                      workObj.patient_profile_Image
                                        ? workObj.patient_profile_Image
                                        : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                                    }
                                    alt={
                                      workObj.patient_first_name +
                                      " " +
                                      workObj.patient_last_name
                                    }
                                  />{" "}
                                </div>
                                <div className="Infos d-flex">
                                  <div className="Infoname">
                                    <h2 className="d-flex">
                                      {" "}
                                      {workObj.patient_first_name +
                                        " " +
                                        workObj.patient_last_name}
                                      &nbsp;
                                      <p className="d-flex justify-content">
                                        ({workObj.pid})
                                      </p>
                                    </h2>

                                    <p className=""> {workObj.patient_email}</p>
                                    <p className="">
                                      {workObj.patient_phone_no}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <ul className="stats">
                                <li>
                                  <h3>
                                    <div className="patientTypeBox favouriteClass">
                                      <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                          <Tooltip
                                            title={
                                              workObj.bookmark !== null
                                                ? workObj.bookmark
                                                : "Add Mark"
                                            }
                                          >
                                            <img
                                              src={
                                                workObj.bookmark === "Favourite"
                                                  ? smile
                                                  : workObj.bookmark ===
                                                    "Frusted"
                                                  ? devil
                                                  : fa
                                              }
                                              style={{ width: "30px" }}
                                              alt="bookmark"
                                            />
                                          </Tooltip>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              updateBookmark(
                                                "Favourite",
                                                index,
                                                workObj.pid
                                              )
                                            }
                                          >
                                            <img
                                              src={smile}
                                              style={{
                                                width: "25px",
                                                margin: "0 10px 0 0",
                                              }}
                                              alt="Smile"
                                            />{" "}
                                            Favourite
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              updateBookmark(
                                                "Frusted",
                                                index,
                                                workObj.pid
                                              )
                                            }
                                          >
                                            <img
                                              src={devil}
                                              style={{
                                                width: "25px",
                                                margin: "0 10px 0 0",
                                              }}
                                              alt="frusted"
                                            />{" "}
                                            Frusted
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </h3>
                                </li>
                                <li>
                                  <h3>
                                    <Link to={`/PatientProfile/${workObj.pid}`}>
                                      <div className="iconsStyle edit">
                                        <FaEdit />
                                      </div>
                                    </Link>
                                  </h3>
                                  {/* <h4>Views</h4> */}
                                </li>
                                <li>
                                  <h3>
                                    {" "}
                                    <div
                                      className="iconsStyle delete"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        DeletePateint(workObj.pid, index)
                                      }
                                    >
                                      <MdDelete />
                                    </div>
                                  </h3>
                                  {/* <h4>Projects</h4> */}
                                </li>
                                <li>
                                  <h3>
                                    {" "}
                                    <Link
                                      to={`/viewHistory/${encodeURIComponent(
                                        workObj.pid
                                      )}/${encodeURIComponent(
                                        workObj.patient_first_name
                                      )}%20${encodeURIComponent(
                                        workObj.patient_last_name
                                      )}/${encodeURIComponent(
                                        workObj.pateint_date_of_birth
                                      )}/${encodeURIComponent(
                                        workObj.patient_gender
                                      )}`}
                                    >
                                      <div className="iconsStyle sucess">
                                        <Tooltip
                                          title="Patient History"
                                          className=""
                                        >
                                          <FaClipboardList
                                            style={{ fontSize: "1.2rem" }}
                                          />
                                        </Tooltip>
                                      </div>
                                    </Link>
                                  </h3>
                                  {/* <h4>Followers</h4> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </section>

                <div className="container">
                  <div className="card">
                    <Pagination
                      total={total}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      pageSize={limit}
                      current={page}
                      onChange={(page, pageSize) => {
                        setPage(page);
                        setLimit(pageSize);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <NoDataFound />
            )}
          </Spin>
        </div>
        {/* -----------------respomsive card design------------- */}
      </SideBar>
    </div>
  );
};

export default Patinents;
