import React, { createContext, useContext } from "react";
import Resizer from "react-image-file-resizer";

const ImageResizer = createContext();

export const useImageResizer = () => useContext(ImageResizer);

const ImageResizerProvider = ({ children }) => {
  const resizeFile = (file, maxWidth, maxHeight, imageExtension, targetFileSize) =>
    new Promise((resolve) => {
      const quality = 100; // starting quality

      const adjustQuality = (currentQuality) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          imageExtension,
          currentQuality,
          0,
          (uri) => {
            if (uri.length <= targetFileSize || currentQuality <= 0) {
                const binaryData = base64ToBlob(uri.split(',')[1], 'image/jpeg');
                resolve(binaryData);
            } else {
              adjustQuality(currentQuality - 10);
            }
          },
          "base64"
        );
      };

      adjustQuality(quality);
    });

    const base64ToBlob = (base64String, mimeType) => {
        const byteCharacters = atob(base64String);
        const byteArray = new Uint8Array(byteCharacters.length);
      
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
      
        return new Blob([byteArray], { type: mimeType });
      };

  const contextValue = {
    resizeFile,
  };

  return (
    <ImageResizer.Provider value={contextValue}>
      {children}
    </ImageResizer.Provider>
  );
};

export default ImageResizerProvider;
