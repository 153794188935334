import React, { useEffect, useState } from "react";
import "../css/ServiceList.css";
import { getToken } from "../services/localStorageServices";
import { MdDelete, MdEdit } from "react-icons/md";
import HospitalVector from "../Images/hospital-vector.webp";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { useServiceAddMutation } from "../services/userAuthAPI";
import { useServiceEditMutation } from "../services/userAuthAPI";
import { useServiceDeleteMutation } from "../services/userAuthAPI";
import NoDataFound from "./NoDataFound";
import { Spin, message } from "antd";
import { useImageResizer } from "../context/imageResizer.js";

const ServiceList = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [serviceAdd] = useServiceAddMutation();
  const [serviceEdit] = useServiceEditMutation();
  const [serviceDelete] = useServiceDeleteMutation();
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();

  // const [listData, setListData] = useState([]);

  const [serviceListData, setServiceListData] = useState([]);

  const fetchServiceList = () => {
    setLoading(true);
    fetch("https://skdm.in/skddev/DrCrm/services_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())

      .then((apiData) => {
        setLoading(false);
        setServiceListData(apiData);
      });
  };

  useEffect(() => {
    fetchServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [file, setFile] = useState("");
  const [imageFile, setImageFile] = useState("");

  const { resizeFile } = useImageResizer();

  const handleChange = async (e) => {
    const maxWidth = 300;
    const maxHeight = 300;
    const targetFileSize = 70 * 1024;

    const compresedFile = await resizeFile(
      e.target.files[0],
      maxWidth,
      maxHeight,
      targetFileSize
    );
    setFile(compresedFile);
    setImageFile(URL.createObjectURL(compresedFile));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const serviceAddList = serviceListData;

    const data = new FormData(event.target);
    const formData = new FormData();

    formData.append("service_name", data.get("service_name"));
    formData.append("service_description", data.get("service_description"));
    formData.append("service_image", file);
    formData.append("token", access_token);
    // Display the form data object in the console (for demonstration)

    const req = await serviceAdd(formData);
    if (req.data) {
      setLoading(false);
      setOpen(false);
      message.success("Added Succesfully");
      const serviceData = {
        service_name: data.get("service_name"),
        service_description: data.get("service_description"),
        service_image: imageFile,
      };

      serviceAddList.push(serviceData);
      setServiceListData([...serviceAddList]);
      setFile("");
      setImageFile(null);

      event.target.reset();
    }

    if (req.error) {
      setLoading(true);
      message.error("unable to add please try again");
    }
    // You can perform further actions here, like sending the data to a server.
  };

  ///////////// edit Function /////////////////

  const handleEdit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.target);
    const formData = new FormData();

    formData.append("service_name", data.get("service_name"));
    formData.append("service_description", data.get("service_description"));
    if (file !== "") {
      formData.append("service_image", file);
    }
    formData.append("service_id", Editservice_Id);
    formData.append("token", access_token);
    // Display the form data object in the console (for demonstration)

    const req = await serviceEdit(formData);
    if (req.data) {
      setLoading(false);
      setOpen(false);
      message.success("Updated Successfully");
      setServiceListData((prevArray) => {
        const updatedArray = [...prevArray]; // Create a copy of the array
        updatedArray[Editservice_index] = {
          ...updatedArray[Editservice_index], // Create a copy of the object at the specified index
          service_name: data.get("service_name"), // Update the property within the object
          service_description: data.get("service_description"), // Update the property within the object
          service_image: imageFile, // Update the property within the object
        };
        return updatedArray; // Update the state with the updated array
      });
      setFile("");
      setImageFile(null);
      setEditShow(false);
      event.target.reset();
    }

    if (req.error) {
      message.error("Unable to update please try again");
    }
    // You can perform further actions here, like sending the data to a server.
  };

  const [editShow, setEditShow] = React.useState(false);

  const [Editservice_name, setEditservice_name] = useState();
  const [Editservice_description, setEditservice_description] = useState();
  const [Editservice_Id, setEditservice_Id] = useState();
  const [Editservice_index, setEditservice_index] = useState();

  const Editdatapop = () => {
    setEditShow(true);
  };

  const Editfun = (cdata, index) => {
    setEditservice_name(cdata.service_name);
    setEditservice_description(cdata.service_description);
    setImageFile(cdata.service_image);
    setEditservice_Id(cdata.service_id);
    setEditservice_index(index);
  };

  const deleteFun = async (ele, index) => {
    if (window.confirm("Are You Sure You Want To Delete This Service ?")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("service_id", ele);
      formData.append("token", access_token);

      const req = await serviceDelete(formData);

      if (req.data) {
        setLoading(false);
        serviceListData.splice(index, 1);
        message.success("Removed Successfully");
      }
    }
  };

  return (
    <>
      <SideBar>
        <NavbarComp />
        <section className="Service-Page navbarBodyMargin">
          <div className="container">
            <div className="row mt-2 mb-3">
              <div class="patientListHead">
                <h1>Services List</h1>
                <div class="addPatientBtn">
                  <button
                    class="btn Clickbtn"
                    onClick={() => {
                      handleOpen()
                      setEditShow(false);
                    }}
                  >
                    <span class="material-symbols-outlined icon">add</span>
                    Add Service
                  </button>
                </div>
              </div>
            </div>
            <hr />
            {
              <Spin spinning={loading}>
                <div className="Service_Page-Con mt-3">
                  <div className="row">
                    {serviceListData && serviceListData.length > 0 ? (
                      serviceListData.map((ele, index) => {
                        const {
                          service_name,
                          service_description,
                          service_image,
                        } = ele;
                        return (
                          <>
                            <div key={index} className="col-lg-6 col-md-6">
                              <div className="Service-card-con-2-Main">
                                <div className="Service-Image">
                                  <img src={service_image} alt="" />
                                </div>
                                <div className="Service-Card-Details">
                                  <h3>{service_name}</h3>
                                  <p>{service_description} </p>
                                </div>
                                <div className="Service-btn">
                                  <div className="row d-flex justify-content-between flex-row">
                                    <div className="col-12">
                                      <div className="Service-Edit-Btn">
                                        <button
                                          className="iconsStyle edit"
                                          onClick={(e) => {
                                            handleOpen();
                                            Editfun(ele, index);
                                            Editdatapop();
                                          }}
                                        >
                                          <MdEdit />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="Service-Remove-Btn">
                                        <button
                                          className="iconsStyle delete"
                                          onClick={(e) => {
                                            deleteFun(ele.service_id, index);
                                          }}
                                        >
                                          <MdDelete />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </Spin>
            }
          </div>
        </section>

        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className="popUpFormTitle">
              <span>
                {editShow === false ? "Add Services" : "Update Services"}
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {editShow === false ? (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="Add-Sevice-Image">
                        <input
                          type="file"
                          id="ProfileImamges"
                          className="ServiceCardImages"
                          name="service_image"
                          placeholder=""
                          accept=".jpg, .png, jpeg"
                          onChange={handleChange}
                        />
                        <link
                          href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
                          rel="stylesheet"
                        />

                        <div className="ServiceImage-con">
                          <div className="ServiceImage">
                            <img
                              src={file === "" ? HospitalVector : imageFile}
                              className="main-Service-img fatchImage"
                              alt="service"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="Services-popup-Inputs-main">
                        <div className="Service-Inputs">
                          <label>Service Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="service_name"
                            placeholder="Enter Service Name"
                          />
                        </div>

                        <div className="Service-Inputs">
                          <label>Service Description</label>
                          <textarea
                            className="form-control"
                            type="textarea"
                            name="service_description"
                            placeholder="Enter Service Description"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Service-popup-model-btn-con">
                    <button type="submit" className="Clickbtn" disabled={loading}>
                        {loading ? "Uplodiing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleEdit}>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="Add-Sevice-Image">
                        <input
                          type="file"
                          id="ProfileImamges"
                          className="ServiceCardImages"
                          name="service_image"
                          placeholder=""
                          accept=".jpg, .png, jpeg"
                          onChange={handleChange}
                        />
                        <link
                          href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
                          rel="stylesheet"
                        />

                        <div className="ServiceImage-con">
                          <div className="ServiceImage">
                            <img
                              src={imageFile ? imageFile : HospitalVector}
                              alt="service"
                              className="main-Service-img fatchImage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="Services-popup-Inputs-main">
                        <div className="Service-Inputs">
                          <label>Service Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="service_name"
                            placeholder="Enter Service Name"
                            defaultValue={Editservice_name}
                          />
                        </div>

                        <div className="Service-Inputs">
                          <label>Service Description</label>
                          <textarea
                            className="form-control"
                            type="textarea"
                            name="service_description"
                            placeholder="Enter Service Description"
                            defaultValue={Editservice_description}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Service-popup-model-btn-con">
                      <button type="submit" className="Clickbtn" disabled={loading}>
                        {loading ? "Uplodiing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </SideBar>
    </>
  );
};

export default ServiceList;
