import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userAuthApi = createApi({
  reducerPath: "userAuthApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://skdm.in/skddev/DrCrm/" }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (user) => {
        return {
          url: "accounts/register.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: "login.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    verifyOtp: builder.mutation({
      query: (user) => {
        return {
          url: "otp_verfication.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    DrProfileUpdate: builder.mutation({
      query: (user) => {
        return {
          url: "Profile_Update.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    addNewHospital: builder.mutation({
      query: (user) => {
        return {
          url: "Account_List_Registration.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    ViewHospital: builder.query({
      query: (data) => {
        return {
          url: "Account_List_View.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    ViewOnlyHospital: builder.query({
      query: (data) => {
        return {
          url: "Account_List_View.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    EditHospital: builder.mutation({
      query: (data) => {
        return {
          url: "Account_List_Update.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    DeleteHospital: builder.mutation({
      query: (user) => {
        return {
          url: "Account_List_Delete.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    ViewPateintProfileData: builder.mutation({
      query: (data) => {
        return {
          url: "Patient_Profile_View.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    AddPatient: builder.mutation({
      query: (user) => {
        return {
          url: "Patient_Profile_Registration.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    DeletePatient: builder.mutation({
      query: (user) => {
        return {
          url: "Patient_Profile_Delete.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    EditPatient: builder.mutation({
      query: (data) => {
        return {
          url: "Patient_Profile_Update.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    hospitalSchedule: builder.mutation({
      query: (user) => {
        return {
          url: "hospital_schedule_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    HospitalScheduleList: builder.query({
      query: (user) => {
        return {
          url: "hospital_schedul_view.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    DeletehospitalScheduleDay: builder.mutation({
      query: (user) => {
        return {
          url: "hospital_schedul_delete.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    diagnosisAdd: builder.mutation({
      query: (user) => {
        return {
          url: "diagnosis_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    patientDiagnosis: builder.mutation({
      query: (user) => {
        return {
          url: "patient_diagnosis_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    patientDrug: builder.mutation({
      query: (user) => {
        return {
          url: "patient_drug_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    patientInstructionData: builder.mutation({
      query: (user) => {
        return {
          url: "instruction_patient_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    diagnosisFetch: builder.query({
      query: (user) => {
        return {
          url: "diagnosis_view.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    diagnosisRegister: builder.mutation({
      query: (user) => {
        return {
          url: "patient_diagnosis_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    drugRegister: builder.mutation({
      query: (user) => {
        return {
          url: "drug_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    drugUpdate: builder.mutation({
      query: (user) => {
        return {
          url: "drug_update.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    insturctionRegister: builder.mutation({
      query: (user) => {
        return {
          url: "instruction_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    priscriptionUpdate: builder.mutation({
      query: (user) => {
        return {
          url: "booking_appointment_update.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    TemplateStyleInput: builder.mutation({
      query: (user) => {
        return {
          url: "template_detail.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    addDoctorDataList: builder.mutation({
      query: (data) => {
        return {
          url: "account_data_register.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    deleteTypeList: builder.mutation({
      query: (data) => {
        return {
          url: "account_data_delete.php",
          method: "post",
          body: data,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    drugDelete: builder.mutation({
      query: (user) => {
        return {
          url: "drug_delete.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    DrugFetch: builder.mutation({
      query: (user) => {
        return {
          url: "drug_view.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    NoteUpdated: builder.mutation({
      query: (user) => {
        return {
          url: "patient_desc_text_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    referenceRegister: builder.mutation({
      query: (user) => {
        return {
          url: "doctor_references_register.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    vitalDataRegister: builder.mutation({
      query: (user) => {
        return {
          url: "patient_vital_register.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    deleteVital: builder.mutation({
      query: (user) => {
        return {
          url: "patient_vital_delete.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    cerficateRegister: builder.mutation({
      query: (user) => {
        return {
          url: "certificate_register.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    documentImageRegister: builder.mutation({
      query: (user) => {
        return {
          url: "patient_desc_text_register.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    documentImageDelete: builder.mutation({
      query: (user) => {
        return {
          url: "patient_desc_text_delete.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    handoutAdd: builder.mutation({
      query: (user) => {
        return {
          url: "handout_catogery_add.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    handoutDelete: builder.mutation({
      query: (user) => {
        return {
          url: "handout_catogery_delete.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    handoutListAdd: builder.mutation({
      query: (user) => {
        return {
          url: "handout_add.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    handoutListDelete: builder.mutation({
      query: (user) => {
        return {
          url: "handout_delete.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    serviceAdd: builder.mutation({
      query: (user) => {
        return {
          url: "services_register.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    serviceEdit: builder.mutation({
      query: (user) => {
        return {
          url: "services_update.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    serviceDelete: builder.mutation({
      query: (user) => {
        return {
          url: "services_delete.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    socialMedia: builder.mutation({
      query: (user) => {
        return {
          url: "social_media.php/",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    savePriscription: builder.mutation({
      query: (user) => {
        return {
          url: "precription_api/appointment_add.php",
          method: "post",
          body: user,
          header: {
            "Content-type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useAddNewHospitalMutation,
  useDeleteHospitalMutation,
  useEditHospitalMutation,
  useVerifyOtpMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useViewHospitalQuery,
  useViewOnlyHospitalQuery,
  useViewPateintProfileQuery,
  useAddPatientMutation,
  useDeletePatientMutation,
  useEditPatientMutation,
  useHospitalScheduleMutation,
  useHospitalScheduleListQuery,
  useDeletehospitalScheduleDayMutation,
  useDiagnosisAddMutation,
  useDiagnosisFetchQuery,
  usePatientDiagnosisMutation,
  useDiagnosisRegisterMutation,
  useDrugRegisterMutation,
  useDrugUpdateMutation,
  useInsturctionRegisterMutation,
  usePriscriptionUpdateMutation,
  useViewTemplateStyleQuery,
  useTemplateStyleInputMutation,
  useAddDoctorDataListMutation,
  useDeleteTypeListMutation,
  useDrugDeleteMutation,
  useDrugFetchMutation,
  usePatientDrugMutation,
  usePatientInstructionDataMutation,
  useViewPateintProfileDataMutation,
  useNoteUpdatedMutation,
  useReferenceRegisterMutation,
  useDeleteVitalMutation,
  useVitalDataRegisterMutation,
  useCerficateRegisterMutation,
  useDocumentImageRegisterMutation,
  useDocumentImageDeleteMutation,
  useHandoutAddMutation,
  useHandoutDeleteMutation,
  useHandoutListAddMutation,
  useHandoutListDeleteMutation,
  useServiceAddMutation,
  useServiceEditMutation,
  useServiceDeleteMutation,
  useDrProfileUpdateMutation,
  useSocialMediaMutation,
  useSavePriscriptionMutation,
} = userAuthApi;
