import React, { useState, useEffect } from "react";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import SideBar from "../components/Sidebar/SideBar";
// Icons
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../css/References.css";
import { getToken } from "../services/localStorageServices";
import ReferalRefrenceModal from "../components/referalRefrenceModal/referalRefrenceModal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
const References = () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { access_token } = getToken();
  const [referalDataList, setReferalDataList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [referalType, setReferalType] = useState("");
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const referalRefrence = (type) => {
    setShowModal(true);
    setReferalType(type);
  };

  const referenceView = () => {
    setOpenBackdrop(true);
    fetch("https://skdm.in/skddev/DrCrm/doctor_references_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        references_type: "References",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())

      .then((apiData) => {
        if (!apiData || apiData === "No Data Found") {
          console.log(apiData);
        } else {
          setOpenBackdrop(false);
          setReferalDataList(apiData);
        }
      })
      .catch((error) => {
        console.error("Error fetching reference data:", error);
      });
  };

  useEffect(() => {
    referenceView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const DeletePateint = async (pid, id) => {
    if (window.confirm("Are you sure want to remove list ?")) {
      setOpenBackdrop(true);
      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("patient_Id", pid);
      // const req = await deletePatient(formData);

      // if (req.data) {
      setOpenBackdrop(false);
      referalDataList.splice(id, 1);
      setReferalDataList([...referalDataList]);
      setOpen(true);
      setErrorMessage("Reffrence Remove Succesfully");
      // }
    }
  };

  const referalRefrenceUpdate = (type, status, value, id) => {};

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <ReferalRefrenceModal
        type={referalType}
        show={showModal}
        setOpen={setOpen}
        setErrorMessage={setErrorMessage}
        hideModal={() => setShowModal(false)}
        referalDataList={referalDataList}
        setReferalDataList={setReferalDataList}
      />
      <SideBar>
        <NavbarComp />
        <div className="referencesPage navbarBodyMargin">
          {/* Head */}
          <div className="referencesHead">
            <div className="referenceHeadText">
              <h6>Doctor References Listing</h6>
            </div>
            <div className="addReferenceBtn">
              <button
                onClick={() => referalRefrence("References")}
                className="Clickbtn"
              >
                + Add Doctor Reference
              </button>
            </div>
          </div>
          {/* ======= References List ========= */}
          <div className="referencesListSection d-lg-block d-none">
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Specialization </th>
                  <th scope="col">Mobile</th>
                  <th scope="col">E-mail</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {referalDataList && referalDataList.length > 0 ? (
                  referalDataList.map((workObj, index) => (
                    <tr key={index}>
                      <td>{workObj.doctor_references_name}</td>
                      <td>{workObj.doctor_refrences_specialization}</td>
                      <td>{workObj.doctor_refernces_mobile}</td>
                      <td>{workObj.doctor_refernces_email}</td>

                      <td>
                        <button
                          className="iconsStyle sucess "
                          style={{ color: "#fff !important" }}
                          onClick={() =>
                            referalRefrenceUpdate(
                              "References",
                              "update",
                              workObj,
                              workObj.doctor_references_id
                            )
                          }
                        >
                          <AiFillEdit />
                        </button>
                      </td>
                      <td>
                        <button
                          className="iconsStyle delete"
                          style={{ color: "#fff !important" }}
                          onClick={() =>
                            DeletePateint(workObj.doctor_references_id, index)
                          }
                        >
                          <MdDelete />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </SideBar>
      <section className="d-lg-none d-md-block">
        <div className="container">
          <div className="row">
            {referalDataList && referalDataList.length > 0 ? (
              referalDataList.map((workObj, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-12">
                  <div className="customerCard">
                    <ul>
                      <li>
                        Name:
                        <span> {workObj.doctor_references_name}</span>
                      </li>
                      <li>
                        Spec:
                        <span> {workObj.doctor_refrences_specialization}</span>
                      </li>
                      <li>
                        Phone:
                        <span> {workObj.doctor_refernces_mobile}</span>
                      </li>
                      <li>
                        Email:
                        <span> {workObj.doctor_refernces_email}</span>
                      </li>
                    </ul>

                    <div className="smallButton">
                      <button
                        className="iconsStyle sucess "
                        style={{ color: "#fff !important" }}
                        onClick={() =>
                          referalRefrenceUpdate(
                            "References",
                            "update",
                            workObj,
                            workObj.doctor_references_id
                          )
                        }
                      >
                        <AiFillEdit />
                      </button>
                      <button
                        className="iconsStyle delete"
                        style={{ color: "#fff !important" }}
                        onClick={() =>
                          DeletePateint(workObj.doctor_references_id, index)
                        }
                      >
                        <MdDelete />
                      </button>
                    </div>
                    {/* <span>+ 100</span>
                <h5>stisfied Customers</h5>
                <h5></h5>
                <h5></h5>
                <h5></h5> */}
                  </div>
                </div>
              ))
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </section>
    </div>

    // ------------------------------------responsive code---------------------------
  );
};

export default References;
