import React, { useState } from "react";
import "../css/Forgot.css";
import { useNavigate } from "react-router-dom";
import { BiMailSend } from "react-icons/bi";
import { RiEdit2Fill } from "react-icons/ri";
import OTPInput, { ResendOTP } from "otp-input-react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useLoginUserMutation,
  useVerifyOtpMutation,
} from "../services/userAuthAPI";

const Forgot = () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);
  const [OTP, setOTP] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [activeOtp, setActiveOtp] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [verifyUser] = useVerifyOtpMutation();
  const [loginUser] = useLoginUserMutation();
  const [errorMessage, setErrorMessage] = useState({
    status: "error",
    message: "",
  });

  const sendOtpData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    setOpenBackdrop(true);

    if (data.get("phone") === "") {
      setOpenBackdrop(false);
      setErrorMessage({
        status: "error",
        message: "All Fields are required",
      });
      setOpen(true);
    } else if (data.get("phone").length > 10) {
      setOpenBackdrop(false);
      setErrorMessage({
        status: "error",
        message: "Please enter valid number",
      });
      setOpen(true);
    } else if (data.get("phone").length < 10) {
      setOpenBackdrop(false);
      setErrorMessage({
        status: "error",
        message: "Please enter valid number",
      });
      setOpen(true);
    }

    if (
      data.get("phone") !== "" &&
      data.get("phone").length === 10 &&
      data.get("password") !== ""
    ) {
      const res = await loginUser({ phone: data.get("phone") });

      if (res.data) {
        setOpen(true);
        setOpenBackdrop(false);
        setActiveOtp(true);
        setErrorMessage({
          status: "success",
          message: "OTP Sent to your Given number",
        });
        setToken(res.data.token)
        // fetch(
        //   "https://2factor.in/API/V1/52022fd1-7569-11ed-9158-0200cd936042/SMS/" +
        //     res.data.phone +
        //     "/" +
        //     res.data.otp +
        //     "/"
        // )
        //   .then((res) => res.json())
        //   .then((result) => {
        //     console.log(result);
        //   });
      }

      if (res.error) {
        setOpen(true);
        setOpenBackdrop(false);
        setActiveOtp(false);
        setErrorMessage({
          status: "error",
          message: "Given number is not register",
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

//   ===================== Resend Otp ===========================

const resendOtpData = async () => {
    setOpenBackdrop(true);
    setOpen(true);
    setErrorMessage({
      status: "success",
      message:"OTP Rsent to your Given number"
    });

    const res = await loginUser({ token: token });
 
    if (res.data) {
      setOpen(false)
      setOpenBackdrop(false);
      // fetch(
      //   "https://2factor.in/API/V1/52022fd1-7569-11ed-9158-0200cd936042/SMS/" +
      //     res.data.phone +
      //     "/" +
      //     res.data.otp +
      //     "/"
      // )
      //   .then((res) => res.json())
      //   .then((result) => {
      //     console.log(result);
      //   });
    }
  };

  const otpSubmit = async (e) => {

    const actualData = {
      token: token,
      otp: OTP,
    };
    if (OTP !== "") {
        setOpenBackdrop(true);

      const res = await verifyUser(actualData);
      if (res.error) {
        setOpenBackdrop(false);
        setOpen(true);
        setErrorMessage({
          ...errorMessage,
          message:"Please Enter valid OTP"
        });
      }

      if (res.data) {
        setOpenBackdrop(false);
        sessionStorage.setItem("token", res.data.token)
        // fetch(
        //   "https://2factor.in/API/V1/52022fd1-7569-11ed-9158-0200cd936042/SMS/" +
        //     res.data.phone +
        //     "/" +
        //     res.data.otp +
        //     "/"
        // )
        //   .then((res) => res.json())
        //   .then((result) => {
        //     console.log(result);
        //   });

        navigate("/newPassword");
      }
    }else {
        setOpen(true);
        setErrorMessage({
          status: "error",
          message:"Please Enter OTP"
        });
    }
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert
          onClose={handleClose}
          severity={errorMessage.status}
          sx={{ width: "100%" }}
        >
          {errorMessage.message}
        </Alert>
      </Snackbar>
      <section className="Login">
        <div className="container">
          <div className="screen">
            <div className="screen__content">
              <form className="login" onSubmit={sendOtpData}>
                <div className="login__field">
                  <i className="login__icon fas fa-user NumberIcon">
                    <BiMailSend />
                  </i>
                  <input
                    type="number"
                    name="phone"
                    className="login__input"
                    placeholder="Enter Number"
                    disabled={activeOtp ? true : false}
                  />
                  {activeOtp ? (
                    <span
                      className="login__icon"
                      onClick={() => setActiveOtp(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <RiEdit2Fill />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {activeOtp ? (
                  <>
                    <div className="login__field otpin">
                      <OTPInput
                        className="otpin-con"
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure
                      />
                      <ResendOTP
                        className="resendbtn"
                        onResendClick={resendOtpData}
                      />
                    </div>
                    <div className="button login__submit" onClick={otpSubmit}>
                      <span className="button__text">Submit</span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* <Link to="/Password"> */}
                {activeOtp ? (
                  ""
                ) : (
                  <button
                    type="submit"
                    className="button login__submit SecondBtn"
                  >
                    <span className="button__text" type="submit">
                      Send OTP
                    </span>
                  </button>
                )}
                {/* </Link> */}
              </form>
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgot;
