import React, { useState, useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate } from "react-router-dom";
import {
  useLoginUserMutation,
  useVerifyOtpMutation,
} from "../services/userAuthAPI";
import "../css/EnterOtp.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import VerificationImage1 from "../Images/smartphone1.webp";
import { getToken, storeToken } from "../services/localStorageServices";
import { useDispatch } from "react-redux";
import { setUserToken } from "../features/authSlice";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const EnterOtp = (buttonProps) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: "error",
    message: ""
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { access_token } = getToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [verifyUser] = useVerifyOtpMutation();
  const [loginUser] = useLoginUserMutation();
  const token = sessionStorage.getItem("token");

  const otpSubmit = async (e) => {
    setOpenBackdrop(true);

    const actualData = {
      token: token,
      otp: OTP,
    };
    if (OTP !== "") {
      const res = await verifyUser(actualData);
      if (res.error) {
        setOpenBackdrop(false);
        setOpen(true);
        setErrorMessage({
          ...errorMessage,
          message:"Please Enter valid OTP"
        });
      }

      if (res.data) {
        setOpenBackdrop(false);
        sessionStorage.removeItem("token");
        storeToken(res.data.token);
        dispatch(setUserToken({ token: res.data.token }));
        navigate("/dashboard");
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);

  const resendOtpData = async () => {
    setOpenBackdrop(true);
    setOpen(true);
    setErrorMessage({
      status: "success",
      message:"OTP Sent to your register number"
    });

    const res = await loginUser({ token: token });
 
    if (res.data) {
      setOpen(false)
      setOpenBackdrop(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage.message}
        </Alert>
      </Snackbar>
      <section className="Login">
        <div className="container">
          <div className="screen">
            <div className="screen__content">
              <div className="Verification-Image-con">
                <div className="Verification-Image">
                  <img src={VerificationImage1} alt="" />
                </div>
              </div>
              <div className="Verification-Text-con">
                <div className="Verification-Text">
                  <h4>Verification</h4>
                  <p>You will get a OTP via SMS</p>
                </div>
              </div>
              <div className="Verification-form">
                <div className="login__field otpin">
                  <OTPInput
                    className="otpin-con"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                  />
                  <ResendOTP
                    className="resendbtn"
                    onResendClick={resendOtpData}
                  />
                </div>
                <button className="button login__submit" onClick={otpSubmit}>
                  <span className="button__text">Submit</span>
                  <i className="button__icon fas fa-chevron-right">
                    <MdOutlineArrowForwardIos />
                  </i>
                </button>
              </div>
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnterOtp;
