import * as React from "react";
import { getToken } from "../../services/localStorageServices";
import {
  useDeletehospitalScheduleDayMutation,
  useHospitalScheduleMutation,
} from "../../services/userAuthAPI";
import { FaCheck } from "react-icons/fa6";
import { BsFillCalendarPlusFill } from "react-icons/bs";
import moment from "moment";
import "./sheduleCss.css";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
  Spin,
  Table,
  TimePicker,
  message,
} from "antd";

import NoDataFound from "../../pages/NoDataFound";
import { FaTrashAlt } from "react-icons/fa";

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function AlertDialogSlide({
  hospitalShedule,
  hospitalID,
  hospitalName,
}) {
  const { access_token } = getToken();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [sheduleList, setSheduleList] = React.useState(hospitalShedule);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [hospitalSchedule] = useHospitalScheduleMutation();

  const addDayTime = async (values) => {
    setErrorMessage("");
    setLoading(true);

    const InsertData = {
      hospital_Week_name: values.selectedDays,
      hospital_Start_Time: moment(new Date(values.shedule[0])).format("h:mm a"),
      hospital_End_Time: moment(new Date(values.shedule[1])).format("h:mm a"),
      hospital_Slot_Duration: values.slotDuration,
      hospital_id: hospitalID,
      token: access_token,
    };


    const req = await hospitalSchedule(InsertData);

    if (req.error) {
      setLoading(false);
      setErrorMessage(req.error.data.status);
      message.error("Failed to add");
    }

    if (req.data) {
      message.success("Addedd successfully")
      setLoading(false);
      setErrorMessage(null);
      // ===================== Add In Array ===========================

      const hospitalSheduleData = values.selectedDays.map((value) => ({
        hospital_schedule_Id: req.data.sheduleIds.find(
          (sheduleId) => sheduleId.hospital_weeek_name === value
        ).hospital_schedule_Id,
        hospital_weeek_name: value,
        hospital_start_time: moment(new Date(values.shedule[0])).format(
          "h:mm a"
        ),
        hospital_end_time: moment(new Date(values.shedule[1])).format("h:mm a"),
        hospital_slot_duration: values.slotDuration,
        hospital_id: hospitalID,
      }));

      setSheduleList((prev) => [...prev, ...hospitalSheduleData]);

      const hospaitl_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));
      const indexToUpdate = hospaitl_list.findIndex(
        (item) => item.list_of_account === hospitalID
      );

      if (indexToUpdate !== -1) {
        // The item was found, so you can update it
        const newData = [...sheduleList, ...hospitalSheduleData]; // Replace with the new data
        hospaitl_list[indexToUpdate].hospital_shedule = newData;

        // Store the updated object back in sessionStorage
        sessionStorage.setItem("hospaitl_list", JSON.stringify(hospaitl_list));
      } else {
        // Item not found
        console.log("Item not found in the array.");
      }
      form.resetFields();
    }
  };


  const columns = [
    {
      title: "Days",
      dataIndex: "hospital_weeek_name",
      key: "hospital_weeek_name",
    },
    {
      title: "Start Time",
      dataIndex: "hospital_start_time",
      key: "hospital_start_time",
    },
    {
      title: "End Time",
      dataIndex: "hospital_end_time",
      key: "hospital_end_time",
    },
    {
      title: "Slot in minute",
      dataIndex: "hospital_slot_duration",
      key: "hospital_slot_duration",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "hospital_schedule_Id",
      render: (_, record) => (
        <button
          onClick={() => deleteDayTime(record.hospital_schedule_Id)}
          style={{ background: "transparent", color: "red", border: "unset" }}
        >
          {<FaTrashAlt />}
        </button>
      ),
    },
  ];

  // ============ Shedule day delete =====================

  const [deleteScheduleDay] = useDeletehospitalScheduleDayMutation();

  const deleteDayTime = async (hospital_schedule_Id) => {
    if (window.confirm("Are you sure you want to delete this schedule day?")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("Hospital_Sedule_Id", hospital_schedule_Id);
      const req = await deleteScheduleDay(formData);

      if (req.data) {
        setLoading(false);
        const indexData = sheduleList.findIndex((value) => value.hospital_schedule_Id === hospital_schedule_Id);
        sheduleList.splice(indexData, 1);
        setSheduleList([...sheduleList]);

        const hospaitl_list = JSON.parse(
          sessionStorage.getItem("hospaitl_list")
        );
        const index = hospaitl_list.findIndex(
          (value) => value.list_of_account === hospitalID
        );
        hospaitl_list[index].hospital_shedule = hospitalShedule;
        sessionStorage.setItem("hospaitl_list", JSON.stringify(hospaitl_list));
      }
    }
  };

  // Define a custom sorting order for days
  const daysOrder = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  React.useEffect(() => {
    sheduleList.sort((a, b) => {
      return (
        daysOrder.indexOf(a.hospital_weeek_name) -
        daysOrder.indexOf(b.hospital_weeek_name)
      );
    });
    // eslint-disable-next-line
  }, [sheduleList]);


  return (
    <div>
      <button
        title="Add Shedule"
        className="iconsStyle sucess"
        onClick={showModal}
      >
        <BsFillCalendarPlusFill />
      </button>

      <Modal
        centered
        title={hospitalName}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={800}
      >
        <Spin spinning={loading}>
          {sheduleList && sheduleList.length > 0 ? (
            <Table
              pagination={false}
              columns={columns}
              dataSource={sheduleList}
            />
          ) : (
            <NoDataFound />
          )}
          <Form
            className="mt-4"
            onFinish={addDayTime}
            name="basic"
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className="row">
              <div className="col-3 px-0">
                <Form.Item
                  label="Select days"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  name="selectedDays"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Days"
                  >
                    {names.map((name) =>
                      sheduleList &&
                      sheduleList.some(
                        (value) => value.hospital_weeek_name === name
                      ) ? null : (
                        <Select.Option key={name} value={name}>
                          {name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-5">
                <Form.Item
                  label="Select shedule"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  name="shedule"
                >
                  <TimePicker.RangePicker
                    style={{
                      width: "100%",
                    }}
                    format="h:mm a"
                  />
                </Form.Item>
              </div>
              <div className="col-2 px-0">
                <Form.Item
                  label="Slots in minute"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  name="slotDuration"
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-1">
                <Form.Item label=" " tooltip="Add shedule">
                  <Button
                    htmlType="submit"
                    style={{
                      background: "green",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "50px",
                    }}
                  >
                    <FaCheck />
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          <span style={{color:"red"}}>{errorMessage}</span>
        </Spin>
      </Modal>
    </div>
  );
}

export default AlertDialogSlide;
