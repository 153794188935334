import React, { useState, useEffect } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { MdDelete } from "react-icons/md";
import "../css/Drugs.css";
import AddDrugBox from "../components/AddDrug/AddDrugBox";
import { getToken } from "../services/localStorageServices";
import SearchIcon from "@mui/icons-material/Search";

import {
  useDrugDeleteMutation,
  useDrugFetchMutation,
} from "../services/userAuthAPI";
import UpdateDrug from "../components/AddDrug/UpdateDrug";
import { Tooltip, message } from "antd";
import TableComp from "../components/Table/TableComp";

const Drugs = () => {
  const columns = [
    {
      title: "Drug Name",
      dataIndex: "brand_name",
      width: 40,
      align: "left",
      fixed: "left",
    },
    {
      title: "Type",
      dataIndex: "medicine_category",
      width: 30,
      align: "center",
    },
    {
      title: "Strength",
      dataIndex: "strength_medicine",
      width: 30,
      align: "center",
    },
    {
      title: "Relation",
      dataIndex: "relation_of_food",
      width: 25,
      align: "center",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: 25,
      align: "center",
    },
    {
      title: "Instruction",
      dataIndex: "drug_instruction",
      align: "left",
      width: 80,
    },
    {
      title: "",
      dataIndex: "edit",
      width: 20,
      align: "right",
      fixed: "right",
    },
    {
      title: "",
      dataIndex: "remove",
      width: 20,
      align: "right",
      fixed: "right",
    },
  ];

  const { access_token } = getToken();
  const [suggestionList, setSuggestionList] = useState([]);
  const [deleteDrug] = useDrugDeleteMutation();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(null);

  // =============== Drug Search ======================

  const [DrugFetch] = useDrugFetchMutation();

  const searchDrug = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.currentTarget);
    const drugListData = {
      page: 1,
      drug_search: data.get("searchText"),
      token: access_token,
      limit: limit,
    };
    const req = await DrugFetch(drugListData);
    if (req.data) {
      setLoading(false);
      const updateData = req.data.data.map((item, index) => ({
        ...item,
        drug_instruction:
          item.drug_instruction === "" ? (
            <span style={{ width: "100%", textAlign: "center" }}>-</span>
          ) : (
            item.drug_instruction
          ),
        strength_medicine:
          item.strength_medicine === " mg" ? (
            <span style={{ width: "100%", textAlign: "center" }}>-</span>
          ) : (
            item.strength_medicine
          ),
        medicine_category:
          item.medicine_category === null ? (
            <span style={{ width: "100%", textAlign: "center" }}>-</span>
          ) : (
            item.medicine_category
          ),
        edit: (
          <UpdateDrug
            index={index}
            drugList={item}
            drugId={item.drug_id}
            setSuggestionList={setSuggestionList}
            suggestionList={suggestionList}
          />
        ),
        remove: (
          <Tooltip title="Remove Drug" placement="bottom">
            <span
              className="iconsStyle delete "
              onClick={() => DeleteDrug(item.drug_id, index)}
            >
              <MdDelete />
            </span>
          </Tooltip>
        ),
      }));
      setTotal(req.data.total);
      setSuggestionList(updateData);
    } else {
      setLoading(false);
      setTotal(0);
      setSuggestionList(req.data);
      // window.confirm("No Data Found!!!");
    }
    e.target.reset();
  };

  // ===============Load More ===================
  const [loading, setLoading] = React.useState(true);

  const DrugListFetch = (pageData, limitData, search) => {
    setLoading(true);
    fetch("https://skdm.in/skddev/DrCrm/drug_view.php", {
      method: "POST",
      body: JSON.stringify({
        drug_search: search,
        token: access_token,
        page: pageData,
        limit: limitData,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setLoading(false);
        const updateData = apiData.data.map((item, index) => ({
          ...item,
          drug_instruction:
            item.drug_instruction === "" ? (
              <span style={{ width: "100%", textAlign: "center" }}>-</span>
            ) : (
              item.drug_instruction
            ),
          strength_medicine:
            item.strength_medicine === " mg" ? (
              <span style={{ width: "100%", textAlign: "center" }}>-</span>
            ) : (
              item.strength_medicine
            ),
          medicine_category:
            item.medicine_category === null ? (
              <span style={{ width: "100%", textAlign: "center" }}>-</span>
            ) : (
              item.medicine_category
            ),
          edit: (
            <UpdateDrug
              index={index}
              drugList={item}
              drugId={item.drug_id}
              setSuggestionList={setSuggestionList}
              suggestionList={suggestionList}
            />
          ),
          remove: (
            <Tooltip title="Remove Drug" placement="bottom">
              <span
                className="iconsStyle delete "
                onClick={() => DeleteDrug(item.drug_id, index)}
              >
                <MdDelete />
              </span>
            </Tooltip>
          ),
        }));
        setTotal(apiData.total);
        setSuggestionList(updateData);
      })
      .catch((error) => {
        console.error("Error fetching drug data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    DrugListFetch(page, limit, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DeleteDrug = async (drugID, index) => {
    message.loading("Removing...");
    if (window.confirm("Are you sure you want to delete this drug?")) {
      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("drug_Id", drugID);
      const req = await deleteDrug(formData);

      if (req.data) {
        const listedArray = [...suggestionList];
        listedArray.splice(index, 1);
        setSuggestionList(listedArray);
        message.success("Drug Removed Successfully");
      }
      if (req.error) {
        message.error("Failed to remove drug");
      }
    }
  };

  return (
    <div>
      <SideBar>
        <NavbarComp />
        <div className="drugsPage navbarBodyMargin">
          <div className="drugHead">
            <div className="drugHeadText">
              <h6 className="pageTitle">Drugs List</h6>
            </div>
            <div className="drugHeadButtons">
              <AddDrugBox
                suggestionList={suggestionList}
                setSuggestionList={setSuggestionList}
              />
            </div>
          </div>

          {/* =========== Drugs Search Box ========================= */}
          <div className="DrugsPageSearchBox">
            <div className="searchBoxes">
              <form onSubmit={searchDrug}>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-8 col-12 mb-3  mb-md-0 d-flex align-items-center">
                    <div className="searchDiv">
                      <input
                        className="form-control"
                        placeholder="Search"
                        type="text"
                        variant="outlined"
                        name="searchText"
                        fullWidth
                        size="small"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      />
                      <div className="iconsStyle search">
                        <SearchIcon />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-4 col-6 d-flex align-items-center">
                    <div className="searchDiv">
                      <select
                        className="form-select"
                        name="selectedCatogery"
                        aria-label="demo-simple-select-label"
                      >
                        <option selected> Search By</option>
                        <option value="Brand Name">Brand Name</option>
                        <option value="Generic Name">Generic Name</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-1 col-md-2 col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                    <div className="searchDrugBtn d-flex aling-items-center">
                      <button type="submit" className="Clickbtn">
                        Search
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        onClick={() => {
                          DrugListFetch(page, limit, "");
                          setSearchValue("");
                        }}
                        className="Cancelbtn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* ================== Drugs List ================ */}
          <div className="container">
              <TableComp
                loading={loading}
                columns={columns}
                data={suggestionList}
                total={total}
                pagePara={page}
                setPage={setPage}
                setLimit={setLimit}
                limitPara={limit}
                DrugListFetch={DrugListFetch}
              />
          </div>
        </div>
      </SideBar>
    </div>
  );
};

export default Drugs;
