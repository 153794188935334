import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaLock,
  FaUser,
  FaUserAlt,
  // FaSignOutAlt,
  FaHospitalAlt,
  FaPencilAlt,
  FaSignInAlt,
} from "react-icons/fa";
import { GiMedicinePills } from "react-icons/gi";
import { SiGooglemessages } from "react-icons/si";
import { BsFillCalendar2WeekFill, BsListTask } from "react-icons/bs";
import { RiLayout5Fill, RiLinksFill } from "react-icons/ri";
import { AiFillMessage, AiFillSetting } from "react-icons/ai";
// import { BsCartCheck } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { removeToken } from "../../services/localStorageServices";
import { MdEmail, MdMedicalServices } from "react-icons/md";
import { useSideBar } from "../../context/sideBar";
import { Tooltip } from "antd";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/appointment/slot",
    name: "Appointment",
    icon: <BsFillCalendar2WeekFill />,
  },

  {
    path: "/hospitals",
    name: "Hospitals",
    icon: <FaHospitalAlt />,
  },
  {
    path: "/patients",
    name: "Patients",
    icon: <FaUserAlt />,
  },
  {
    path: "/templates",
    name: "Prescription Templates",
    icon: <RiLayout5Fill />,
    exact: true,
    subRoutes: [
      {
        path: "/prescriptionTemplates",
        name: "Templates",
        icon: <RiLayout5Fill />,
      },
      {
        path: "/prescriptionAttributes",
        name: "Attributes",
        icon: <BsListTask />,
      },
    ],
  },
  // {
  //   path: "/prescriptionTemplates",
  //   name: "Templates",
  //   icon: <RiLayout5Fill />,
  // },
  {
    path: "/drugs",
    name: "Drugs",
    icon: <GiMedicinePills />,
  },
  {
    path: "/referrals",
    name: "Refer Lists",
    icon: <FaPencilAlt />,
    exact: true,
    subRoutes: [
      {
        path: "/referrals/references",
        name: "References",
        icon: <FaPencilAlt />,
      },
      {
        path: "/referrals/referrals",
        name: "Referrals",
        icon: <FaPencilAlt />,
      },
      {
        path: "/referrals/test",
        name: "Test Referrals",
        icon: <FaPencilAlt />,
      },
      {
        path: "/procedure",
        name: "Procedure Referrals",
        icon: <FaPencilAlt />,
      },
    ],
  },
  {
    path: "/notification",
    name: "Notification Setting",
    icon: <AiFillMessage />,
    exact: true,
    subRoutes: [
      {
        path: "/sms",
        name: "SMS",
        icon: <SiGooglemessages />,
      },
      {
        path: "/email",
        name: "Email",
        icon: <MdEmail />,
      },
    ],
  },
  {
    path: "/services",
    name: "Services",
    icon: <MdMedicalServices />,
  },
  {
    path: "/handouts",
    name: "Handouts",
    icon: <RiLinksFill />,
  },

  {
    path: "/settings",
    name: "Settings",
    icon: <AiFillSetting />,
    exact: true,
    subRoutes: [
      {
        path: "/settings/doctor",
        name: "Profile",
        icon: <FaUser />,
      },
      {
        path: "/ForgotPassword/",
        name: "Password Reset",
        icon: <FaLock />,
      },
    ],
  },
  {
    path: "/",
    name: "Sign Out",
    icon: <FaSignInAlt />,
  },
];

const SideBar = ({ children }) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const { page } = useParams();
  const [screenSizeValue, setScreenSizeValue] = useState(0);

  const checkScreenSize = () => {
    const screenWidth = window.innerWidth;
    const newValue = screenWidth < 600 ? "0px" : "45px";
    const loginType = localStorage.getItem("loginType");

    if (page === "reception") {
      setScreenSizeValue(0);
    } else {
      if (loginType !== null) {
        setScreenSizeValue(0);
      } else {
        setScreenSizeValue(newValue);
      }
    }
  };

  useEffect(() => {
    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Initial check when component mounts
    checkScreenSize();

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isOpen, setIsOpen, toggle } = useSideBar();

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const navigate = useNavigate();
  const logOutFunction = () => {
    removeToken();
    navigate("/");
  };

  const navbarRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // Click outside the navbar, so close it
        setIsOpen(false);

      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-container" ref={navbarRef}>
        <motion.div
          animate={{
            width: isOpen ? "240px" : screenSizeValue,

            transition: {
              duration: 0.5,
              // type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  Stetho Admin
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars style={{ color: "#fff" }} onClick={toggle} />
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <Tooltip key={index} title={route.name} placement="right">
                  <NavLink
                    onClick={
                      route.name === "Sign Out" ? logOutFunction : toggle
                    }
                    to={route.path}
                    key={index}
                    className="link"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                </Tooltip>
              );
            })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
