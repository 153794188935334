import React, { useEffect, useState } from "react";
import "../css/NewProfile.css";
import { Tab, Nav } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillFacebook,
  AiFillInfoCircle,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiFillWarning,
  AiFillYoutube,
  AiOutlineCheck,
} from "react-icons/ai";
import { BsInstagram, BsTrashFill } from "react-icons/bs";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { FileUploader } from "react-drag-drop-files";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FcGoogle } from "react-icons/fc";
import {
  useDrProfileUpdateMutation,
  useSocialMediaMutation,
} from "../services/userAuthAPI";
import moment from "moment/moment";
import { getToken } from "../services/localStorageServices";
import NoDataFound from "./NoDataFound";
import { Avatar, Tooltip } from "@mui/material";
import { Image, message } from "antd";
import { useImageResizer } from "../context/imageResizer.js";
import googlePlay from "../Images/googlePlay.png";

const NewProfile = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [DrProfileUpdate] = useDrProfileUpdateMutation();
  const [socialMedia] = useSocialMediaMutation();
  const [loading, setLoading] = useState(false);

  const [socialList, setSocialList] = useState([]);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const socialMediaIcons = [
    {
      name: "facebook",
      icon: <AiFillFacebook />,
      label: "Facebook",
    },
    {
      name: "instagram",
      icon: <BsInstagram />,
      label: "Instagram",
    },
    {
      name: "linkedin",
      icon: <AiFillLinkedin />,
      label: "Linkedin",
    },
    {
      name: "twitter",
      icon: <AiFillTwitterCircle />,
      label: "Twitter",
    },
    {
      name: "google",
      icon: <FcGoogle />,
      label: "Google My Buisness",
    },
    {
      name: "youtube",
      icon: <AiFillYoutube />,
      label: "You Tube",
    },
    {
      name: "googlePlay",
      icon: <img src={googlePlay} alt="Google Play" />,
      label: "Google Play Store",
    },
  ];

  //   Data From Json
  const fileTypes = ["JPG", "PNG", "GIF", "JFIF", "JPEG"];
  // const [signatureFile, setSignatureFile] = useState(null);
  // const [fileSignatureView, setFileSignatureView] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [value, setValue] = useState(null);
  const { access_token } = getToken();
  const [data, setData] = useState({});

  const getDataFromLocal = () => {
    const localData = JSON.parse(sessionStorage.getItem("doctorData"));
    setData(localData);
    setSocialList(localData.social_media);
  };

  // ======================== Signature Update ====================
  // const handleChange = (fileList) => {
  //   setSignatureFile(fileList);
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     const imageDataUrl = reader.result;
  //     setFileSignatureView(imageDataUrl);
  //   };

  //   if (fileList) {
  //     reader.readAsDataURL(fileList);
  //   }
  // };

  // ========= Get Dynamic Data ===================
  useEffect(() => {
    getDataFromLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { resizeFile } = useImageResizer();

  // ================== Upload Profile Image ===============
  const handleImageUpload = async (event) => {
    const maxWidth = 150;
    const maxHeight = 150;
    const targetFileSize = 80 * 1024;

    const compresedFile = await resizeFile(
      event,
      maxWidth,
      maxHeight,
      targetFileSize
    );
    setProfileFile(compresedFile);
    setSelectedImage(URL.createObjectURL(compresedFile));
  };

  // =================== Handle Date Change ==============

  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    setValue(selectedDate);
  };

  //   ====== edit Function ===============

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    const fromData = new FormData();
    fromData.append("First_Name", data.get("firstname"));
    fromData.append("Last_Name", data.get("lastname"));
    fromData.append("dateofbirth", moment(value).format("YYYY-MM-DD"));
    fromData.append("gender", data.get("gender"));
    fromData.append("specialtion", data.get("speciality"));
    fromData.append("degree", data.get("degree"));
    fromData.append("additionalQaulification", data.get("additional"));
    fromData.append("Description", data.get("description"));
    fromData.append("Address", data.get("address"));
    fromData.append("token", access_token);

    if (profileFile) {
      fromData.append("profile_image", profileFile);
    }
    // if (signatureFile) {
    //   fromData.append("signature_image", signatureFile);
    // }
    const req = await DrProfileUpdate(fromData);

    if (req.data) {
      const localData = JSON.parse(sessionStorage.getItem("doctorData"));
      localData.first_name = data.get("firstname");
      localData.last_name = data.get("lastname");
      localData.date_of_birth = moment(value).format("YYYY-MM-DD");
      localData.gender = data.get("gender");
      localData.specialtion = data.get("speciality");
      localData.degree = data.get("degree");
      localData.additional_qaulification = data.get("additional");
      localData.description = data.get("description");
      localData.address = data.get("address");
      if (profileFile) {
        localData.logo = selectedImage;
      }
      // if (signatureFile) {
      //   localData.singnature = fileSignatureView;
      // }

      const updatedData = JSON.stringify(localData);
      sessionStorage.setItem("doctorData", updatedData);

      setLoading(false);
      message.success("Update Successfully");
      getDataFromLocal();
    }

    if (req.error) {
      setLoading(false);
      message.error("Update Failed");
    }
  };

  // ============================== Add Social Media =========================
  const addInLocal = (newAdd) => {
    const listedSocial = socialList;
    listedSocial.push(newAdd);
    setSocialList([...listedSocial]);

    const localData = JSON.parse(sessionStorage.getItem("doctorData"));
    localData.social_media = listedSocial;
    sessionStorage.setItem("doctorData", JSON.stringify(localData));
  };

  const SocialMediaAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dataGet = new FormData(e.target);

    const AddData = {
      socialMedia: dataGet.get("socialMedia"),
      socialLink: dataGet.get("socialLink"),
      token: access_token,
      action: "AddSocial",
    };

    const req = await socialMedia(AddData);
    if (req) {
      const newData = {
        link: dataGet.get("socialLink"),
        profile_link_id: Math.floor(Math.random() * 100),
        social_media: dataGet.get("socialMedia"),
      };
      addInLocal(newData);
      setLoading(false);
      message.success("Added Successfully");
      // getDataFromLocal();
    }

    if (req.error) {
      setLoading(false);
      message.error("Add Failed");
    }
    e.target.reset();
  };

  // ============================ Remove Social List ======================
  const removeFromLocal = (i) => {
    const listedArray = [...socialList];
    listedArray.splice(i, 1);
    setSocialList(listedArray);

    const localData = JSON.parse(sessionStorage.getItem("doctorData"));
    localData.social_media = listedArray;
    sessionStorage.setItem("doctorData", JSON.stringify(localData));
  };

  const removeSocial = async (name, index) => {
    if (window.confirm("Are sure you want to remove " + name + " ?")) {
      setLoading(true);
      const removeData = {
        socialName: name,
        token: access_token,
        action: "RemoveSocial",
      };
      const req = await socialMedia(removeData);

      if (req.data) {
        removeFromLocal(index);
        setLoading(false);
        message.success(name + " Removed Successfully");
      }

      if (req.error) {
        setLoading(false);
        message.error(name + " Remove Failed");
      }
    }
  };

  // ============================= Update Social app link =========================

  const updateSocialList = (index, newValue) => {
    const updatedItems = [...socialList];

    // Update the value of the specific item
    updatedItems[index] = {
      ...updatedItems[index],
      link: newValue,
    };

    // Update the state with the modified array
    setSocialList(updatedItems);

    const localData = JSON.parse(sessionStorage.getItem("doctorData"));
    localData.social_media = updatedItems;
    sessionStorage.setItem("doctorData", JSON.stringify(localData));
  };

  const updateLink = async (name, index) => {
    if (window.confirm("Are sure you want to update link of " + name + " ?")) {
      setLoading(true);
      const linkValue = document.getElementById("socialLink" + name);
      const updateData = {
        socialMedia: name,
        socialLink: linkValue.value,
        token: access_token,
        action: "updateSocial",
      };

      const req = await socialMedia(updateData);

      if (req.data) {
        updateSocialList(index, linkValue.value);
        setLoading(false);
        message.success(name + " Link updated Successfully");
      }

      if (req.error) {
        setLoading(false);
        message.error(name + " Link Update Failed");
      }
    }
  };

  // ================================== Update Handle App ==================

  const handleAppUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const getData = new FormData(e.target);
    const formData = new FormData();
    formData.append("appLink", getData.get("appLink"));
    formData.append("appDesc", getData.get("appDesc"));
    formData.append("token", access_token);
    formData.append("action", "updateApp");

    const req = await DrProfileUpdate(formData);

    if (req.data) {
      // updateSocialList(index, linkValue.value);
      setLoading(false);
      message.success("App Detail updated Successfully");
    }

    if (req.error) {
      setLoading(false);
      message.error("App Deatil Update Failed");
    }
  };

  return (
    <>
      <SideBar>
        <NavbarComp data={data} />
        <section className="section_profile">
          <div className="container">
            <div className="pagetitle">
              <h1>Profile</h1>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <div className="Edit_profileCard">
                  <div className="Edit_profilecard-body">
                    <div className="Edit_profile-img">
                      <img
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                        src={data.logo}
                        alt={"Dr. " + data.first_name + " " + data.last_name}
                        className="rounded-circle"
                      />
                    </div>
                    <div className="Edit_profile-Txt">
                      <h2>{"Dr. " + data.first_name + " " + data.last_name}</h2>
                      <h3>{data.degree}</h3>
                      <h3>
                        {data.specialtion + " " + data.additional_qaulification}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="newProfile_card">
                  <div className="newProfile_card-body pt-3">
                    <Tab.Container activeKey={activeTab}>
                      <Nav variant="tabs" className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="overview"
                            onClick={() => handleTabChange("overview")}
                          >
                            Overview
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="edit"
                            onClick={() => handleTabChange("edit")}
                          >
                            Edit Profile
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="media"
                            onClick={() => handleTabChange("media")}
                          >
                            Social Media
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            eventKey="EditApp"
                            onClick={() => handleTabChange("EditApp")}
                          >
                            Edit App
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="overview">
                          <div className="edit_Overview">
                            <h5>About Doctor</h5>
                            <p className="small fst-italic">
                              {data.description}
                            </p>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label ">
                                <div className="overForm_list">
                                  <h5>Full Name:</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>
                                    {"Dr. " +
                                      data.first_name +
                                      " " +
                                      data.last_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Gender:</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>{data.gender}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Date of Birth-</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>
                                    {moment(data.date_of_birth).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Specialization</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>{data.specialtion}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Phone</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>{"+91-" + data.mobile}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Email</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>{data.email}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Address</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <p>{data.address}</p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="col-lg-3 col-sm-4 col-12 label">
                                <div className="overForm_list">
                                  <h5>Signature</h5>
                                </div>
                              </div>
                              <div className="col-lg-9 col-sm-8 col-12">
                                <div className="overForm_detail">
                                  <img
                                    src={data.singnature}
                                    alt="doctor signature"
                                    style={{
                                      maxWidth: "150px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit">
                          <form onSubmit={handleSubmit}>
                            <div className="Edit_profileSec">
                              <div className="row mb-3">
                                <div className="col-md-4 col-sm-3 col-12">
                                  <div className="profle_headings">
                                    <h5>Profile Image</h5>
                                  </div>
                                </div>
                                <div className="col-md-8 col-lg-9 col-12 mt-1">
                                  <div className="profile-img">
                                    <Image
                                      style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: "cover",
                                      }}
                                      src={
                                        selectedImage
                                          ? selectedImage
                                          : data.logo
                                      }
                                      alt="DR"
                                    />
                                  </div>
                                  <div className="personalInfoCol mt-2">
                                    <FileUploader
                                      handleChange={handleImageUpload}
                                      name="myFile"
                                      types={fileTypes}
                                    />
                                    <span
                                      style={{
                                        fontSize: ".9rem",
                                        fontWeight: "500",
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {profileFile ? (
                                        profileFile.size > 100000 ? (
                                          <>
                                            <AiFillWarning
                                              style={{
                                                margin: "0 10px",
                                                color: "red",
                                              }}
                                            />{" "}
                                            File size exceeds the limit of 100KB
                                          </>
                                        ) : (
                                          <>
                                            <AiFillCheckCircle
                                              style={{
                                                margin: "0 10px",
                                                color: "green",
                                              }}
                                            />{" "}
                                            {profileFile.name}
                                          </>
                                        )
                                      ) : (
                                        <>
                                          <AiFillInfoCircle
                                            style={{
                                              margin: "0 10px",
                                              color: "orange",
                                            }}
                                          />{" "}
                                          No files uploaded yet{" "}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>First Name</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="firstname"
                                      type="text"
                                      defaultValue={data.first_name}
                                      className="form-control"
                                      id="company"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Last Name</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="lastname"
                                      type="text"
                                      defaultValue={data.last_name}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>About Doctor</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="description"
                                      type="text"
                                      defaultValue={data.description}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Gender</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <select
                                      name="gender"
                                      id=""
                                      className="form-select"
                                    >
                                      <option selected disabled>
                                        Select Gender
                                      </option>
                                      <option
                                        value="male"
                                        selected={data.gender === "male"}
                                      >
                                        Male
                                      </option>
                                      <option
                                        value="female"
                                        selected={data.gender === "female"}
                                      >
                                        Female
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Date Of Birth</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <DesktopDatePicker
                                          label=""
                                          inputFormat="DD/MM/YYYY"
                                          value={
                                            value ? value : data.date_of_birth
                                          }
                                          onChange={handleDateChange}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Degree</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="degree"
                                      type="text"
                                      defaultValue={data.degree}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Specialization</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="speciality"
                                      type="text"
                                      className="form-control"
                                      defaultValue={data.specialtion}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Additional Qualification</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="additional"
                                      type="text"
                                      className="form-control"
                                      defaultValue={
                                        data.additional_qaulification
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>Address</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="address"
                                      type="text"
                                      defaultValue={data.address}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row mb-3">
                                <div className="col-md-4 col-lg-3">
                                  <div className="profle_headings">
                                    <h5>Signature</h5>
                                  </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                  <div className="profle_Detail">
                                    <img
                                      src={
                                        fileSignatureView
                                          ? fileSignatureView
                                          : data.singnature
                                      }
                                      alt="doctor signature"
                                      style={{
                                        maxWidth: "150px",
                                        marginTop: "20px",
                                      }}
                                    />
                                    <div className="personalInfoCol">
                                      <FileUploader
                                        handleChange={handleChange}
                                        name="myFile"
                                        types={fileTypes}
                                      />
                                      <span
                                        style={{
                                          fontSize: ".9rem",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {signatureFile ? (
                                          signatureFile.size > 100000 ? (
                                            <>
                                              <AiFillWarning
                                                style={{
                                                  margin: "0 10px",
                                                  color: "red",
                                                }}
                                              />{" "}
                                              File size exceeds the limit of
                                              100KB
                                            </>
                                          ) : (
                                            <>
                                              <AiFillCheckCircle
                                                style={{
                                                  margin: "0 10px",
                                                  color: "green",
                                                }}
                                              />{" "}
                                              {signatureFile.name}
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <AiFillInfoCircle
                                              style={{
                                                margin: "0 10px",
                                                color: "orange",
                                              }}
                                            />{" "}
                                            No files uploaded yet{" "}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="editProfle">
                                <button
                                  type="submit"
                                  className="Clickbtn"
                                  style={{ minWidth: "150px" }}
                                >
                                  {loading ? (
                                    <div
                                      class="spinner-border text-light"
                                      role="status"
                                    ></div>
                                  ) : (
                                    "Update Changes"
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="media">
                          <div className="Edit_profileSec">
                            <h5 className="mb-3">Listed Social Media</h5>
                            {socialList && socialList.length > 0 ? (
                              socialList.map((valueList, i) => (
                                <div className="row mb-3" key={i}>
                                  {socialMediaIcons
                                    .filter(
                                      (valueFilter) =>
                                        valueFilter.name ===
                                        valueList.social_media
                                    )
                                    .map((valueMap, index) => (
                                      <div
                                        className="col-md-1 col-sm-9 col-8 order-1"
                                        key={index}
                                      >
                                        <div
                                          className={`socialIcon ${valueMap.name}`}
                                        >
                                          <h5> {valueMap.icon}</h5>
                                        </div>
                                      </div>
                                    ))}
                                  <div className="col-md-9 col-12 order-md-2 order-3">
                                    <div className="socialInputLink">
                                      <input
                                        type="text"
                                        name="link"
                                        id={
                                          `socialLink` + valueList.social_media
                                        }
                                        placeholder="Link"
                                        defaultValue={valueList.link}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-3 col-4 order-md-3 order-2 d-flex justify-content-between align-items-start">
                                    <Tooltip title="Update Link">
                                      <button
                                        className="iconsStyle sucess"
                                        onClick={() =>
                                          updateLink(valueList.social_media, i)
                                        }
                                      >
                                        {loading ? (
                                          <div
                                            class="spinner-border text-sucess"
                                            role="status"
                                          ></div>
                                        ) : (
                                          <AiOutlineCheck />
                                        )}
                                      </button>
                                    </Tooltip>
                                    <Tooltip title="Remove Link">
                                      <button
                                        className="iconsStyle delete"
                                        onClick={() =>
                                          removeSocial(
                                            valueList.social_media,
                                            i
                                          )
                                        }
                                      >
                                        {loading ? (
                                          <div
                                            class="spinner-border text-danger"
                                            role="status"
                                          ></div>
                                        ) : (
                                          <BsTrashFill />
                                        )}
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <NoDataFound />
                            )}

                            <div className="addNewSocial">
                              <h5 className="mb-3">Upload Social Media</h5>
                              <form onSubmit={SocialMediaAdd}>
                                <div className="row">
                                  <div className="col-md-4 col-12 mb-3">
                                    <select
                                      name="socialMedia"
                                      id=""
                                      className="form-select"
                                    >
                                      <option selected disabled>
                                        {" "}
                                        Select Social Media
                                      </option>
                                      {socialMediaIcons.map((valueMap) =>
                                        socialList.filter(
                                          (valueFilter) =>
                                            valueFilter.social_media ===
                                            valueMap.name
                                        ).length > 0 ? null : (
                                          <option
                                            key={valueMap.name}
                                            value={valueMap.name}
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {valueMap.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="col-md-8 col-12">
                                    <input
                                      type="text"
                                      name="socialLink"
                                      placeholder="Enter Link"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-12 mt-4 editProfle">
                                    <button
                                      type="submit"
                                      className="Clickbtn"
                                      style={{ minWidth: "150px" }}
                                    >
                                      {loading ? (
                                        <div
                                          class="spinner-border text-light"
                                          role="status"
                                        ></div>
                                      ) : (
                                        "Upload New"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="EditApp">
                          <form onSubmit={handleAppUpdate}>
                            <div className="Edit_profileSec">
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>App Link</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <input
                                      name="appLink"
                                      type="text"
                                      className="form-control"
                                      defaultValue={data.app_link}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-4 col-12">
                                  <div className="profle_headings">
                                    <h5>App Message</h5>
                                  </div>
                                </div>
                                <div className="col-sm-8 col-12">
                                  <div className="profle_Detail">
                                    <textarea
                                      name="appDesc"
                                      defaultValue={data.app_message}
                                      className="form-control"
                                      rows="7"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="editProfle">
                                <button
                                  type="submit"
                                  className="Clickbtn"
                                  style={{ minWidth: "150px" }}
                                >
                                  {loading ? (
                                    <div
                                      class="spinner-border text-light"
                                      role="status"
                                    ></div>
                                  ) : (
                                    "Update Changes"
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SideBar>
    </>
  );
};

export default NewProfile;
