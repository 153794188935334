import React, { useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import medicineShort from "../../json/medicineShort.json";
import { MdDelete } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { SlOptionsVertical } from "react-icons/sl";
import { FaEdit } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";
import { MdDragIndicator } from "react-icons/md";

const type = "Drug"; // Need to pass which type element can be draggable, its a simple string or Symbol. This is like an Unique ID so that the library know what type of element is dragged or dropped on.

export default function DrugListCard({
  obj,
  drugDesign,
  i,
  drugUpdate,
  removeMedicine,
  changeDugInput,
  ViewEditDrugData,
  moveImage,
}) {
  const refDrug = useRef(null); // Initialize the reference

  const [, drop] = useDrop({
    // Accept will make sure only these element type can be droppable on this element
    accept: type,
    hover(item) {
      if (!refDrug.current) {
        return;
      }
      const dragIndex = item.index;
      // current element where the dragged element is hovered on
      const hoverIndex = i;
      // If the dragged element is hovered in the same place, then do nothing
      if (dragIndex === hoverIndex) {
        return;
      }
      // If it is dragged around other elements, then move the image and set the state with position changes
      moveImage(dragIndex, hoverIndex);
      /*
            Update the index for dragged item directly to avoid flickering
            when the image was half dragged into the next
          */
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    // what type of item this to determine if a drop target accepts it
    type: type,
    // data of the item to be available to the drop methods
    item: { id: obj.drug_id, index: i },
    // method to collect additional data for drop handling like whether is currently being dragged
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  drag(drop(refDrug));
  const cursorStyle = isDragging
    ? {
        cursor: "grabbing",
        opacity: 0.2,
        transition: "transform 0.3s ease-in-out",
      }
    : {
        cursor: "grab",
        opacity: 1,
        transition: "transform 0.3s ease-in-out",
      };

  return (
    <div className="d-flex justify-content-center align-items-baseline">
      <MdDragIndicator
        style={{
          color: "rgb(128, 128, 128)",
          fontSize: "1.2rem",
          position: "relative",
          bottom: "-4px",
          left:"-4px"
        }}
      />
      <div
        ref={refDrug}
        style={{ ...cursorStyle }}
        key={i}
        class="row borderDrug pt-2 pb-2 mainRoot mb-3"
      >
        <div className="col-md-4 col-10 mb-3 mb-md-0 d-flex align-items-center justify-content-start">
          <div className="input-style-1">
            <h6>
              {medicineShort.map((value) =>
                value.name === obj.drug_catogery ? value.name : null
              )}
              {" " + obj.brand_name}
              {obj.drug_strength
                ? obj.drug_strength.split(" ")[0] !== ""
                  ? " (" + obj.drug_strength + ") "
                  : null
                : null}

              {obj.strength_medicine
                ? obj.strength_medicine !== " mg"
                  ? " (" + obj.strength_medicine + ") "
                  : null
                : null}
            </h6>
            <p
              style={{
                fontSize: ".85rem",
                color: "gray",
                textDecoration: "underline",
              }}
            >
              {obj.drug_instruction}
            </p>
          </div>
        </div>
        {/* =============== For Responsive ============== */}
        <div className="col-sm-1 col-2 mb-3 mb-md-0 d-block d-md-none">
          <div className="inputBoxHeadIcons" style={{ position: "relative" }}>
            <div
              className="headIcon"
              onClick={() => removeMedicine(i)}
              style={{ cursor: "pointer" }}
            >
              <div className="iconstyle delete"></div>
              <MdDelete />
            </div>

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <SlOptionsVertical />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {drugDesign.map((value, index) =>
                  index === i && value.type === "checkView" ? (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      onClick={() => changeDugInput(i, "inputView")}
                    >
                      <FaEdit />
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                )}

                {drugDesign.map((value, index) =>
                  index === i && value.type === "inputView" ? (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      onClick={() => changeDugInput(i, "checkView")}
                    >
                      <FaEdit />
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                )}

                <Dropdown.Item
                  onClick={() => ViewEditDrugData(obj.drug_id)}
                  href="#"
                >
                  <CgNotes />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-md-4 col-sm-8 col-12 mb-3 mb-md-0 d-flex align-items-center justify-content-center">
          {/* ===================== First View ======================= */}

          {drugDesign.map((value, index) =>
            index === i && value.type === "checkView" ? (
              <div key={index} className="row" id="firstDesign">
                <div className="col-sm-4 col-3 d-flex align-items-center justify-content-center flex-column">
                  <div className="input-style-1">
                    <input
                      type="number"
                      placeholder=""
                      className="form-control"
                      disabled={obj.drug_catogery === "Ointment" ? true : false}
                      onChange={drugUpdate}
                      defaultValue={
                        obj.drug_catogery === "Ointment"
                          ? ""
                          : obj.drug_dosg !== 0
                          ? obj.drug_dosg
                          : 1
                      }
                      data-name="dose"
                      data-id={obj.drug_id}
                      style={
                        obj.drug_catogery === "Ointment"
                          ? {
                              background: "#eee",
                            }
                          : null
                      }
                    />
                  </div>
                  <span>
                    {medicineShort.map((value) =>
                      value.name === obj.drug_catogery ? value.shrt : null
                    )}{" "}
                  </span>
                </div>
                <div className="col-sm-8 col-9 d-flex align-items-start justify-content-center marginbot">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-2 d-flex flex-column align-items-center">
                      <div className="input-style-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="morning"
                          value="morning"
                          onChange={drugUpdate}
                          data-name="frequencyMorning"
                          data-id={obj.drug_id}
                          defaultChecked={
                            obj.drug_freq_morning === "null" ? false : true
                          }
                        />
                      </div>
                      <span className="d-block d-md-none">M</span>
                    </div>
                    <div className="col-2 d-flex flex-column align-items-center">
                      <div className="input-style-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="after"
                          value="after"
                          onChange={drugUpdate}
                          data-name="frequencyAfterNoon"
                          data-id={obj.drug_id}
                          defaultChecked={
                            obj.drug_freq_afternoon === "null" ? false : true
                          }
                        />
                      </div>
                      <span className="d-block d-md-none">A</span>
                    </div>
                    <div className="col-2 d-flex flex-column align-items-center">
                      <div className="input-style-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="evening"
                          value="evening"
                          onChange={drugUpdate}
                          data-name="frequencyEvening"
                          data-id={obj.drug_id}
                          defaultChecked={
                            obj.drug_freq_evening === "null" ? false : true
                          }
                        />
                      </div>
                      <span className="d-block d-md-none">E</span>
                    </div>
                    <div className="col-2 d-flex flex-column align-items-center">
                      <div className="input-style-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="night"
                          value="night"
                          onChange={drugUpdate}
                          data-name="frequencyNight"
                          data-id={obj.drug_id}
                          defaultChecked={
                            obj.drug_freq_night === "null" ? false : true
                          }
                        />
                      </div>
                      <span className="d-block d-md-none">N</span>
                    </div>
                    <div className="col-2 d-flex flex-column align-items-center ">
                      <div className="input-style-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="sos"
                          value="sos"
                          onChange={drugUpdate}
                          data-name="frequencySos"
                          data-id={obj.drug_id}
                          defaultChecked={
                            obj.drug_freq_sos === "null" ? false : true
                          }
                        />
                      </div>
                      <span className="d-block d-md-none">SOS</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}

          {/* ============================= Second View ======================= */}
          {drugDesign.map((value, index) =>
            index === i && value.type === "inputView" ? (
              <div
                key={index}
                className="row px-2 px-2 marginbot"
                id="secondView"
              >
                <div className="col-2 m-0 p-0">
                  <div className="input-style-1">
                    <input
                      type="number"
                      name="morning"
                      className="form-control"
                      placeholder="M"
                      onChange={drugUpdate}
                      data-name="frequencyMorning"
                      data-type="inputView"
                      data-id={obj.drug_id}
                    />
                  </div>
                  <div className="inputMedType d-flex justify-content-center">
                    <span>
                      {medicineShort.map((value) =>
                        value.name === obj.drug_catogery ? value.shrt : " "
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-2 m-0 p-0">
                  <div className="input-style-1">
                    <input
                      type="number"
                      name="after"
                      className="form-control"
                      placeholder="A"
                      onChange={drugUpdate}
                      data-name="frequencyAfterNoon"
                      data-type="inputView"
                      data-id={obj.drug_id}
                    />
                  </div>
                  <div className="inputMedType d-flex justify-content-center">
                    <span>
                      {medicineShort.map((value) =>
                        value.name === obj.drug_catogery ? value.shrt : " "
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-2 m-0 p-0">
                  <div className="input-style-1">
                    <input
                      type="number"
                      name="evening"
                      className="form-control"
                      placeholder="E"
                      onChange={drugUpdate}
                      data-name="frequencyEvening"
                      data-type="inputView"
                      data-id={obj.drug_id}
                    />
                  </div>
                  <div className="inputMedType d-flex justify-content-center">
                    <span>
                      {medicineShort.map((value) =>
                        value.name === obj.drug_catogery ? value.shrt : " "
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-2 m-0 p-0">
                  <div className="input-style-1">
                    <input
                      type="number"
                      name="night"
                      className="form-control"
                      placeholder="N"
                      onChange={drugUpdate}
                      data-name="frequencyNight"
                      data-type="inputView"
                      data-id={obj.drug_id}
                    />
                  </div>
                  <div className="inputMedType d-flex justify-content-center">
                    <span>
                      {medicineShort.map((value) =>
                        value.name === obj.drug_catogery ? value.shrt : " "
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-2 m-0 p-0">
                  <div className="input-style-1">
                    <input
                      type="number"
                      name="stat"
                      className="form-control"
                      placeholder="stat"
                      onChange={drugUpdate}
                      data-name="frequencyStat"
                      data-type="inputView"
                      data-id={obj.drug_id}
                    />
                  </div>
                  <div className="inputMedType d-flex justify-content-center">
                    <span>
                      {medicineShort.map((value) =>
                        value.name === obj.drug_catogery ? value.shrt : " "
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-2 m-0 p-0"></div>
              </div>
            ) : (
              ""
            )
          )}
        </div>

        <div className="col-sm-3 col-6 d-flex align-items-center justify-content-center d-flex align-items-center d-flex align-items-center justify-content-center">
          <div className="input-style-1 prescreptionDuration marginbot paddbot">
            <input
              type="number"
              name="duration"
              data-name="drugDays"
              className="form-control"
              data-id={obj.drug_id}
              onChange={drugUpdate}
              defaultValue={obj.drug_duration_days}
            />
            <select
              name="duration"
              id="duration"
              className="form-select"
              data-name="drugDuration"
              data-id={obj.drug_id}
              onChange={drugUpdate}
            >
              {obj.drug_duration_dayType === "days" ? (
                <option selected value="Days">
                  Days
                </option>
              ) : (
                <option value="Days">Days</option>
              )}

              {obj.drug_duration_dayType === "weeks" ? (
                <option selected value="Weeks">
                  Weeks
                </option>
              ) : (
                <option value="Weeks">Weeks</option>
              )}
              {obj.drug_duration_dayType === "months" ? (
                <option selected value="Months">
                  Months
                </option>
              ) : (
                <option value="Months">Months</option>
              )}
            </select>
          </div>
        </div>
        <div className="col-1 d-none d-md-block">
          <div className="inputBoxHeadIcons" style={{ position: "relative" }}>
            <div
              className="headIcon"
              onClick={() => removeMedicine(i)}
              style={{ cursor: "pointer" }}
            >
              <div className="iconstyle delete"></div>
              <MdDelete />
            </div>

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <SlOptionsVertical />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {drugDesign.map((value, index) =>
                  index === i && value.type === "checkView" ? (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      onClick={() => changeDugInput(i, "inputView")}
                    >
                      <FaEdit />
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                )}

                {drugDesign.map((value, index) =>
                  index === i && value.type === "inputView" ? (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      onClick={() => changeDugInput(i, "checkView")}
                    >
                      <FaEdit />
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                )}

                <Dropdown.Item
                  onClick={() => ViewEditDrugData(obj.drug_id)}
                  href="#"
                >
                  <CgNotes />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
