import React, {useEffect} from "react";
import Header from "../components/Sidebar/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getToken } from "../services/localStorageServices";
import { useDispatch } from "react-redux";
import { setUserToken } from "../features/authSlice";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { PatientChart } from "../components/charts/patientchart";
import { AppointmentChart } from "../components/charts/appointmentChart";
import { MedicineChart } from "../components/charts/medicineChart";
import { DownloadApp } from "../components/charts/downloadApp";

const Dashboard = () => {
  const {access_token} = getToken();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);


  return (
    <div>
      <SideBar>
        <NavbarComp />
        <div className="dashboardList navbarBodyMargin px-2">
          <Row>
            <Col md={12}>
              <Header />
            </Col>
            <Col className="mb-5" md={6}>
              <PatientChart />
            </Col>
            <Col className="mb-5" md={6}>
              <AppointmentChart />
            </Col>
            <Col className="mb-5" md={6}>
              <MedicineChart />
            </Col>
            <Col className="mb-5"  md={6}>
              <DownloadApp />
            </Col>
          </Row>
        </div>
      </SideBar>
    </div>
  );
};

export default Dashboard;
