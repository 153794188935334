import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./AddLocationBox.css";
import { TextField } from "@mui/material";
import { useAddDoctorDataListMutation } from "../../services/userAuthAPI";
import { getToken } from "../../services/localStorageServices";
const LocationAdd = ({
  show,
  close,
  locationType,
  referalDataList,
  setReferalDataList,
  setOpenBackdrop,
  setErrorMessage
}) => {
  const [addDoctorDataList] = useAddDoctorDataListMutation();
  const { access_token } = getToken();

  const loacationAdd = async (e) => {
    e.preventDefault();
    close(false);
    setOpenBackdrop(true);
    const data = new FormData(e.currentTarget);

    const datapush = referalDataList;

    const dataList = {
      account_text_name: data.get("name"),
      account_type_name: data.get("email"),
      account_mobile: data.get("contact"),
      account_address: data.get("address"),
      account_data_type: locationType,
      token: access_token,
    };

    const req = await addDoctorDataList(dataList);
    if (req.data) {
      datapush.push(dataList);
      setReferalDataList([...datapush]);
      setOpenBackdrop(false);
      setErrorMessage("Added Successfully")
    }
  };

  return (
    <div>
      <div className="LocationAdd">
        <div>
          <Dialog
            open={show}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px", // Set your width here
                },
              },
            }}
          >
            <DialogContent>
              <div className="addLocationHead">
                <h6>Add Location for Test</h6>
              </div>
              <div className="addLocationBoxContent">
                <div className="addNewLocation">
                  <form onSubmit={loacationAdd}>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <TextField
                          id="filled-basic"
                          label="Name"
                          name="name"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <TextField
                          id="filled-basic"
                          label="Email Id"
                          name="email"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <TextField
                          id="filled-basic"
                          label="Contact No."
                          name="contact"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          id="filled-basic"
                          label="Address"
                          name="address"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <div className="buttonGroup">
                      <Button onClick={close}>Back</Button>
                      <Button type="submit">Add</Button>
                    </div>
                  </form>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default LocationAdd;
