import React from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
// Components
import RecordHistory from "../components/HistoryComponents/RecordHistory";
//CSS
import "../css/ViewHistory.css";

const ViewHistory = () => {
  return (
    <div>
      <SideBar>
        <NavbarComp />
        <div className="viewHistorySection navbarBodyMargin">
        <RecordHistory />
        </div>
      </SideBar>
    </div>
  );
};

export default ViewHistory;
