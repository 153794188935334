import React from "react";
import FirstDesign from "./firstDesign";
import SecondDesign from "./secondDesign";
import moment from "moment";

export default function OfflineAppointment({
  slotList,
  activeTab,
  onAppointmentFormOpening,
  offlineAppointmentList,
  bookedList,
  formattedDate,
  hospitalId
}) {

  const appointmentTime = (timeData) => {
    const hsopitalList = JSON.parse(sessionStorage.getItem("hospaitl_list"));
    const shedule = hsopitalList.find((value) => value.list_of_account === hospitalId).hospital_shedule
    var weekDayName =  moment(formattedDate).format('dddd');

    const slotTime = shedule.find((value) => value.hospital_weeek_name === weekDayName).hospital_slot_duration;

    const momentTime = moment(timeData, 'hh:mm A');

    // Add slot time to the input time
    const updatedTime = momentTime.add(parseInt(slotTime), 'minutes');

  return updatedTime.format('hh:mm A');
  }


  return (
    <div className="boxsection1">
      <section className="Shedular-data-con-Main">
        <div className="Shedular-data-con">
          <h5>Offline Appointment Lists</h5>
          <div className="Shedular-data-con01">
              {activeTab === 0 ? ( // Checking Active Design
                <FirstDesign onAppointmentFormOpening={onAppointmentFormOpening} offlineAppointmentList={offlineAppointmentList} slotList={slotList} hospitalId ={hospitalId} bookedList={bookedList} formattedDate={formattedDate} appointmentTime={appointmentTime} />
              ) : (
                <SecondDesign onAppointmentFormOpening={onAppointmentFormOpening} offlineAppointmentList={offlineAppointmentList} slotList={slotList} hospitalId ={hospitalId} bookedList={bookedList} formattedDate={formattedDate} appointmentTime={appointmentTime} />
              )
            }
          </div>
        </div>
      </section>
    </div>
  );
}
