import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../services/userAuthAPI";
import { getToken, storeToken } from "../services/localStorageServices";
import { useDispatch } from "react-redux";
import { setUserToken } from "../features/authSlice";
import "../css/Login.css";
import { IoPersonSharp } from "react-icons/io5";
import { IoLockClosed } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { access_token } = getToken();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const data = new FormData(e.currentTarget);

    // ================================= Validation ===============================

    if (data.get("phone") === "") {
      setOpenBackdrop(false);
      setErrorMessage("All field is required.");
      setOpen(true);
    } else if (data.get("password") === "") {
      setOpenBackdrop(false);
      setErrorMessage("All field is required.");
      setOpen(true);
    }
    const actualData = {
      phone: data.get("phone"),
      password: data.get("password"),
    };

    if (data.get("phone") !== "" && data.get("password") !== "") {
      const res = await loginUser(actualData);
      if (res.error) {
        setOpenBackdrop(false);
        setOpen(true);
        setErrorMessage("Please Enter valid Password");
      }

      if (res.data) {
        setOpenBackdrop(false);
        if (res.data.loginType) {
          storeToken(res.data.token);
          dispatch(setUserToken({ token: res.data.token }));
          localStorage.setItem("loginType", res.data.loginType)
          navigate("/appointment/reception");
        } else {
          sessionStorage.setItem("token", res.data.token);
          navigate("/enterOtp");
        }
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <section className="Login">
        <div className="container">
          <div className="screen">
            <div className="screen__content">
              <form className="login" onSubmit={handleSubmit}>
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <IoPersonSharp />
                  </i>
                  <input
                    type="text"
                    className="login__input"
                    name="phone"
                    placeholder="Username / Phone"
                  />
                </div>
                <div className="login__field">
                  <i className="login__icon fas fa-lock">
                    <IoLockClosed />
                  </i>
                  <input
                    type="password"
                    name="password"
                    className="login__input"
                    placeholder="Password"
                  />
                </div>
                <button className="button login__submit" type="submit">
                  <span className="button__text">Log In Now</span>
                  <i className="button__icon fas fa-chevron-right">
                    <MdOutlineArrowForwardIos />
                  </i>
                </button>
              </form>
              <div className="social-login">
                <h3>
                  <Link to="/ForgotPassword">Forgot Password</Link>
                </h3>
              </div>
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
