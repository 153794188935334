import { Pagination, Table } from "antd";
import React from "react";

export default function TableComp({
  columns,
  data,
  loading,
  pagePara,
  total,
  setPage,
  limitPara,
  setLimit,
  DrugListFetch,
}) {
  const handlePage = (page, pageSize) => {
    DrugListFetch(page, pageSize, "");
    setPage(page);
    setLimit(pageSize);
  };
  return (
    <div>
      <div className="card">
        <Table
          columns={columns}
          pagination={false}
          loading={loading}
          dataSource={data}
          scroll={{ x: 800 }}
        />
      </div>
      <div className="card">
        <Pagination
          total={total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={limitPara}
          current={pagePara}
          onChange={handlePage}
        />
      </div>
    </div>
  );
}
