import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoLockClosed } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoginUserMutation } from "../services/userAuthAPI";

const Password = () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: "error",
    message: "",
  });
  const [loginUser] = useLoginUserMutation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");
    const data = new FormData(e.currentTarget);

    if (data.get("password1") === "" && data.get("password2") === "") {
      setErrorMessage({
        status: "error",
        message: "All Fields are required",
      });
      setOpen(true);
    } else if (data.get("password1") !== data.get("password2")) {
      setErrorMessage({
        status: "error",
        message: "Both Password should be same",
      });
      setOpen(true);
    }

    if (
      data.get("password1") !== "" &&
      data.get("password2") !== "" &&
      data.get("password1") === data.get("password2")
    ) {
      setOpenBackdrop(true);
      const res = await loginUser({
        token: token,
        password: data.get("password2"),
      });

      if (res.data) {
        setOpen(true);
        setOpenBackdrop(false);
        setErrorMessage({
          status: "success",
          message: "Password Reset Succesfully",
        });
        sessionStorage.removeItem("token");
        navigate("/")
        // fetch(
        //   "https://2factor.in/API/V1/52022fd1-7569-11ed-9158-0200cd936042/SMS/" +
        //     res.data.phone +
        //     "/" +
        //     res.data.otp +
        //     "/"
        // )
        //   .then((res) => res.json())
        //   .then((result) => {
        //     console.log(result);
        //   });
      }

      if (res.error) {
        setOpen(true);
        setOpenBackdrop(false);
        setErrorMessage({
          status: "error",
          message: "Password Not updated for reason of some tecnical problem",
        });
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert
          onClose={handleClose}
          severity={errorMessage.status}
          sx={{ width: "100%" }}
        >
          {errorMessage.message}
        </Alert>
      </Snackbar>
      <section className="Login">
        <div className="container">
          <div className="screen">
            <div className="screen__content">
              <form className="login" onSubmit={resetPassword}>
                <div className="login__field">
                  <i className="login__icon fas fa-lock">
                    <IoLockClosed />
                  </i>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="login__input"
                    placeholder=" New Password"
                    name="password1"
                  />
                  {showPassword ? (
                    <span
                      className="login__icon"
                      onClick={() => setShowPassword(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      className="login__icon"
                      onClick={() => setShowPassword(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <AiFillEye />
                    </span>
                  )}
                </div>
                <div className="login__field">
                  <i className="login__icon fas fa-lock">
                    <IoLockClosed />
                  </i>
                  <input
                    type="password"
                    name="password2"
                    className="login__input"
                    placeholder="Confirm Password"
                  />
                </div>
                <button className="button login__submit" type="submit">
                  <span className="button__text">Submit</span>
                  <i className="button__icon fas fa-chevron-right">
                    <MdOutlineArrowForwardIos />
                  </i>
                </button>
              </form>
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Password;
