const storeIntoDraft = (
  apptDate,
  apptHospital,
  patientID,
  diagnosis,
  priscription,
  instruction,
  otherData,
  handouts
) => {
  if ((apptDate, apptHospital, patientID)) {
    const isDraftCreated = JSON.parse(sessionStorage.getItem("draft_data"));

    if (isDraftCreated && isDraftCreated.length > 0) {
      const isUserHave = isDraftCreated.some(
        (value) =>
          value.PID === patientID &&
          value.date === apptDate &&
          value.hospital === apptHospital
      );

      if (isUserHave) {
        // is draft already creadted checking
        const index = isDraftCreated.findIndex(
          // finding index of already created draft
          (value) =>
            value.PID === patientID &&
            value.date === apptDate &&
            value.hospital === apptHospital
        );
        isDraftCreated[index].diagnosis = diagnosis;
        isDraftCreated[index].priscription = priscription;
        isDraftCreated[index].instruction = instruction;
        isDraftCreated[index].otherData = otherData;
        isDraftCreated[index].handouts = handouts
        sessionStorage.setItem("draft_data", JSON.stringify(isDraftCreated));
      } else {
        // new data adding in draft
        const DraftStore = {
          PID: patientID,
          date: apptDate,
          hospital: apptHospital,
          diagnosis: diagnosis,
          priscription: priscription,
          instruction: instruction,
          otherData: otherData,
          handouts:handouts
        };

        isDraftCreated.push(DraftStore);
        sessionStorage.setItem("draft_data", JSON.stringify(isDraftCreated));
      }
    } else {
      const DraftStore = {
        PID: patientID,
        date: apptDate,
        hospital: apptHospital,
        diagnosis: diagnosis,
        priscription: priscription,
        instruction: instruction,
        otherData: otherData,
        handouts:handouts
      };

      sessionStorage.setItem("draft_data", JSON.stringify([DraftStore]));
    }
  }
};

const getDraftData = (patientID, apptDate, apptHospital) => {
  const isDraftCreated = JSON.parse(sessionStorage.getItem("draft_data"));
  let userData;

  if (isDraftCreated) {
    const isUserHave = isDraftCreated.some(
      (value) =>
        value.PID === patientID &&
        value.date === apptDate &&
        value.hospital === apptHospital
    );
    if (isUserHave) {
      userData = isDraftCreated.find(
        (value) =>
          value.PID === patientID &&
          value.date === apptDate &&
          value.hospital === apptHospital
      );
    } else {
      userData = null;
    }
  } else {
    userData = null;
  }
  return { userData };
};

const removeDataFromDraft = (patientID, apptDate, apptHospital) => {
  const isDraftCreated = JSON.parse(sessionStorage.getItem("draft_data"));

  if (isDraftCreated && isDraftCreated.length > 0) {
    const index = isDraftCreated.findIndex(
      // finding index of already created draft
      (value) =>
        value.PID === patientID &&
        value.date === apptDate &&
        value.hospital === apptHospital
    );

    isDraftCreated.splice(index, 1);

    sessionStorage.setItem("draft_data", JSON.stringify(isDraftCreated))
  }
};

export { storeIntoDraft, getDraftData, removeDataFromDraft };
