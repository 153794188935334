import moment from "moment";
import React from "react";
import rx from "../../../Images/RX.webp";

const Template1 = ({
  marginLeft,
  marginRight,
  fontSize,
  preview,
  headerHeight,
  footerHeight,
  fontFamily,
  patientDetail,
  dateTime,
  drugList,
  selectedReferal,
  selectedRefrence,
  vitalData,
  withHeader,
  languageList,
  diagnosis,
  findings,
  complaints,
  test,
  procedures,
  instruction,
  followUp,
  stepInstructionData,
  diagnosisNote,
  signature,
  doctorDataFooter,
  result,
  handoutList,
}) => {
  const TemplateFontSize = {
    fontSize: `${fontSize}px`,
    fontFamily: `${fontFamily}`,
  };

  return (
    <>
      <div
        className="template"
        style={{
          paddingLeft: marginLeft + "cm",
          paddingRight: marginRight + "cm",
          backgroundImage: withHeader ? "url(" + preview + ")" : null,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          minHeight: withHeader ? "100vh" : null,
          height: withHeader ? "29.7cm" : "100%",
          width:withHeader ? "21cm" : "100%"
        }}
      >
        <div className="templateViewSection">
          {result && result.template_layout_header && (
            <div
              className="header"
              style={{
                width: "100%",
                height: `${headerHeight}cm`,
              }}
            ></div>
          )}
          <div
            style={{
              width: "100%",
            }}
          >
            <table width="100%">
              <tbody width="100%">
                <tr width="100%">
                  <td width="50%" style={TemplateFontSize}>
                    {/* ==================== Pateint Name ============ */}
                    {result &&
                      result.template_patient_name &&
                      patientDetail && (
                        <div className="nameData">
                          <strong>Name: </strong>
                          <span>{patientDetail.name}</span>
                        </div>
                      )}

                    {/* ================ Pateint Age and Sex ============= */}
                    {result && result.template_patient_age && patientDetail && (
                      <div className="age">
                        <strong>Age/Sex: </strong>{" "}
                        <span>
                          {moment().diff(patientDetail.date, "years") +
                            " Y/ " +
                            patientDetail.gender}
                        </span>
                      </div>
                    )}
                    {/* =================== Patient ID ==================== */}
                    {result && result.template_patient_pid && patientDetail && (
                      <div className="patientId">
                        <strong>PID: </strong> <span>{patientDetail.ID}</span>
                      </div>
                    )}
                    {/* ====================== Address ========== */}
                    {result &&
                      result.template_patient_address &&
                      patientDetail.address && (
                        <div className="address">
                          <strong>ADD: </strong>
                          <span>
                            {patientDetail.address +
                              " " +
                              patientDetail.city +
                              " " +
                              patientDetail.pincode}
                          </span>
                        </div>
                      )}
                  </td>
                  {/* =================== Date ================ */}
                  <td width="50%" style={TemplateFontSize}>
                    {result && result.template_patient_date && dateTime && (
                      <div className="date">
                        <strong>Date: </strong> <span>{dateTime.date}</span>
                      </div>
                    )}

                    {/* ============= Time =============== */}
                    {result && result.template_patient_time && dateTime && (
                      <div className="time">
                        <strong>Time: </strong> <span>{dateTime.time}</span>
                      </div>
                    )}

                    {/* ===================== Mobile Number ============= */}
                    {result &&
                      result.template_patient_mobile &&
                      patientDetail && (
                        <div className="mobile">
                          <strong>Mobile: </strong>{" "}
                          <span>+91 {patientDetail.number}</span>
                        </div>
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* ======================= Vitals Details ============= */}
          {result &&
          result.template_patient_vital &&
          vitalData &&
          vitalData.length > 0 ? (
            <>
              <table
                style={{
                  width: "100%",
                  border: "1px solid grey",
                  tableLayout: "fixed",
                  marginTop: "10px",
                }}
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>WT:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>HT:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>BMI:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>TEMP:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>PULSE:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>RR:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>BP:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>SATS:</span>
                    </th>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_Weight} kg
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_height} cm
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patinet_bmi}
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_temperature}
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_pulse}
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_respiratory_rate}
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patinet_blood_presure}
                      </span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>
                        {vitalData[0].patient_oxygen_saturation}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}

          <table style={{ width: "100%", margin: "10px 0px 0px 0px" }}>
            <tbody>
              <tr width="100%" style={TemplateFontSize}>
                <td style={TemplateFontSize} width="50%">
                  {/* ============= Diagnosis =============== */}
                  <div className="diagnosisData">
                    {result &&
                    result.template_patient_diagnosis &&
                    diagnosis &&
                    diagnosis.length > 0 ? (
                      <>
                        <strong>Diagnosis: </strong>

                        {diagnosis
                          .filter(
                            (filtData) =>
                              filtData.diagnosis_type === "" &&
                              filtData.diagnosis_discription === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.diagnosis_name}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}

                        <ol style={{ listStyle: "auto" }}>
                          {diagnosis
                            .filter(
                              (filtData) =>
                                filtData.diagnosis_type !== "" ||
                                filtData.diagnosis_discription !== ""
                            )
                            .map((value, i) => (
                              <li key={i} style={{ listStyle: "auto" }}>
                                <span>
                                  {value.diagnosis_name}
                                  {value.diagnosis_type !== ""
                                    ? " (" + value.diagnosis_type + ")"
                                    : ""}
                                  {value.diagnosis_discription !== ""
                                    ? " - " + value.diagnosis_discription
                                    : ""}
                                </span>
                              </li>
                            ))}
                        </ol>
                      </>
                    ) : null}
                  </div>

                  {/* ==================================== Complaints ======================== */}
                  <div className="complaintsData">
                    {result &&
                    result.template_patient_complaints &&
                    complaints &&
                    complaints.length > 0 ? (
                      <>
                        <strong>Complaints: </strong>{" "}
                        {complaints
                          .filter(
                            (filtValue) =>
                              filtValue.diagnosis_type === "" &&
                              filtValue.diagnosis_discription === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.diagnosis_name +
                                (value.diagnosis_duration !== "null" &&
                                value.diagnosis_duration !== null &&
                                value.diagnosis_duration !== ""
                                  ? " since " +
                                    value.diagnosis_duration +
                                    " " +
                                    value.diagnosis_days
                                  : "")}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}
                        <ol style={{ listStyle: "auto" }}>
                          {complaints &&
                            complaints.map((value, i) =>
                              value.diagnosis_type !== "" ||
                              value.diagnosis_discription !== "" ? (
                                <li key={i} style={{ listStyle: "auto" }}>
                                  {" "}
                                  <span>
                                    {value.diagnosis_name +
                                      " since " +
                                      value.diagnosis_duration +
                                      " " +
                                      value.diagnosis_days}{" "}
                                    {value.diagnosis_type !== ""
                                      ? " (" + value.diagnosis_type + ")"
                                      : ""}
                                    {value.diagnosis_discription !== ""
                                      ? " - " + value.diagnosis_discription
                                      : null}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ol>
                      </>
                    ) : null}
                  </div>

                  {/* ================== Findings ======================== */}
                  <div className="findingsData">
                    {result &&
                    result.template_patient_findings &&
                    findings &&
                    findings.length > 0 ? (
                      <>
                        <strong>Findings: </strong>{" "}
                        {findings
                          .filter(
                            (filtValue) =>
                              filtValue.diagnosis_type === "" &&
                              filtValue.diagnosis_discription === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.diagnosis_name}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}
                        <ol style={{ listStyle: "auto" }}>
                          {findings &&
                            findings.map((value, i) =>
                              value.diagnosis_type !== "" ||
                              value.diagnosis_discription !== "" ? (
                                <li key={i} style={{ listStyle: "auto" }}>
                                  {" "}
                                  <span>
                                    {value.diagnosis_name}{" "}
                                    {value.diagnosis_type !== ""
                                      ? " (" + value.diagnosis_type + ")"
                                      : ""}
                                    {value.diagnosis_discription !== ""
                                      ? " - " + value.diagnosis_discription
                                      : ""}
                                  </span>
                                </li>
                              ) : null
                            )}
                        </ol>
                      </>
                    ) : null}
                  </div>
                </td>

                <td style={TemplateFontSize} width="50%">
                  {/* ======================= Instruction ============================ */}

                  <div className="isntructionData">
                    {result &&
                    result.template_patient_instruction &&
                    instruction &&
                    instruction.length > 0 ? (
                      <>
                        <strong>Instruction: </strong>{" "}
                        {instruction
                          .filter(
                            (filtValue) =>
                              filtValue.instruction_date === "" &&
                              filtValue.instruction_location_name === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.instruction_name}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}
                      </>
                    ) : null}
                  </div>

                  {/* =========================== Test Data ========================== */}
                  <div className="testData">
                    {result &&
                    result.template_patient_test &&
                    test &&
                    test.length > 0 ? (
                      <>
                        <strong>Test: </strong>{" "}
                        {test
                          .filter(
                            (filtValue) =>
                              filtValue.instruction_date === "" &&
                              filtValue.instruction_location_name === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.instruction_name}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}
                        <ol style={{ listStyle: "auto" }}>
                          {test &&
                            test.map((value, i) =>
                              value.instruction_date !== "" ||
                              value.instruction_location_name !== "" ? (
                                <li key={i} style={{ listStyle: "auto" }}>
                                  <span>
                                    {value.instruction_name}
                                    {value.instruction_location_name !== "" ? (
                                      <>
                                        &nbsp;at&nbsp;
                                        {value.instruction_location_name}
                                      </>
                                    ) : null}
                                    {value.instruction_date !== "" ? (
                                      <>
                                        to be done on{" "}
                                        {moment(
                                          value.instruction_date,
                                          "YYYY-MM-DD"
                                        ).format("DD MMM YYYY")}
                                      </>
                                    ) : null}
                                  </span>
                                </li>
                              ) : null
                            )}
                        </ol>
                      </>
                    ) : null}
                  </div>

                  {/* ============================ Procedures Data =============================== */}

                  <div className="proceduresData">
                    {result &&
                    result.template_patient_procedure &&
                    procedures &&
                    procedures.length > 0 ? (
                      <>
                        <strong>Procedures: </strong>{" "}
                        {procedures
                          .filter(
                            (filtValue) =>
                              filtValue.instruction_date === "" &&
                              filtValue.instruction_location_name === ""
                          )
                          .map((value, i) => (
                            <span key={i} className="listItemForComma">
                              {value.instruction_name}
                            </span>
                          ))
                          .map((element, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && ", "}
                              {element.props.children}
                            </React.Fragment>
                          ))}
                        <ol style={{ listStyle: "auto" }}>
                          {procedures &&
                            procedures.map((value, i) =>
                              value.instruction_date !== "" ||
                              value.instruction_location_name !== "" ? (
                                <li key={i} style={{ listStyle: "auto" }}>
                                  {" "}
                                  <span>
                                    {value.instruction_name}
                                    {value.instruction_location_name !== "" ? (
                                      <>
                                        &nbsp;at&nbsp;
                                        {value.instruction_location_name}
                                      </>
                                    ) : null}
                                    {value.instruction_date !== "" ? (
                                      <>
                                        to be done on{" "}
                                        {moment(
                                          value.instruction_date,
                                          "YYYY-MM-DD"
                                        ).format("DD MMM YYYY")}
                                      </>
                                    ) : null}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ol>
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {(complaints && complaints.length > 0) ||
          (diagnosis && diagnosis.length > 0) ||
          (findings && findings.length > 0) ||
          (procedures && procedures.length > 0) ||
          (instruction && instruction.length > 0) ||
          (test && test.length > 0) ? (
            <hr />
          ) : null}

          {/* ============ Surgical Notes  ===================== */}
          {result &&
          result.template_patient_clinic_note &&
          diagnosisNote &&
          diagnosisNote.length < 0
            ? null
            : diagnosisNote.map(
                (value, i) =>
                  value.patient_desc_text !== "" && (
                    <div key={i} className="diagnosisItem mb-2">
                      <h6 style={TemplateFontSize}>
                        <strong>Clinical / Surgical Notes: </strong>
                        <span>{value.patient_desc_text}</span>
                      </h6>
                    </div>
                  )
              )}

          {result &&
          result.template_patient_drug &&
          drugList &&
          drugList.length > 0 ? (
            <>
              <div className="drugList">
                <img src={rx} width={35} alt="rx" />
                <div>
                  <table style={{ width: "100%" }}>
                    <thead style={TemplateFontSize}>
                      <tr style={TemplateFontSize}>
                        <th
                          style={{
                            borderBottom: "1px solid rgb(153 153 153)",
                            padding: "10px 0",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid rgb(153 153 153)",
                            padding: "10px 0",
                          }}
                        >
                          Frequency
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid rgb(153 153 153)",
                            padding: "10px 0",
                          }}
                        >
                          Duration
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid rgb(153 153 153)",
                            padding: "10px 0",
                          }}
                        >
                          Instruction
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ padding: "5px 0 0 0" }}>
                      {drugList.map((value, i) => (
                        <tr key={i} style={TemplateFontSize}>
                          <td>
                            <span
                              style={{
                                textTransform: "capitalize",
                                margin: "unset",
                              }}
                            >
                              {value.drug_catogery +
                                " " +
                                value.brand_name +
                                (value.drug_strength !== " mg" &&
                                value.drug_strength !== " ml" &&
                                value.drug_strength !== ""
                                  ? " (" + value.drug_strength + ")"
                                  : "") +
                                  (value.once_in !== null && value.once_in !== "" && value.once_in !== undefined && value.once_in !== "Select Once In"
                                  ? " - Once In " + value.once_in
                                  : "")}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: ".8rem",
                                color: "#808080",
                                margin: "unset",
                              }}
                            >
                              {value.drug_instruction}
                            </span>
                          </td>
                          {/* =================== tapper frequency list ==================== */}
                          {value.drug_tapper_list.length > 0 ? (
                            <td style={{ padding: "5px 0" }}>
                              {value.drug_tapper_list.map((valueList, i) =>
                                valueList.taper_freq_sos !== "null" ? (
                                  <div key={i}>{valueList.dose}</div>
                                ) : (
                                  <div key={i}>
                                    <span>
                                      {valueList.taper_freq_morning !== "null"
                                        ? valueList.dose
                                        : "0"}
                                    </span>
                                    <span>
                                      {valueList.taper_freq_afternoon !== "null"
                                        ? "- " + valueList.dose
                                        : " - 0"}{" "}
                                    </span>
                                    <span>
                                      {valueList.taper_freq_evening !== "null"
                                        ? "- " + valueList.dose
                                        : ""}{" "}
                                    </span>
                                    <span>
                                      {valueList.taper_freq_night !== "null"
                                        ? "- " + valueList.dose
                                        : " - 0"}{" "}
                                    </span>
                                  </div>
                                )
                              )}
                            </td>
                          ) : value.drug_freq_sos !== "null" ? (
                            <td style={{ padding: "5px 0" }}>
                              {value.drug_dosg}
                            </td>
                          ) : (
                            <td style={{ padding: "5px 0" }}>
                              {value.drug_freq_morning !== "null"
                                ? value.drug_freq_morning === "morning"
                                  ? value.drug_dosg
                                  : value.drug_freq_morning
                                : "0"}
                              {value.drug_freq_afternoon !== "null"
                                ? value.drug_freq_afternoon === "after"
                                  ? " - " + value.drug_dosg
                                  : " - " + value.drug_freq_afternoon
                                : " - 0"}
                              {value.drug_freq_evening !== "null"
                                ? value.drug_freq_evening === "evening"
                                  ? " - " + value.drug_dosg
                                  : " - " + value.drug_freq_evening
                                : ""}
                              {value.drug_freq_night !== "null"
                                ? value.drug_freq_night === "night"
                                  ? " - " + value.drug_dosg
                                  : " - " + value.drug_freq_night
                                : " - 0"}
                            </td>
                          )}
                          {/* ========================= Tapper Duration List ======================== */}
                          {value.drug_tapper_list.length > 0 ? (
                            <td style={{ padding: "5px 0" }}>
                              {value.drug_tapper_list.map((valueList, i) => (
                                <tr key={i}>
                                  <td>
                                    {moment(
                                      valueList.fromDate,
                                      "YYYY-MM-DD"
                                    ).format("DD MMM YYYY")}{" "}
                                    To{" "}
                                    {moment(
                                      valueList.toDate,
                                      "YYYY-MM-DD"
                                    ).format("DD MMM YYYY")}
                                  </td>
                                </tr>
                              ))}
                            </td>
                          ) : (
                            <td style={{ padding: "5px 0" }}>
                              <div className="careTakerNo">
                                {value.drug_duration_days +
                                  " " +
                                  (languageList && languageList[0].language === "english"
                                    ? value.drug_duration_dayType
                                    : value.drug_duration_dayType === "days"
                                    ? languageList[0].days
                                    : value.drug_duration_dayType === "months"
                                    ? languageList[0].months
                                    : languageList[0].years)}
                              </div>
                            </td>
                          )}

                          {/* ================== Tapper Instruction =============== */}

                          {value.drug_tapper_list.length > 0 && (
                            <td style={{ padding: "5px 0" }}>
                              {value.drug_tapper_list.map((valueList) => (
                                <div key={valueList.someUniqueKey}>
                                  {/* ============== Tapper SOS Freq ========== */}
                                  {valueList.taper_freq_sos !== "null"
                                    ? value.drug_relation === "After"
                                      ? languageList[0].sosAfter
                                      : value.drug_relation === "Before"
                                      ? languageList[0].sosBefore
                                      : languageList[0].sosAnyTime
                                    : null}

                                  {/* ========== Tapper Morning Freq ========== */}
                                  {valueList.taper_freq_morning !== "null" && (
                                    <span>
                                      {languageList[0].language === "english"
                                        ? value.drug_relation +
                                          " " +
                                          languageList[0].breakfast +
                                          " "
                                        : languageList[0].breakfast +
                                          " " +
                                          (value.drug_relation === "After"
                                            ? languageList[0].after + ", "
                                            : languageList[0].before + ", ")}
                                    </span>
                                  )}

                                  {/* ========== Tapper Afternoon Freq ========== */}
                                  {valueList.taper_freq_afternoon !==
                                    "null" && (
                                    <span>
                                      {languageList[0].language === "english"
                                        ? ", " +
                                          value.drug_relation +
                                          " " +
                                          languageList[0].lunch +
                                          " "
                                        : languageList[0].lunch +
                                          " " +
                                          (value.drug_relation === "After"
                                            ? languageList[0].after + ", "
                                            : languageList[0].before + ", ")}
                                    </span>
                                  )}

                                  {/* ========== Tapper Night Freq ========== */}
                                  {valueList.taper_freq_night !== "null" && (
                                    <span>
                                      {languageList[0].language === "english"
                                        ? ", " +
                                          value.drug_relation +
                                          " " +
                                          languageList[0].dinner +
                                          " "
                                        : languageList[0].dinner +
                                          " " +
                                          (value.drug_relation === "After"
                                            ? languageList[0].after + " "
                                            : languageList[0].before + " ")}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </td>
                          )}

                          {/* =================== Normale Instruction  ======= */}
                          {value.drug_tapper_list.length === 0 &&
                            (value.drug_freq_sos !== "null" ? (
                              <td style={{ padding: "5px 0" }}>
                                {value.drug_relation === "After"
                                  ? languageList[0].sosAfter
                                  : value.drug_relation === "Before"
                                  ? languageList[0].sosBefore
                                  : languageList[0].sosAnyTime}
                              </td>
                            ) : (
                              <td style={{ padding: "5px 0" }}>
                                {value.drug_freq_morning !== "null"
                                  ? languageList[0].language === "english"
                                    ? value.drug_relation +
                                      " " +
                                      languageList[0].breakfast +
                                      " "
                                    : languageList[0].breakfast +
                                      " " +
                                      (value.drug_relation === "After"
                                        ? languageList[0].after + ", "
                                        : languageList[0].before + ", ")
                                  : ""}
                                {value.drug_freq_afternoon !== "null"
                                  ? languageList[0].language === "english"
                                    ? ", " +
                                      value.drug_relation +
                                      " " +
                                      languageList[0].lunch +
                                      " "
                                    : languageList[0].lunch +
                                      " " +
                                      (value.drug_relation === "After"
                                        ? languageList[0].after + ", "
                                        : languageList[0].before + ", ")
                                  : ""}
                                {value.drug_freq_night !== "null"
                                  ? languageList[0].language === "english"
                                    ? ", " +
                                      value.drug_relation +
                                      " " +
                                      languageList[0].dinner +
                                      " "
                                    : languageList[0].dinner +
                                      " " +
                                      (value.drug_relation === "After"
                                        ? languageList[0].after + " "
                                        : languageList[0].before + " ")
                                  : ""}
                              </td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
            </>
          ) : null}

          <table style={{ width: "100%", textAlign: "left" }}>
            <tbody>
              {/* =============================== Drug Instruction ===================== */}

              {result &&
              result.template_patient_specific_drug &&
              stepInstructionData &&
              stepInstructionData.length < 0
                ? null
                : stepInstructionData.map(
                    (value, i) =>
                      value.patient_desc_text !== "" && (
                        <tr key={i} style={TemplateFontSize}>
                          <td style={{ padding: "5px 0px" }} width="50%">
                            <strong>Specific Drug Instructions:</strong>
                          </td>
                          <td style={{ padding: "5px 0px" }} width="50%">
                            <span>-</span> {value.patient_desc_text}.
                          </td>
                        </tr>
                      )
                  )}

              {/* =================== Hoadouts ======================== */}
              {result &&
                result.template_patient_handouts &&
                handoutList &&
                (handoutList.length > 0 ? (
                  <tr style={TemplateFontSize}>
                    <th style={{ padding: "3px 0px" }}>Handouts:</th>
                    <td style={{ padding: "3px 0px" }}>
                      -{" "}
                      {handoutList
                        .map((value, i) => (
                          <span key={i}>{value.handout_title}</span>
                        ))
                        .map((element, i) => (
                          <React.Fragment key={i}>
                            {i > 0 && ", "}
                            {element.props.children}
                          </React.Fragment>
                        ))}
                    </td>
                  </tr>
                ) : null)}

              {/* ==================== Follow Up ================= */}
              {result &&
                result.template_patient_follow_up &&
                followUp &&
                (followUp.length < 0
                  ? null
                  : followUp.map((value, i) => (
                      <tr key={i} style={TemplateFontSize}>
                        <th style={{ padding: "3px 0px" }}>Follow Up:</th>
                        <td style={{ padding: "3px 0px" }}>
                          -{" "}
                          {moment(value.follow_up, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )}{" "}
                          {value.patient_desc_text === ""
                            ? ""
                            : ` (Note: ${value.patient_desc_text})`}
                        </td>
                      </tr>
                    )))}

              {/* =========================== Referrel =============================== */}
              {result &&
                result.template_patient_referral &&
                selectedReferal &&
                (selectedReferal.doctor_referal_name === "" ? null : (
                  <tr style={TemplateFontSize}>
                    <th style={{ padding: "5px 0px" }}>Referral:</th>
                    <td style={{ padding: "5px 0px" }}>
                      -{" "}
                      {selectedReferal.doctor_referal_name +
                        " (" +
                        selectedReferal.doctor_referal_specialization +
                        ")  to contact +91" +
                        selectedReferal.doctor_referal_mobile}
                    </td>
                  </tr>
                ))}

              {/* ============================= Refrences ======================= */}

              {result &&
                result.template_patient_references &&
                selectedRefrence &&
                (selectedRefrence.doctor_referal_name === "" ? null : (
                  <tr style={TemplateFontSize}>
                    <th style={{ padding: "5px 0px" }}>References:</th>
                    <td style={{ padding: "5px 0px" }}>
                      -{" "}
                      {selectedRefrence.doctor_referal_name +
                        " (" +
                        selectedRefrence.doctor_referal_specialization +
                        ")  to contact +91" +
                        selectedRefrence.doctor_referal_mobile}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* ====================== Border Line Checking ================= */}
          {(selectedRefrence && selectedRefrence.doctor_referal_name !== "") ||
          (selectedReferal && selectedReferal.doctor_referal_name !== "") ||
          (followUp && followUp.length > 0) ||
          (stepInstructionData && stepInstructionData.length > 0) ? (
            <hr />
          ) : null}

          <table style={{ width: "100%" }}>
            <tbody style={{ padding: "0 10px" }}>
              <tr style={{ textAlign: "left" }}>
                <td style={{ padding: "5px 0px 0px 0px" }}></td>
                <td style={{ textAlign: "right" }}>
                  <div
                    className="doctorSignatueDetails"
                    style={{
                      margin: "0px 15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    {/* ================== Signature ============= */}
                    {result &&
                      result.template_doctor_signature &&
                      signature && (
                        <div className="signImg">
                          <img
                            src={signature}
                            alt="signature"
                            style={{ maxWidth: "110px !important" }}
                          />
                        </div>
                      )}

                    {/* ================= Doctor Qualification and Degreee */}
                    {result &&
                      result.template_doctor_qualification &&
                      doctorDataFooter && (
                        <div
                          className="doctorName"
                          style={{ width: "100%", textAlign: "end" }}
                        >
                          <p style={TemplateFontSize}>
                            <div
                              style={TemplateFontSize}
                              dangerouslySetInnerHTML={{
                                __html: doctorDataFooter,
                              }}
                            />
                          </p>
                        </div>
                      )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {result && result.template_layout_footer && (
            <div
              className="footer"
              style={{
                width: "100%",
                height: `${footerHeight}cm`,
              }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Template1;
