import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import SideBarProvider from "./context/sideBar";
import { ConfigProvider } from "antd";
import ImageResizerProvider from "./context/imageResizer";
import serviceDev from "./swDev";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "rgb(9, 57, 101)",
          },
        }}
      >
        <SideBarProvider>
          <ImageResizerProvider>
            <App />
          </ImageResizerProvider>
        </SideBarProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

serviceDev();
reportWebVitals();
