import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./AddDrugBox.css";
import { useDrugRegisterMutation } from "../../services/userAuthAPI";
import { getToken } from "../../services/localStorageServices";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { message } from "antd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  setSuggestionList,
  suggestionList,
  drugName,
}) {
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState("DrugDetails");
  const [medicineList, setMedicineList] = React.useState([]);
  const [drugRegister] = useDrugRegisterMutation();
  const { access_token } = getToken();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [medicineType, setMedicineType] = useState(null);
  const [isSosChecked, setIsSosChecked] = useState(false);
  const [frequencyCheck, setFrequencyChecked] = useState({
    morning: null,
    afternoon: null,
    evening: null,
    night: null,
    sos: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
    setKey("DrugDetails");
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ====== show oral non-oral ========
  const [oralView, setOralView] = React.useState("oral");

  const toggleOral = () =>
    setOralView((prev) => (prev === "oral" ? "nonOral" : "oral"));

  const activeStepComponent = (k) => {
    setKey(k);
  };

  // ==================== Medicine add into Database =====================
  const medicineAdd = async (e) => {
    e.preventDefault();
    setLoader(true);

    const data = new FormData(e.currentTarget);
    const medicineData = suggestionList;
    const medicineListdDataAdd = medicineList;

    const medicineListData = {
      drug_name: data.get("companyName"),
      brand_name: data.get("brandName"),
      generic_name: data.get("genericName"),
      drug_mrp: data.get("mrp"),
      medicine_category_type: oralView,
      medicine_category: data.get("medicineSelected"),
      strength_medicine:
        data.get("strengthNumber") + " " + data.get("strengthUnit"),
      frequency:
        data.get("morning") +
        ", " +
        data.get("after") +
        ", " +
        data.get("evening") +
        ", " +
        data.get("night") +
        ", " +
        data.get("sos") +
        ", null",
      duration: data.get("durationTime") + " " + data.get("durationDay"),
      relation_of_food: data.get("relationWithFood"),
      drug_instruction: data.get("drugInstruction"),
      once_in:data.get("once_in"),
      token: access_token,
    };

    const req = await drugRegister(medicineListData);

    if (req.data) {
      message.success("Drug Added Successfully");

      setLoader(false);
      medicineData.unshift(medicineListData);
      medicineListdDataAdd.unshift(medicineListData);

      const localData = JSON.parse(localStorage.getItem("suggestionList"));
      localData.drugs = medicineData;
      localStorage.setItem("suggestionList", JSON.stringify(localData));

      setSuggestionList([...medicineData]);
      setMedicineList([...medicineListdDataAdd]);
      setShowErrorMessage(false);
      setOpen(false);
      e.target.reset();
    }
    if (req.error) {
      showErrorMessage(true);
    }
  };

  return (
    <div>
      <button variant="outlined" className="Clickbtn" onClick={handleClickOpen}>
        + Add Drug {drugName}
      </button>
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Add Drug
      </Button> */}
      <Dialog
        className="AddDrugDialogue"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px", // Set your width here
              minHeight: "500px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="addDrugBoxHead">
            <h6>Add Drug</h6>
          </div>
          <div className="Facilities-Section2-tabpanel">
            <form onSubmit={medicineAdd}>
              <Tabs
                defaultActiveKey="DrugDetails"
                id="justify-tab-example"
                className="mb-3"
                justify
                onSelect={(k) => activeStepComponent(k)}
                activeKey={key}
              >
                {/* ========== Drugs Details ============= */}
                <Tab eventKey="DrugDetails" title="Drug Details">
                  <div className="Treatment-Con drugDetail">
                    <div className="input-style-1">
                      {/* <label>Brand Name</label> */}
                      <input
                        type="text"
                        name="brandName"
                        className="InputStyleMain"
                        placeholder="Enter Drug Name"
                        defaultValue={drugName}
                        style={{ textTransform: "capitalize" }}
                      />
                    </div>
                    <div className="input-style-1">
                      {/* <label>Company Name</label> */}
                      <input
                        type="text"
                        name="companyName"
                        className="InputStyleMain"
                        placeholder="Enter Company Name"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-style-1">
                          {/* <label>MRP</label> */}
                          <input
                            type="number"
                            name="mrp"
                            className="InputStyleMain"
                            placeholder="Enter MRP in Rupees "
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="input-style-1">
                          <input
                            type="text"
                            name="genericName"
                            className="InputStyleMain"
                            placeholder="Enter Generic Name"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="input-style-1">
                          <label>Generic Name</label>
                          <input
                            type="text"
                            name="genericName"
                            placeholder="Enter Generic Name"
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-md-6">
                        <div className="input-style-1 showGenericInput">
                          <input type="checkbox" name="genericCheked" />
                          <label>Show Generic Name</label>
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="oralButtons">
                          <span>Oral</span>
                          <span>Non Oral</span>
                          <div
                            onClick={toggleOral}
                            className={`${oralView} scrollButton`}
                          >
                            {oralView === "oral" ? "Oral" : "Non Oral"}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* <label htmlFor="nonOral" className="Nonoralbtn">&nbsp;Non-Oral</label> */}
                      </div>
                      {oralView === "oral" ? (
                        <div className="col-12">
                          <div className="drugTypeSection">
                            <div className="row">
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Syrup"
                                  name="medicineSelected"
                                  value="Syrup"
                                />
                                 <label htmlFor="Syrup">Syrup</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Drops"
                                  name="medicineSelected"
                                  value="Drops"
                                />
                                  <label htmlFor="Drops">Drops</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Tablet"
                                  name="medicineSelected"
                                  value="Tablet"
                                />
                                  <label htmlFor="Tablet">Tablet</label> 
                              </div>

                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Sachet"
                                  name="medicineSelected"
                                  value="Sachet"
                                />
                                  <label htmlFor="Sachet">Sachet</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Capsule"
                                  name="medicineSelected"
                                  value="Capsule"
                                />
                                  <label htmlFor="Capsule">Capsule</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Vial"
                                  name="medicineSelected"
                                  value="Vial"
                                />
                                  <label htmlFor="Vial">Vial</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="RTD"
                                  name="medicineSelected"
                                  value="RTD"
                                />
                                  <label htmlFor="RTD">RTD</label> 
                              </div>

                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Biscuit"
                                  name="medicineSelected"
                                  value="Biscuit"
                                />
                                  <label htmlFor="Biscuit">Biscuit</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="diskette"
                                  name="medicineSelected"
                                  value="diskette"
                                />
                                  <label htmlFor="diskette">Diskette</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="OralPowder"
                                  name="medicineSelected"
                                  value="Oral Powder"
                                />
                                 {" "}
                                <label htmlFor="OralPowder">
                                  Oral&nbsp;Powder
                                </label>
                                 
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {oralView === "nonOral" ? (
                        <div className="col-12">
                          <div className="drugTypeSection">
                            <div className="row">
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Ointment"
                                  name="medicineSelected"
                                  value="Ointment"
                                />
                                  <label htmlFor="Ointment">Ointment</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Powder"
                                  name="medicineSelected"
                                  value="Powder"
                                />
                                  <label htmlFor="Powder">Powder</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Soap"
                                  name="medicineSelected"
                                  value="Soap"
                                />
                                  <label htmlFor="Soap">Soap</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Oil"
                                  name="medicineSelected"
                                  value="Oil"
                                />
                                  <label htmlFor="Oil">Oil</label> 
                              </div>
                              <div className="col-md-3 col-6 d-flex justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    setMedicineType(e.target.value)
                                  }
                                  className="form-check-input"
                                  id="Serum"
                                  name="medicineSelected"
                                  value="Serum"
                                />
                                  <label htmlFor="Serum">Serum</label> 
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Tab>

                {/* ========== Strength Details ============= */}
                {/* <Tab eventKey="StrengthDetails" title="Strength Details">
                  <div className="Treatment-Con strengthDetailSection">
                    
                      <div className="col-12">
                        <div className="addStrength">
                          <h6>
                            
                            <span>
                              
                              <BsPlus />
                            </span>
                            Add Strength
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab> */}

                {/* ========== Other Detail ============= */}
                <Tab eventKey="OtherDetails" title="Other Details">
                  <div className="Treatment-Con">
                    <div className="otherDetails">
                      {/* Frequency section */}
                      <div className="frequencyBox">
                        <div className="row strengthDetailSection">
                          <div className="col-4 mb-4">
                            {oralView === "oral" ? (
                              <div className="input-style-1 oneCapsuletext">
                                <h6>
                                  {medicineType && "1 " + medicineType + " = "}
                                </h6>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-4 mb-4">
                            <div className="input-style-1">
                              {/* <h6>Strength</h6> */}
                              <input
                                type="number"
                                name="strengthNumber"
                                className="InputStyleMain"
                                placeholder="Strength"
                                min="0"
                                max="1000"
                                step="0.50"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="input-style-1 strengthUnitSelect">
                              {/* <h6>Strenght Unit</h6> */}
                              <select name="strengthUnit" id="strenghtUnit">
                                <option value="mg">mg</option>
                                <option value="ml">ml</option>
                                <option value="IU">IU</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <h6>Frequency</h6>
                          </div>

                          <div className="col-8">
                            <div className="frequencyBtns">
                              <div className="row">
                                <div className="col d-flex justify-content-start align-items-center flex-column">
                                  <h6>M</h6>
                                  <input
                                    name="morning"
                                    value="morning"
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) =>
                                      setFrequencyChecked({
                                        ...frequencyCheck,
                                        morning: e.target.checked
                                          ? e.target.value
                                          : null,
                                      })
                                    }
                                    checked={
                                      isSosChecked
                                        ? false
                                        : frequencyCheck.morning === "morning"
                                    }
                                    disabled={isSosChecked}
                                  />
                                </div>
                                <div className="col d-flex justify-content-start align-items-center flex-column">
                                  <h6>A</h6>
                                  <input
                                    name="after"
                                    value="after"
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) =>
                                      setFrequencyChecked({
                                        ...frequencyCheck,
                                        afternoon: e.target.checked
                                          ? e.target.value
                                          : null,
                                      })
                                    }
                                    checked={
                                      isSosChecked
                                        ? false
                                        : frequencyCheck.afternoon === "after"
                                    }
                                    disabled={isSosChecked}
                                  />
                                </div>
                                <div className="col d-flex justify-content-start align-items-center flex-column">
                                  <h6>E</h6>
                                  <input
                                    name="evening"
                                    value="evening"
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) =>
                                      setFrequencyChecked({
                                        ...frequencyCheck,
                                        evening: e.target.checked
                                          ? e.target.value
                                          : null,
                                      })
                                    }
                                    checked={
                                      isSosChecked
                                        ? false
                                        : frequencyCheck.evening === "evening"
                                    }
                                    disabled={isSosChecked}
                                  />
                                </div>
                                <div className="col d-flex justify-content-start align-items-center flex-column">
                                  <h6>N</h6>
                                  <input
                                    name="night"
                                    value="night"
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) =>
                                      setFrequencyChecked({
                                        ...frequencyCheck,
                                        night: e.target.checked
                                          ? e.target.value
                                          : null,
                                      })
                                    }
                                    checked={
                                      isSosChecked
                                        ? false
                                        : frequencyCheck.night === "night"
                                    }
                                    disabled={isSosChecked}
                                  />
                                </div>
                                <div className="col d-flex justify-content-start align-items-center flex-column">
                                  <h6>SOS</h6>
                                  <input
                                    name="sos"
                                    value="sos"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={frequencyCheck.sos === "sos"}
                                    onChange={(e) => {
                                      setIsSosChecked((prev) =>
                                        prev === true ? false : true
                                      );
                                      setFrequencyChecked({
                                        ...frequencyCheck,
                                        sos: e.target.checked
                                          ? e.target.value
                                          : null,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Duration Section */}
                      <div className="durationSection">
                        <div className="row">
                          <div className="col-4">
                            <h6>Duration</h6>
                          </div>
                          <div className="col-3">
                            <input
                              type="number"
                              name="durationTime"
                              className="InputStyleMain"
                            />
                          </div>
                          <div className="col-3">
                            <div className="searchDiv">
                              <select name="durationDay" id="duration">
                                <option value="days">days</option>
                                <option value="weeks">weeks</option>
                                <option value="months">months</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Once In Week Section */}
                      <div className="durationSection">
                        <div className="row">
                          <div className="col-4">
                            <h6>Once In</h6>
                          </div>
                          <div className="col-6">
                            <div className="searchDiv">
                              <select name="once_in" id="duration">
                              <option value={null} selected>Select Once In</option>
                                <option value="week">week</option>
                                <option value="month">month</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Relation with Food */}
                      <div className="relationWithFood">
                        <h6>Relation with Food</h6>
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Before"
                          name="relationWithFood"
                          value="Before"
                        />
                          <label htmlFor="Before">Before</label> 
                        <input
                          type="radio"
                          className="form-check-input"
                          id="After"
                          name="relationWithFood"
                          value="After"
                        />
                          <label htmlFor="After">After</label> 
                        <input
                          type="radio"
                          className="form-check-input"
                          id="AnyTime"
                          name="relationWithFood"
                          value="AnyTime"
                        />
                          <label htmlFor="AnyTime">Any Time</label> 
                      </div>

                      {/* Drug Instruction */}
                      <div className="drugInstruction">
                        <h6>Drug Instructions</h6>
                        <div className="input-style-1">
                          <textarea
                            className="form-control TextareaStyleMain"
                            rows={2}
                            name="drugInstruction"
                            type="text"
                            placeholder="Enter drug instructions"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>

              <div className="buttonList">
                {loader ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      sx={{
                        width: "45px !important",
                        height: "45px !important",
                      }}
                    />
                    &nbsp;
                    {showErrorMessage ? (
                      <span
                        style={{
                          color: "red",
                          textTransform: "lowercase !important",
                        }}
                      >
                        For some technical problem unable add drug
                      </span>
                    ) : (
                      "Uploading..."
                    )}{" "}
                  </span>
                ) : null}
                {key === "DrugDetails" ? (
                  <span onClick={handleClose} className="Cancelbtn">
                    Cancel
                  </span>
                ) : (
                  <span
                    onClick={() => setKey("DrugDetails")}
                    className="Clickbtn"
                  >
                    Previous
                  </span>
                )}

                {key === "OtherDetails" ? (
                  <Button type="submit" value="submit" className="submitBtn">
                    Submit
                  </Button>
                ) : (
                  <span
                    className="Clickbtn"
                    onClick={() => setKey("OtherDetails")}
                  >
                    Next
                  </span>
                )}
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
