import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import { useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import { getToken } from "../services/localStorageServices";
import moment from "moment";
import { Pagination, Table } from "antd";

export default function Logs() {
  const columns = [
    {
      title: "Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      fixed: "left",
    },
    {
      title: "Template Name",
      dataIndex: "log_title",
      key: "log_title",
    },
    {
      title: "Message",
      dataIndex: "log_message",
      key: "log_message",
    },
    {
      title: "Used",
      dataIndex: "CreditUsed",
      key: "CreditUsed",
      fixed: "right",
    },
  ];

  const { type } = useParams();
  const { access_token } = getToken();
  const [logData, setLogData] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal]=useState(0);
  const [loading, setLoading] = useState(false);

  const fetchLogs = async (pageData, limitData) => {
    setLoading(true)
    const response = await fetch(
      "https://skdm.in/skddev/DrCrm/message_api/logs_api.php",
      {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          type: type,
          page: pageData,
          limit: limitData,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await response.json();
      const arrayData = data.list.map((item) => ({
        ...item,
        CreatedAt: moment(item.CreatedAt).format("llll"),
      }));
      setLogData(arrayData);
      setTotal(data.total);
      setLoading(false)
    
  };

  useEffect(() => {
    fetchLogs(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  return (
    <div>
      <NavbarComp />
      <SideBar />

      <div className="logsSection navbarBodyMargin px-lg-5 px-sm-3 px-2">
        <div className="pageHeader mb-5">
          <h3 className="pageTitle ">{type} Logs List</h3>

          {type === "sms" ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/sms">
                SMS Setting
              </Link>
              <Typography color="text.primary"> Logs List</Typography>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/email">
                Email Setting
              </Link>
              <Typography color="text.primary"> Logs List</Typography>
            </Breadcrumbs>
          )}
        </div>

        <div className="card">
          <Table loading={loading} columns={columns} dataSource={logData} pagination={false} />
        </div>
        {logData && (
          <div className="card">
            <Pagination
              total={total}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              pageSize={limit}
              current={page}
              onChange={(page, pageSize) => {
                setPage(page)
                setLimit(pageSize)
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
