import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";

function TextEditor({ setTemplateSetting, templateSetting, defaultValue }) {
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  useEffect(() => {
    // Update the value when the defaultValue prop changes
    if (defaultValue !== null) {
      setValue(RichTextEditor.createValueFromString(defaultValue, "html"));
    } else {
      // Handle the case when defaultValue is explicitly set to null
      setValue(RichTextEditor.createEmptyValue());
    }
  }, [defaultValue]);

  const onChange = (newValue) => {
    setValue(newValue);
    if (setTemplateSetting) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      setTemplateSetting({
        ...templateSetting,
        doctorDetails: newValue.toString("html"),
      });
    }
  };

  return (
    <>
      <RichTextEditor
        value={value}
        onChange={onChange}
        toolbarConfig={toolbarConfig}
      />
    </>
  );
}

TextEditor.propTypes = {
  setTemplateSetting: PropTypes.func,
  templateSetting: PropTypes.object,
  defaultValue: PropTypes.string, // New prop to set the initial value
};

export default TextEditor;
