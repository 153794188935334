import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./AddAllergyBox.css";
import { BiPlus } from "react-icons/bi";
import { useNoteUpdatedMutation } from "../../services/userAuthAPI";
import { getToken } from "../../services/localStorageServices";
import { message } from "antd";

const AddAlleryBox = ({ alleryListFetch, setAlleryListFetch, patientId }) => {
  const [open, setOpenAllergy] = React.useState(false);
  const [allergyText, setAllergyText] = useState();
  const [ noteUpdated ] = useNoteUpdatedMutation();
  const {access_token} = getToken();

  const handleAllergeyAdd = async () => {
    const addData = {
      appointmentIdreff: null,
      patientIdReff: patientId,
      token:access_token,
      data: [
        {
          patient_desc_text: allergyText,
          patient_desc_type: "allergy",
          follow_up: null,
        },
      ],
    };

    const res = await noteUpdated(addData);
    if (res.data) {

      const localAdd = {
        id:res.data.id,
        text:allergyText
      }
      message.success("Allergy Added succesfully")
      setAlleryListFetch([...alleryListFetch, localAdd]);
      setOpenAllergy(false);
      setAllergyText("");
    }
  };
  const clickOpenAllergy = () => {
    setOpenAllergy(true);
  };

  const closeAllergy = () => {
    setOpenAllergy(false);
  };

  return (
    <div>
      <div className="addAlleryBox ">
        <button
          variant="outlined"
          onClick={clickOpenAllergy}
          className="Clickbtn"
        >
          <BiPlus style={{ marginRight: "5px" }} />
          Add&nbsp;Allergy
        </button>
        <Dialog
          open={open}
          onClose={closeAllergy}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px", // Set your width here
              },
            },
          }}
        >
          <DialogContent>
            {/* Header */}
            <div className="popUpFormTitle">
              <span>Add&nbsp;Allergy</span>
            </div>
            {/* Body */}
            <div className="addAllergyBody">
              <div className="input-style-1">
                <input
                  type="text"
                  placeholder="Enter here..."
                  onChange={(e) => setAllergyText(e.target.value)}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button className="Cancelbtn" onClick={closeAllergy}>
              Cancel
            </button>
            <button className="Clickbtn" onClick={handleAllergeyAdd}>
              {" "}
              Save
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AddAlleryBox;
