import React from "react";
import rx from "../../../Images/RX.webp";

const TemplateOneView = ({
  fontSize,
  fontFamily,
}) => {
  const TemplateFontSize = {
    fontSize: `${fontSize}px`,
    fontFamily: `${fontFamily}`,
  };
 
  return (
    <>
      <div
        className="template"
      >
        <div className="templateViewSection">
          <div
            style={{
              width: "100%",
            }}
          >
            <table width="100%">
              <tbody width="100%">
                <tr width="100%">
                  <td width="50%" style={TemplateFontSize}>
                    {/* ==================== Pateint Name ============ */}
                  
                      <div className="nameData">
                        <strong>Name: </strong>
                        <span>Patient Name</span>
                      </div>

                    {/* ================ Pateint Age and Sex ============= */}
                 
                      <div className="age">
                        <strong>Age/Sex: </strong> <span>22 Y/Male</span>
                      </div>
                    {/* =================== Patient ID ==================== */}
                    
                      <div className="patientId">
                        <strong>PID: </strong> <span>PID11234</span>
                      </div>
                    {/* ====================== Address ========== */}
                    
                      <div className="address">
                        <strong>ADD: </strong>
                        <span>Railway Police Line, pantnagar Ghatkopar</span>
                      </div>
                  </td>
                  {/* =================== Date ================ */}
                  <td width="50%" style={TemplateFontSize}>
                    
                      <div className="date">
                        <strong>Date: </strong> <span>20 Feb 2023</span>
                      </div>

                    {/* ============= Time =============== */}
                   
                      <div className="time">
                        <strong>Time: </strong> <span>03:30pm</span>
                      </div>

                    {/* ===================== Mobile Number ============= */}
                   
                      <div className="mobile">
                        <strong>Mobile: </strong> <span>+91 9876543212</span>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* ======================= Vitals Details ============= */}
        
              <table style={{ width: "100%", border: "1px solid grey" }}>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>WT:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>HT:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>BMI:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>TEMP:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>PULSE:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>RR:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>BP:</span>
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>SATS:</span>
                    </th>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>50Kg</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>131cm</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>30</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>101.3</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>93</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>99</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>120/90</span>
                    </td>
                    <td
                      width="12.5%"
                      style={{
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <span style={TemplateFontSize}>99</span>
                    </td>
                  </tr>
                </tbody>
              </table>

          <table style={{ width: "100%", margin: "10px 0px 0px 0px" }}>
            <tbody>
              <tr width="100%" style={TemplateFontSize}>
                <td style={TemplateFontSize} width="50%">
                  {/* ============= Diagnosis =============== */}
                  <div className="diagnosisData">
                   
                        <strong>Diagnosis: </strong> Neck Injury, Back Injury
                   
                  </div>

                  {/* ==================================== Complaints ======================== */}
                  <div className="complaintsData">
                   
                        <strong>Compaints: </strong> Neck Injury
                    
                  </div>

                  {/* ================== Findings ======================== */}
                  <div className="findingsData">
                   
                        <strong>Findings: </strong> Neck Injury
                   
                  </div>
                </td>

                <td style={TemplateFontSize} width="50%">
                  {/* ======================= Instruction ============================ */}

                  <div className="isntructionData">
                   
                        <strong>Instruction: </strong> Neck Injury
                    
                  </div>

                  {/* =========================== Test Data ========================== */}
                  <div className="testData">
                   
                        <strong>Test: </strong>
                        <span>X-Ray, CT-Scan and Blood Test</span>
                    
                  </div>

                  {/* ============================ Procedures Data =============================== */}

                  <div className="proceduresData">
                  
                        <strong>Procedures: </strong> Neck Injury
                     
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
            <hr />

          {/* ============ Surgical Notes  ===================== */}
         
            <div className="diagnosisItem mt-3 mb-2">
              <h6 style={TemplateFontSize}>
                <strong>Clinical / Surgical Notes: </strong>
                <span>takes baed rest try do work less</span>
              </h6>
            </div>

            <div className="drugList mb-3">
              <img src={rx} width={35} alt="rx" />
              <div>
                <table style={{ width: "100%" }}>
                  <tbody style={{ textAlign: "left" }}>
                    <tr style={TemplateFontSize}>
                      <th
                        style={{
                          borderBottom: "1px solid rgb(153 153 153)",
                          padding: "10px 0",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid rgb(153 153 153)",
                          padding: "10px 0",
                        }}
                      >
                        Frequency
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid rgb(153 153 153)",
                          padding: "10px 0",
                        }}
                      >
                        Duration
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid rgb(153 153 153)",
                          padding: "10px 0",
                        }}
                      >
                        Instruction
                      </th>
                    </tr>
                    <tr style={TemplateFontSize}>
                      <td style={{ paddingTop: "10px" }}>Drops Paracetamol</td>
                      <td>2 - 0 - 2</td>
                      <td style={{ paddingTop: "10px" }}>
                        23 Feb 2023 To 25 Feb 2023{" "}
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        Before Breakfast,Before Dinner
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        

          <table style={{ width: "100%", textAlign: "left" }}>
            <tbody>
              {/* =============================== Drug Instruction ===================== */}

                <tr style={TemplateFontSize}>
                  <th style={{ padding: "3px 0px" }}>
                    <strong>Specific Drug Instructions:</strong>
                  </th>
                  <td style={{ padding: "3px 0px" }}>
                    <span>-</span> take rest try not too hard yourself.
                  </td>
                </tr>

              {/* =================== Hoadouts ======================== */}
            
                <tr style={TemplateFontSize}>
                  <th style={{ padding: "3px 0px" }}>Handouts:</th>
                  <td style={{ padding: "3px 0px" }}>
                    - Knee-Problems and solution
                  </td>
                </tr>

                <tr style={TemplateFontSize}>
                  <th style={{ padding: "3px 0px" }}>Follow Up:</th>
                  <td style={{ padding: "3px 0px" }}>
                    - 24th Feb 2023(Note: Followup with Reports).
                  </td>
                </tr>

              {/* =========================== Referrel =============================== */}
             
                <tr style={TemplateFontSize}>
                  <th style={{ padding: "3px 0px" }}>Referral:</th>
                  <td style={{ padding: "3px 0px" }}>
                    - CBC,CRP,CT-Scan to be done on 22 Feb 2023 at star
                    Diagnosis.
                  </td>
                </tr>

              {/* ============================= Refrences ======================= */}
                <tr style={TemplateFontSize}>
                  <th style={{ padding: "3px 0px" }}>References:</th>
                  <td style={{ padding: "3px 0px" }}>
                    - CBC,CRP,CT-Scan to be done on 22 Feb 2023 at star
                    Diagnosis.
                  </td>
                </tr>
            </tbody>
          </table>

          {/* ====================== Border Line Checking ================= */}
        
            <hr />

          <table style={{ width: "100%" }}>
            <tbody style={{ padding: "0 10px" }}>
              <tr style={{ textAlign: "left" }}>
                <td style={{ padding: "5px 0px 0px 0px" }}></td>
                <td style={{ textAlign: "right" }}>
                  <div
                    className="doctorSignatueDetails"
                    style={{
                      margin: "0px 15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    {/* ================== Signature ============= */}
                  
                      <div className="signImg">
                        <img
                          src="https://static.cdn.wisestamp.com/wp-content/uploads/2020/08/Michael-Jordan-personal-autograph.png"
                          alt="signature"
                          style={{ maxWidth: "110px !important" }}
                        />
                      </div>

                    {/* ================= Doctor Qualification and Degreee */}
                 
                      <div
                        className="doctorName"
                        style={{ width: "100%", textAlign: "end" }}
                      >
                        <p style={TemplateFontSize}>
                          Doctor Name
                          <br />
                          Qualification and degree
                        </p>
                      </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TemplateOneView;
