import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillWarning,
} from "react-icons/ai";
import { MdDelete, MdGridView } from "react-icons/md";
import { BsFillImageFill, BsFillPrinterFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

// CSS
import "../../css/ViewHistory.css";
import { getToken } from "../../services/localStorageServices";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import VitalModal from "./vitalAddModal";
import NoDataFound from "../../pages/NoDataFound";
import { FileUploader } from "react-drag-drop-files";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import {
  useDocumentImageDeleteMutation,
  useDocumentImageRegisterMutation,
} from "../../services/userAuthAPI";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";

const fileTypes = ["JPG", "PNG", "GIF"];

const RecordHistory = () => {
  const [show, setShow] = useState(false);
  const { pid, name, DOB, gender } = useParams();
  const [historyList, setHistoryList] = useState([]);
  const { access_token } = getToken();
  const [loadData, setLoadData] = useState(true);
  const [vitalView, setVitalView] = useState([]);
  const [documentImageRegister] = useDocumentImageRegisterMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [documentImageDelete] = useDocumentImageDeleteMutation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const patientHistoryFetch = async (pid) => {
    setLoadData(true);
    try {
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/patient_history_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            patient_id: pid,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      setLoadData(false);
      const data = await response.json();
      setHistoryList(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    patientHistoryFetch(pid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skeletonArray = [];

  for (let i = 0; i < 5; i++) {
    skeletonArray.push(
      <div className="row mb-3" key={i}>
        <div className="col-2">
          <Skeleton variant="rounded" width="100%" height={80} />
        </div>
        <div className="col-10">
          <Skeleton variant="rounded" width="100%" height={80} />
        </div>
      </div>
    );
  }

  const vitalList = (pid) => {
    fetch("https://skdm.in/skddev/DrCrm/patient_vital_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        patient_id_reff: pid,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setVitalView(apiData);
      });
  };

  useEffect(() => {
    vitalList(pid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ================================== Priscription Fetch =================================

  const [priscriptionList, setPriscriptionList] = useState([]);
  const priscriptionFetch = (patientsID) => {
    fetch("https://skdm.in/skddev/DrCrm/booking_appointment_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        appointment_status: "visit",
        patient_Id: patientsID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setPriscriptionList(apiData);
      });
  };

  useEffect(() => {
    priscriptionFetch(pid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to print a specific image
  function handlePrint(img) {
    const printWindow = window.open();
    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(
      '<link rel="stylesheet" type="text/css" href="print-styles.css" media="print" />'
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<img style="width:100%; border:unset;" src=' + img + ">"
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  }

  // ========================================= File Upload ==========================
  const [file, setFile] = useState(null);
  const [fileDataList, setFileDataList] = useState([]);

  const handleChange = (fileList) => {
    setFile(fileList);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const data = new FormData(e.target);
    const dataValue = fileDataList;

    const formData = new FormData();
    formData.append("patient_Image", file);
    formData.append("file_note", data.get("message"));
    formData.append("patientIdReff", pid);
    formData.append("token", access_token);

    const res = await documentImageRegister(formData);
    if (res.data) {
      const dataList = {
        upload_image: data.get("myFile"),
        patient_desc_text: data.get("message"),
        created_at: moment().format("DD MMM YYYY"),
        upload_type: true,
      };
      dataValue.push(dataList);
      setFileDataList([...dataValue]);
      setFile(null);
      setOpenBackdrop(false);
      setOpen(true);
      setErrorMessage("File uploaded successfully");
      setMessageType("success");
      e.currentTarget.reset();
    }

    if (res.error) {
      setOpenBackdrop(false);
      setOpen(true);
      setErrorMessage("File uploading failed !");
      setMessageType("error");
    }
  };

  const removeArrayData = async (id, listId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to remove this?"
    );

    if (confirmDelete) {
      setOpenBackdrop(true);
      const res = await documentImageDelete({
        patient_desc_id: listId,
        token: access_token,
      });

      if (res.data) {
        fileDataList.splice(id, 1);
        setFileDataList([...fileDataList]);
        setOpenBackdrop(false);
        setOpen(true);
        setErrorMessage("File removed successfully");
        setMessageType("success");
      } else {
        setOpenBackdrop(false);
        setOpen(true);
        setErrorMessage("File removing failed");
        setMessageType("error");
      }
    }
  };

  // ================================== Certificate Fetch ============================
  const [certiFicateFetch, setCertiFicateFetch] = useState([]);

  const certificateFetchData = (patientsID, type) => {
    fetch("https://skdm.in/skddev/DrCrm/certificate_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        patientdesctype: type,
        patientIdReff: patientsID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        if (type === "certificate") {
          setCertiFicateFetch(apiData);
        } else {
          setFileDataList(apiData);
        }
      });
  };

  useEffect(() => {
    // ======= Fetch Certificate =======
    certificateFetchData(pid, "certificate");

    // ======== Fetch Upload Records =========
    certificateFetchData(pid, "docImage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="right"
      >
        <Alert
          onClose={handleClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="recordHistorySection">
        <Tabs
          defaultActiveKey="medical-history"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="medical-history" title="Medical History">
            <div className="recordHistoryTab">
              <div className="medicalRecordSection">
                {loadData ? (
                  skeletonArray
                ) : historyList.length > 0 ? (
                  historyList.map((obj, i) => (
                    <div key={i} className="row mb-3">
                      <div className="col-3">
                        <div className="medicalRecordDate">
                          <h6>
                            {moment(obj.appointmentDate).format("MMM DD, YYYY")}{" "}
                            <span style={{ fontWeight: "bold" }}>
                              ({obj.appointment_start_time})
                            </span>
                          </h6>
                          <p>{obj.purpose_of_visit}</p>
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="medicalRecordContent">
                          <p>
                            {name},{" "}
                            {moment().diff(DOB, "years")}
                                              &nbsp;years{" "}
                                              {moment().diff(DOB, "months") % 12}
                                              &nbsp;months {gender}.
                            had{" "}
                            {/* ==================== Daignosis ================= */}
                            {obj.diagnosis_data.length > 0
                              ? `diagnosis of ${obj.diagnosis_data
                                  .filter((item) => item.type === "Diagnosis")
                                  .map(
                                    (diagnosisValue) =>
                                      `${diagnosisValue.diagnosis_name} ${
                                        diagnosisValue.diagnosis_name_type_complain !==
                                        ""
                                          ? "(" +
                                            diagnosisValue.diagnosis_name_type_complain +
                                            ")"
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_description !==
                                        null
                                          ? " - " +
                                            diagnosisValue.diagnosis_description
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_duration !== ""
                                          ? diagnosisValue.diagnosis_duration
                                          : ""
                                      }, `
                                  )
                                  .join("")}`
                              : ""}
                            {/* =========== Complain ============== */}
                            {obj.diagnosis_data.length > 0
                              ? `Complaints of ${obj.diagnosis_data
                                  .filter((item) => item.type === "Complaints")
                                  .map(
                                    (diagnosisValue) =>
                                      `${diagnosisValue.diagnosis_name} ${
                                        diagnosisValue.diagnosis_name_type_complain !==
                                        ""
                                          ? "(" +
                                            diagnosisValue.diagnosis_name_type_complain +
                                            ")"
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_description !==
                                        null
                                          ? " - " +
                                            diagnosisValue.diagnosis_description
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_duration !== ""
                                          ? " since " +
                                            diagnosisValue.diagnosis_duration
                                          : ""
                                      }, `
                                  )
                                  .join("")}`
                              : ""}
                            {/* =================== Findings =========== */}
                            {obj.diagnosis_data.length > 0
                              ? `Findings of ${obj.diagnosis_data
                                  .filter((item) => item.type === "Findings")
                                  .map(
                                    (diagnosisValue) =>
                                      `${diagnosisValue.diagnosis_name} ${
                                        diagnosisValue.diagnosis_name_type_complain !==
                                        ""
                                          ? "(" +
                                            diagnosisValue.diagnosis_name_type_complain +
                                            ")"
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_description !==
                                        null
                                          ? " - " +
                                            diagnosisValue.diagnosis_description
                                          : ""
                                      } ${
                                        diagnosisValue.diagnosis_duration !== ""
                                          ? " since " +
                                            diagnosisValue.diagnosis_duration
                                          : ""
                                      }, `
                                  )
                                  .join("")}`
                              : ""}
                            {/* ================== Drug ================= */}
                            {obj.drug_data.length > 0
                              ? `Gave drugs ${obj.drug_data
                                  .map(
                                    (diagnosisValue) =>
                                      `${diagnosisValue.brand_name} for ${diagnosisValue.duration}, `
                                  )
                                  .join("")}`
                              : ""}
                            {/* ================ Test ========================= */}
                            {obj.instruction_data.length > 0
                              ? `Tests - ${obj.instruction_data
                                  .filter(
                                    (item) =>
                                      item.instruction_patient_type === "Test"
                                  )
                                  .map(
                                    (instructionValue) =>
                                      ` ${instructionValue.instruction_patient_name},`
                                  )
                                  .join("")}`
                              : ""}
                            {/* ===================== Procedures ===================== */}
                            {obj.instruction_data.length > 0
                              ? `Procedures - ${obj.instruction_data
                                  .filter(
                                    (item) =>
                                      item.instruction_patient_type ===
                                      "Procedures"
                                  )
                                  .map(
                                    (instructionValue) =>
                                      ` ${instructionValue.instruction_patient_name},`
                                  )
                                  .join("")}`
                              : ""}
                            {/* =================== Instructions ================= */}
                            {obj.instruction_data.length > 0
                              ? ` and follow instruction - ${obj.instruction_data
                                  .filter(
                                    (item) =>
                                      item.instruction_patient_type ===
                                      "Instruction"
                                  )
                                  .map(
                                    (instructionValue) =>
                                      ` ${instructionValue.instruction_patient_name},`
                                  )
                                  .join("")}`
                              : ""}
                            {/* ============================ Follow Up =================== */}
                            {obj.patientDesc_data.length > 0
                              ? obj.patientDesc_data
                                  .filter(
                                    (item) =>
                                      item.patient_desc_type === "Follow"
                                  ).map(
                                    (instructionValue) =>
                                      ` and follow up - ${moment(instructionValue.follow_up).format("DD MMM YYYY")},`
                                  )
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="Records-And-Images" title="Records And Priscription">
            <div className="recordHistoryTab">
              <div className="recordsAndImgSection">
                <Tabs
                  defaultActiveKey="prescription"
                  id="fill-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="prescription" title="Prescription">
                    <div className="recordPrescription">
                      <div className="row">
                        {priscriptionList && priscriptionList.length > 0 ? (
                          priscriptionList.map((value, index) => (
                            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-12 mb-3" key={index}>
                              <div className="prescriptionImgSection">
                                <div className="prescriptionImgCard">
                                  <div className="prescriptionImgHead">
                                    <p>
                                      {" "}
                                      Prescription - {value.patient_id_reff}
                                    </p>
                                  </div>
                                  <div className="prescriptionImg">
                                    <img src={value.prescription_link} alt="" />
                                  </div>
                                  <div className="prescriptionImgFooter">
                                    <div
                                      className="prscFootIcon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handlePrint(value.prescription_link)
                                      }
                                    >
                                      <BsFillPrinterFill />
                                    </div>
                                    <div
                                      className="prscFootIcon"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <MdDelete />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="images" title="Upload Records">
                    <div className="recordImages">
                      <form onSubmit={uploadFile}>
                        <div className="row">
                          <div className="col-lg-5">
                            <FileUploader
                              handleChange={handleChange}
                              name="myFile"
                              types={fileTypes}
                            />
                            <span
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "500",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {file ? (
                                file.size > 100000 ? (
                                  <>
                                    <AiFillWarning
                                      style={{ margin: "0 10px", color: "red" }}
                                    />{" "}
                                    File size exceeds the limit of 100KB
                                  </>
                                ) : (
                                  <>
                                    <AiFillCheckCircle
                                      style={{
                                        margin: "0 10px",
                                        color: "green",
                                      }}
                                    />{" "}
                                    {file.name}
                                  </>
                                )
                              ) : (
                                <>
                                  <AiFillInfoCircle
                                    style={{
                                      margin: "0 10px",
                                      color: "orange",
                                    }}
                                  />{" "}
                                  No files uploaded yet{" "}
                                </>
                              )}
                            </span>
                          </div>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              style={{ height: "50px" }}
                              className="form-control"
                              placeholder="Message"
                              name="message"
                            />
                          </div>
                          <div className="col-lg-1">
                            <button className="btn btn-success">Upload</button>
                          </div>
                        </div>
                      </form>
                      <hr />
                      {fileDataList && fileDataList.length > 0 ? (
                        <Table>
                          <Thead style={{ background: "#111", color: "#fff" }}>
                            <Tr>
                              <Th style={{ padding: "10px" }}>Sr No.</Th>
                              <Th style={{ padding: "10px" }}>Date</Th>
                              <Th style={{ padding: "10px" }}>Message</Th>
                              <Th style={{ padding: "10px" }}>File</Th>
                              <Th>
                                <span></span>
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {fileDataList.map((value, i) => (
                              <Tr
                              key={i}
                                style={{
                                  padding: "10px",
                                  borderBottom: "1px solid #808080",
                                }}
                              >
                                <Td style={{ padding: "10px" }}>
                                  {(i + 1).toString().padStart(2, "0")}
                                </Td>
                                <Td style={{ padding: "10px" }}>
                                  {moment(value.created_at).format(
                                    "DD MMM YYYY (hh:mm A)"
                                  )}
                                </Td>
                                <Td style={{ padding: "10px" }}>
                                  {value.patient_desc_text}
                                </Td>
                                <Td style={{ padding: "10px" }}>
                                  <PhotoProvider>
                                    <PhotoView
                                      src={
                                        value.upload_type
                                          ? URL.createObjectURL(
                                              value.upload_image
                                            )
                                          : value.upload_image
                                      }
                                    >
                                      <span
                                        data-image={value.upload_image}
                                        style={{
                                          color: "#023361",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <BsFillImageFill /> View File
                                      </span>
                                    </PhotoView>
                                  </PhotoProvider>
                                </Td>
                                <Td style={{ padding: "10px" }}>
                                  <div
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      removeArrayData(
                                        i,
                                        value.patient_desc_note_id
                                      )
                                    }
                                  >
                                    <MdDelete
                                      style={{
                                        margin: "0 10px",
                                      }}
                                    />
                                    Remove
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Tab>
          <Tab eventKey="longer-tab" title="Growth & Vitals">
            <div className="recordHistoryTab">
              <div className="addRecords">
                <button onClick={() => setShow(true)}>
                  <MdGridView /> Add Records
                </button>
              </div>
              <VitalModal
                setVitalView={setVitalView}
                show={show}
                close={() => setShow(false)}
                vitalView={vitalView}
                setShow={setShow}
                pid={pid}
              />
              <div className="invoiceTab">
                <section className="growthVitalList marginHeader">
                  {vitalView && vitalView.length > 0 ? (
                    vitalView.map((workObj, index) => (
                      <div key={index} className="viltalListCard">
                        <div className="removeButton">
                          <button></button>
                        </div>
                        <div className="vitalData">
                          <div className="vitalDate">
                            <div className="dataHeading">
                              <span>Created At</span>
                            </div>
                            <div className="dataText">
                              <span>
                                {moment(
                                  workObj.date_of_capture,
                                  "YYYY-MM-DD"
                                ).format("DD MMM YYYY")}{" "}
                                by {workObj.update_by}
                              </span>
                            </div>
                          </div>
                          <div className="vitalDataList">
                            {" "}
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Weight</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_Weight === null
                                    ? " - "
                                    : workObj.patient_Weight}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Height</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_height === null
                                    ? " - "
                                    : workObj.patient_height}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>BMI</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patinet_bmi === null
                                    ? " - "
                                    : workObj.patinet_bmi}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Temprature</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_temperature === null
                                    ? " - "
                                    : workObj.patient_temperature}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Pulse</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_pulse === null
                                    ? " - "
                                    : workObj.patient_pulse}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Respiratoy Rate</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_respiratory_rate === null
                                    ? " - "
                                    : workObj.patient_respiratory_rate}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Blood Presure</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patinet_blood_presure === null
                                    ? " - "
                                    : workObj.patinet_blood_presure}
                                </span>
                              </div>
                            </div>
                            <div className="vitalInfo">
                              <div className="dataHeading">
                                <span>Oxygen Saturation</span>
                              </div>
                              <div className="dataText">
                                <span>
                                  {workObj.patient_oxygen_saturation === null
                                    ? " - "
                                    : workObj.patient_oxygen_saturation}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </section>
              </div>
            </div>
          </Tab>
          <Tab eventKey="certificates" title="Certificates">
            <div className="recordHistoryTab">
              <div className="recordPrescription">
                <div className="row">
                  {certiFicateFetch && certiFicateFetch.length > 0 ? (
                    certiFicateFetch.map((value, index) => (
                      <div className="col-2" key={index}>
                        <div className="prescriptionImgSection">
                          <div className="prescriptionImgCard">
                            <div className="prescriptionImgHead">
                              <p> Prescription - {value.patient_id_reff}</p>
                            </div>
                            <div className="prescriptionImg">
                              <img src={value.upload_image} alt="" />
                            </div>
                            <div className="prescriptionImgFooter">
                              <div
                                className="prscFootIcon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handlePrint(value.upload_image)}
                              >
                                <BsFillPrinterFill />
                              </div>
                              <div
                                className="prscFootIcon"
                                style={{ cursor: "pointer" }}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default RecordHistory;
