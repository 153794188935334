import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./AddHandoutBox.css";
import { BsCloudUploadFill } from "react-icons/bs";
import { getToken } from "../../services/localStorageServices";
import { useHandoutListAddMutation } from "../../services/userAuthAPI";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const AddHandoutBox = ({ catogeryId }) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);
  const [typeSelected, setTypeSelected] = useState("PDF");
  const formRef = useRef(null);
  const [handoutListAdd] = useHandoutListAddMutation();
  const { access_token } = getToken();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [message, setMessage] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSnackBar(false);
  };

  const onHandleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setOpenBackDrop(true);

    const data = new FormData(formRef.current);
    const formData = new FormData();

    formData.append("title", data.get("title"));
    formData.append("type", data.get("type"));
    if (typeSelected === "Link") {
      formData.append("uploadLink", data.get("Link"));
    } else {
      formData.append("uploadFile", data.get("file"));
    }
    formData.append("uploadBanner", data.get("banner"));
    formData.append("discription", data.get("discription"));
    formData.append(
      "slug",
      data.get("title").replace(/\s+/g, "-").toLowerCase()
    );
    formData.append("token", access_token);
    formData.append("catogeryId", catogeryId);

    const req = await handoutListAdd(formData);

    if (req.data) {
      setOpen(false);
      setMessage({ text: "Added Succesfully", color: "success" });
      setOpenSnackBar(true);
      setOpenBackDrop(false);
    }

    if (formRef.current) {
      formRef.current.reset();
    }
  };

  return (
    <div className="addHandoutBox">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        onClose={handleClose}
        autoHideDuration={6000}
        key="right"
      >
        <Alert
          onClose={handleClose}
          severity={message.color}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
      <div>
        <button
          className="btn btn-dark d-flex align-items-center"
          onClick={handleClickOpen}
        >
          Add Handout&nbsp;&nbsp;
          <BsCloudUploadFill />
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px", // Set your width here
              },
            },
          }}
        >
          <DialogContent>
            <div className="popUpFormTitle">
              <span>Add Handout</span>
            </div>
            <div className="addHandoutContent">
              <form ref={formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-style">
                      <label className="mb-1" htmlFor="handoutTitle">
                        Title
                      </label>
                      <input
                        name="title"
                        id="handoutTitle"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-style">
                      <label className="mb-1" htmlFor="handoutType">
                        Handout Type
                      </label>
                      <select
                        className="form-control"
                        name="type"
                        id="handoutType"
                        onChange={(e) => setTypeSelected(e.target.value)}
                      >
                        <option value="PDF">PDF</option>
                        <option value="Link">Link</option>
                        <option value="Image">Image</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {typeSelected === "Link" ? (
                      <div className="input-style">
                        <label className="mb-1" htmlFor="handoutFile">
                          Enter Link
                        </label>
                        <input
                          name="Link"
                          id="handoutFile"
                          type="url"
                          className="form-control"
                        />
                      </div>
                    ) : typeSelected === "PDF" ? (
                      <div className="input-style">
                        <label className="mb-1" htmlFor="handoutFile">
                          Select File
                        </label>
                        <input
                          name="file"
                          id="handoutFile"
                          type="file"
                          className="form-control"
                          accept=".pdf, application/pdf"
                        />
                      </div>
                    ) : (
                      <div className="input-style">
                        <label className="mb-1" htmlFor="handoutFile">
                          Select File
                        </label>
                        <input
                          name="file"
                          id="handoutFile"
                          type="file"
                          className="form-control"
                          accept="image/*"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="input-style">
                      <label className="mb-1" htmlFor="handoutBanner">
                        Upload Banner
                      </label>
                      <input
                        name="banner"
                        id="handoutBanner"
                        type="file"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-style">
                      <label className="mb-1" htmlFor="handoutDesc">
                        Short Description
                      </label>
                      <textarea
                        name="discription"
                        id="handoutDesc"
                        cols="10"
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            {openBackDrop ? (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <CircularProgress
                  color="inherit"
                  sx={{ width: "30px !important", height: "30px !important" }}
                />
                &nbsp;Uploading...{" "}
              </span>
            ) : null}
            <>
              <button className="Cancelbtn" onClick={handleClose}>Cancel</button>
              <button className="Clickbtn" onClick={onHandleSubmit} autoFocus>
                Save
              </button>
            </>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AddHandoutBox;