import React from "react";
import FirstDesing from "./FirstDesing";
import SecondDesing from "./SecondDesing";

export default function OnlineAppointments({
  activeTab,
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  timeSlots,
  VitalFunction
}) {
  return (
    <div className="Shedular-data-con">
      <h5>Online Appointment Lists</h5>
      <div className="Shedular-data-con01">
        {activeTab === 0 ? <FirstDesing VitalFunction={VitalFunction} onAppointmentFormOpening={onAppointmentFormOpening} bookedList={bookedList} formattedDate={formattedDate} slotList= {slotList} hospitalId={hospitalId} timeSlots={timeSlots} /> : <SecondDesing onAppointmentFormOpening={onAppointmentFormOpening} bookedList={bookedList} formattedDate={formattedDate} slotList= {slotList} hospitalId={hospitalId} timeSlots={timeSlots} />}
      </div>
    </div>
  );
}
