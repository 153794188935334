import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Form from "react-bootstrap/Form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useVitalDataRegisterMutation } from "../../services/userAuthAPI";
import { getToken } from "../../services/localStorageServices";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { InputNumber, Spin, message } from "antd";
function VitalModal({
  vitalView,
  setVitalView,
  show,
  close,
  pid,
  appointment,
  setShow,
}) {
  const [value, setValue] = React.useState(new Date());
  const { access_token } = getToken();
  const [vitalDataRegister] = useVitalDataRegisterMutation();
  const [bmi, setBMI] = useState("");
  const [loading, setLoading] = useState(false);

  // weight validation

  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const handleweightChange = (value) => {
    if (value >= 0 && value <= 999) {
      setWeight(value);
    }
    calculateBMI();
  };

  const handleHeightChange = (value) => {
    if (value >= 0 && value <= 250) {
      setHeight(value);
    }
    calculateBMI();
  };

  const calculateBMI = () => {
    const bmiValue = weight / (height * height);
    setBMI(bmiValue * 100);
  };

  //temprature  validate

  const [temperatures, setTemperatures] = useState("");

  const handleTemperatureChange = (value) => {
    if (value >= 0 && value <= 106) {
      setTemperatures(value);
    }
  };

  //pulse  validate

  const [pulse, setPulse] = useState("");

  const handlePulseChange = (value) => {
    if (value >= 0 && value <= 100) {
      setPulse(value);
    }
  };

  //Respiratory  validate

  const [respiratory, setRespiratory] = useState("");

  const handlerespiratoryChange = (value) => {
    if (value >= 0 && value <= 25) {
      setRespiratory(value);
    }
  };

  //setOxygenSaturation  validate

  const [oxygenSaturation, setOxygenSaturation] = useState("");

  const handleOxygenSaturationChange = (value) => {
    if (value >= 0 && value <= 100) {
      setOxygenSaturation(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const dataArray = vitalView;
    const currentData = new Date();
    const vitalData = {
      patient_Weight: data.get("weight"),
      patient_height: data.get("height"),
      patient_temperature: data.get("temperature"),
      patient_pulse: data.get("pulse"),
      patient_respiratory_rate: data.get("respiratoryRate"),
      patinet_blood_presure: data.get("bloodPressure"),
      patient_oxygen_saturation: data.get("oxygenSaturation"),
      vital_date: appointment ? currentData : appointment,
      token: access_token,
      update_by: "Doctor",
      patient_id_reff: pid,
    };

    const req = await vitalDataRegister(vitalData);
    if (req.data) {
      setLoading(false);
      setShow(false);
      if (vitalView) {
        dataArray.push(vitalData);
        setVitalView([...dataArray]);
      }

      message.success("Successfull Add");

      setBMI("");
      setHeight("");
      setOxygenSaturation("");
      setPulse("");
      setRespiratory("");
      setTemperatures("");
      setWeight("");
    }
    if (req.error) {
      message.error(
        "For the reason of some technical problem unable to add please try again"
      );
    }

  };
  return (
    <>
      <Modal show={show} onHide={close} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spin spinning={loading}>
            <div className="vitalSection">
              {appointment ? (
                ""
              ) : (
                <div className="dateSection">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        value={value}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              )}

              <Form className="vitalForm" onSubmit={handleSubmit}>
                <div className="vitalInputData">
                  <div
                    className="GrowthSection mt-4"
                    style={{ marginRight: "20px" }}
                  >
                    <h4>Anthropometry</h4>

                    <div className="d-flex flex-column align-items-end">
                      <Form.Group className="mb-3">
                        <Form.Label>Weight</Form.Label>&nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={weight}
                          name="weight"
                          id="weight"
                          min="0"
                          max="999"
                          required
                          onChange={handleweightChange}
                        />
                        &nbsp;
                        <Form.Text className="text-muted">kg</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Hight</Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={height}
                          name="height"
                          id="height"
                          required
                          onChange={handleHeightChange}
                          min={0}
                          max={250}
                        />
                        &nbsp;<Form.Text className="text-muted">cm</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>BMI</Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          name="BMI"
                          id="BMI"
                          value={bmi}
                        />
                        &nbsp;
                        <Form.Text className="text-muted">kg/m2</Form.Text>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="GrowthSection mt-4">
                    <h4>Vitals</h4>
                    <div className="d-flex flex-column align-items-end">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>Temprature</span>
                        </Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={temperatures}
                          name="temperature"
                          id="temperature"
                          onChange={handleTemperatureChange}
                          min="96"
                          max="106"
                        />
                        &nbsp;
                        <Form.Text className="text-muted">F (96-106)</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>Pulse</span>
                        </Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={pulse}
                          name="pulse"
                          id="pulse"
                          min="0"
                          max="100"
                          onChange={handlePulseChange}
                        />
                        &nbsp;<Form.Text className="text-muted">/min</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>Respiratory&nbsp;Rate</span>
                        </Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={respiratory}
                          name="respiratoryRate"
                          id="respiratoryRate"
                          min="0"
                          max="25"
                          onChange={handlerespiratoryChange}
                        />
                        &nbsp;<Form.Text className="text-muted">/min</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>Blood&nbsp;Presure</span>
                        </Form.Label>
                        &nbsp;
                        <InputNumber
                          type="text"
                          placeholder="0"
                          name="bloodPressure"
                          id="bloodPressure"
                        />
                        &nbsp;
                        <Form.Text className="text-muted">mm/Hg</Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>Oxygen&nbsp;Saturation</span>
                        </Form.Label>
                        &nbsp;
                        <InputNumber
                          type="number"
                          placeholder="0"
                          value={oxygenSaturation}
                          name="oxygenSaturation"
                          id="oxygenSaturation"
                          min="0"
                          max="100"
                          onChange={handleOxygenSaturationChange}
                        />
                        &nbsp;
                        <Form.Text className="text-muted">0-100</Form.Text>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="buttonGroup mt-4 mb-4">
                  <Button type="submit">Add</Button>
                </div>
              </Form>
            </div>
          </Spin>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VitalModal;
