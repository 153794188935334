import React from "react";
import Template1 from "./Template1/Template1";
import Template2 from "./Template2/Template2";

export default function ActiveTemplateComp({
  marginLeft,
  marginRight,
  fontSize,
  preview,
  headerHeight,
  footerHeight,
  fontFamily,
  patientDetail,
  dateTime,
  drugList,
  selectedReferal,
  selectedRefrence,
  vitalData,
  withHeader,
  languageList,
  diagnosis,
  findings,
  complaints,
  test,
  procedures,
  instruction,
  followUp,
  stepInstructionData,
  diagnosisNote,
  signature,
  doctorDataFooter,
  result,
  handoutList,
  activeTemplate,
}) {
  return (
    <div>
      {activeTemplate === "Template 1" && (
        <Template1
          headerHeight={headerHeight}
          footerHeight={footerHeight}
          marginLeft={marginLeft}
          marginRight={marginRight}
          fontSize={fontSize}
          templateName="Template 1"
          fontFamily={fontFamily}
          signature={signature}
          preview={preview}
          doctorDataFooter={doctorDataFooter}
          patientDetail={patientDetail}
          dateTime={dateTime}
          withHeader={withHeader}
          diagnosis={diagnosis}
          complaints={complaints}
          findings={findings}
          drugList={drugList}
          test={test}
          procedures={procedures}
          instruction={instruction}
          followUp={followUp}
          stepInstructionData={stepInstructionData}
          diagnosisNote={diagnosisNote}
          selectedRefrence={selectedRefrence}
          selectedReferal={selectedReferal}
          vitalData={vitalData}
          languageList={languageList}
          result={result}
          handoutList={handoutList}
        />
      )}
      {activeTemplate === "Template 2" && (
        <Template2
          headerHeight={headerHeight}
          footerHeight={footerHeight}
          marginLeft={marginLeft}
          marginRight={marginRight}
          fontSize={fontSize}
          templateName="Template 2"
          fontFamily={fontFamily}
          signature={signature}
          preview={preview}
          doctorDataFooter={doctorDataFooter}
          patientDetail={patientDetail}
          dateTime={dateTime}
          withHeader={withHeader}
          diagnosis={diagnosis}
          complaints={complaints}
          findings={findings}
          drugList={drugList}
          test={test}
          procedures={procedures}
          instruction={instruction}
          followUp={followUp}
          stepInstructionData={stepInstructionData}
          diagnosisNote={diagnosisNote}
          selectedRefrence={selectedRefrence}
          selectedReferal={selectedReferal}
          vitalData={vitalData}
          languageList={languageList}
          result={result}
          handoutList={handoutList}
        />
      )}
    </div>
  );
}
